import React from 'react';
import styled, { keyframes } from 'styled-components';

const slide = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`;

const ImageArc = styled.img`
  width: 365px;
  height: 447px;
  border-radius: 50% 50% 0 0;
  object-fit: cover;
  margin: 4em 1.5em;
`;

const CarouselContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  display: flex;
  width: ${props => props.imagesLength * 365}px;
  animation: ${slide} 55s linear infinite;
`;

export const Carousel = ({ images }) => {
  const imagesLength = images.length;
  const doubledImages = [...images, ...images]; // Duplicate images for continuous effect

  return (
    <CarouselContainer>
      <ImageContainer imagesLength={imagesLength}>
        {doubledImages.map((image, index) => (
          <ImageArc key={index} src={image} />
        ))}
      </ImageContainer>
    </CarouselContainer>
  );
};