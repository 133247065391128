import React, { useState, useEffect } from 'react';
import { styled, css } from 'styled-components';
import { CallToActionButton } from '../utils/cta-button';
import { scale } from '../utils/keyframes';
import { fadeIn } from '../utils/keyframes';

// Animation CSS
const fadeInAnimation = css`
  animation: ${fadeIn} 1s ease-out;
`;

const CardContainer = styled.div`
  ${props => props.animate && fadeInAnimation}
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  width: ${props => props.privatePartiesProduct ? '33%' : props.spaceRental ? '420px' : props.threeCards ? '378px' : props.icon ? '316px' : props.giftCards ? '33%' : '380px' };
  padding-bottom: 0em;
  ${props => props.privatePartiesProduct && 'padding: .5em .5em 1em .5em;'}
  ${props => props.icon && 'padding: 1em;'}
  ${props => props.blog && 'height: auto; position: relative;'}

  align-items: baseline;
  border-radius: 8px;
  position: relative;
  height: ${props => props.giftCards ? '290px' : props.privatePartiesProduct ? '625px' : props.blog ? '34em' : props.icon ? '14em' : props.price ? '395px' : props.buttonText ? '334px' : '28em'};

  &:hover {
    animation: ${scale} .5s ease 0s 1 normal forwards;
  }

  ${props => (props.icon && props.privateParties) && `@media (max-width: 1323px) {
    height: 13em;
  }`}

  ${props => props.privatePartiesProduct && `@media (max-width: 1186px) {
    height: 616px;
  }`}

  ${props => props.privatePartiesProduct && `@media (max-width: 1179px) {
    height: 651px;
  }`}

  ${props => props.privatePartiesProduct && `@media (max-width: 1120px) {
    height: 673px;
  }`}

  ${props => props.privateParties && `@media (max-width: 375) {
    padding: 36px 24px;
  }`}

  @media (min-width: 768px) {
    margin: ${props => props.parties ? '.6em' : props.giftCards ? '.5em .5em 0 .5em' : '1em'};
  }

  ${props => props.homeScreen && `
    @media (max-width: 768px) {
      margin: 1em;
    }
  `}

  @media (max-width: 810px) {
    margin: ${props => props.privatePartiesProduct ? '0 0 2em 0' : ''};
  }


  ${props => props.price ? '' : `@media (max-width: 1278px) {
    height: ${props.blog ? 'auto' : props.homeScreen ? '31em' : props.giftCards ? '19em' : props.privateParties ? '19em' : props.icon ?  '15em' : '30em'};
  }`}

  ${props => (props.icon && props.privateParties) && `@media (max-width: 1278px) {
    height: 13em;
  }`}

  ${props => props.homeScreen && `@media (max-width: 1280px) {
    height: ${props.blog ? 'auto' : '30em'};
  }`}

  ${props => props.homeScreen && `@media (max-width: 1279px) {
    height: ${props.blog ? 'auto' : '30em'};
  }`}

  ${props => props.giftCards && `@media (max-width: 1244px) {
    height: 20em; 
  }`}

  ${props => props.privateParties && `@media (max-width: 1239px) {
    height: ${props.privateParties && props.icon ? '22em' : '20em'}; 
  }`}

  ${props => (props.icon && props.privateParties) && `@media (max-width: 1239px) {
    height: 13em;
  }`}

  ${props => props.privateParties && `@media (max-width: 1218px) {
    height: 22em; 
  }`}

  ${props => (props.icon && props.privateParties) && `@media (max-width: 1218px) {
    height: 13em;
  }`}

  ${props => props.homeScreen && `@media (max-width: 1325px) {
    height: ${props.blog ? 'auto' : '30em'};
  }`}

  ${props => props.blog && `@media (max-width: 1325px) {
    height: auto;
  }`}

  ${props => props.homeScreen && `@media (max-width: 1217px) {
    height: ${props.blog ? 'auto' : '32.3em'};
  }`}

  ${props => props.homeScreen && `@media (max-width: 1197px) {
    height: ${props.blog ? 'auto' : '32em'}; 
  }`}

  ${props => props.price ? '' : `@media (max-width: 1193px) {
    height: ${props.blog ? 'auto' : props.giftCards ? '20em' : props.privateParties ? '13em' : props.icon ? '16em' : '32em'};
  }`}

  ${props => props.privateParties && `@media (max-width: 1193px) {
    height: ${props.blog ? 'auto' : '13em'};
  }`}

  ${props => (props.icon && props.privateParties) && `@media (max-width: 1193px) {
    height: ${props.blog ? 'auto' : '13.5em'};
  }`}

  ${props => props.homeScreen && `@media (max-width: 1191px) {
    height: ${props.blog ? 'auto' : '32em'};
  }`}

  ${props => props.price ? '' : `@media (max-width: 1185px) {
    height: ${props.blog ? 'auto' : props.giftCards ? '22em' : props.spaceRental ? '18em' : (props.icon && props.privateParties) ? '13.5em' : props.icon ? '16em' : props.homeScreen ? '33em' : '34em'};
  }`}

  ${props => props.privateParties && `@media (max-width: 1170px) {
    height: 14.9em; 
  }`}

  ${props => props.giftCards && `@media (max-width: 1185px) {
    height: ${props.blog ? 'auto' : '20em'}; 
  }`}

  ${props => props.homeScreen && `@media (max-width: 1183px) {
    height: ${props.blog ? 'auto' : '32em'}; 
  }`}

  ${props => props.homeScreen && `@media (max-width: 1134px) {
    // height: ${props.blog ? '36em' : props.homeScreen ? '34em' : '33em'};  
    height: ${props.blog ? 'auto' : props.homeScreen ? '34em' : '33em'};  
  }`}

  ${props => props.giftCards && `@media (max-width: 1151px) {
    height: 20.3em; 
  }`}

  ${props => props.price ? '' : `@media (max-width: 1109px) {
    // height: ${props.homeScreen ? '33.9em' : props.blog ? '37em' : props.giftCards ? '20.3em' : props.privateParties ? '15em' : props.icon ? '18em' : '34em'};
    height: ${props.blog ? 'auto' : props.homeScreen ? '33.9em' :  props.giftCards ? '20.3em' : props.privateParties ? '15em' : props.icon ? '18em' : '34em'};
  }`}

  ${props => props.homeScreen && `@media (max-width: 1105px) {
    ${!props.blog && 'height: 32.2em;'} 
  }`}

  ${props => props.privateParties && `@media (max-width: 1108px) {
    height: 22em; 
  }`}

  ${props => (props.icon && props.privateParties) && `@media (max-width: 1108px) {
    height: 14.4em;
  }`}

  ${props => props.privateParties && `@media (max-width: 1099px) {
    height: 24em; 
  }`}

  ${props => (props.icon && props.privateParties) && `@media (max-width: 1099px) {
    height: 14.4em;
  }`}

  ${props => (props.icon && props.privateParties) && `@media (max-width: 1081px) {
    height: 15em;
  }`}

  ${props => props.homeScreen && `@media (max-width: 1016px) {
    // height: ${props.blog ? '37em' : props.homeScreen ? '32.6em' : '35em'}; 
    height: ${props.blog ? 'auto' : props.homeScreen ? '32.6em' : '35em'}; 
  }`}

  ${props => props.privateParties && `@media (max-width: 1073px) {
    height: 15em; 
  }`}

  ${props => (props.icon && props.privateParties) && `@media (max-width: 1073px) {
    height: 14em;
  }`}

  ${props => (props.price && !props.privatePartiesProduct) && `@media (max-width: 1078px) {
    height: 422px; 
  }`}

  ${props => (props.icon && props.privateParties) && `@media (max-width: 1067px) {
    height: 14.8em;
  }`}

  ${props => props.privateParties && `@media (max-width: 1064px) {
    height: 14.9em;
    width: 50%; 
  }`}

  ${props => props.privateParties && `@media (max-width: 1061px) {
    height: 10.9em;
  }`}

  ${props => props.privateParties && `@media (max-width: 1030px) {
    height: 10.8em;
  }`}

  ${props => props.giftCards && `@media (max-width: 1010px) {
    ${props => !props.blog && 'height: 23em; '} 
  }`}

  ${props => props.price ? '' : `@media (max-width: 1012px) {
    height: ${(props.privateParties || props.blog) ? 'auto' : props.icon ? '18em' : props.homeScreen ? '32.6em' : '36em'};
  }`}

  ${props => props.giftCards && `@media (max-width: 1050px) {
    height: 19.9em;
  }`}

  ${props => props.giftCards && `@media (max-width: 1029px) {
    height: 21em;
  }`}

  ${props => props.price ? '' : `@media (max-width: 1008px) {
    height: ${props.blog ? 'auto' : props.privateParties ? '19em' : props.icon ? '17em' : props.giftCards ? '21em' : '24em'};
    width: ${props.giftCards ? '33%' : 'auto'};
  }`}

  ${props => props.price && `@media (max-width: 1009px) {
    height: 638px; 
  }`}

  ${props => props.price && `@media (max-width: 954px) {
    height: 660px; 
  }`}

  ${props => props.giftCards && `@media (max-width: 876px) {
    height: 22.5em; 
  }`}

  ${props => props.giftCards && `@media (max-width: 886px) {
    height: 22em; 
  }`}

  ${props => props.giftCards && `@media (max-width: 876px) {
    height: 23em; 
  }`}

  ${props => props.price && `@media (max-width: 858px) {
    height: 446px;
  }`}

  ${props => props.privatePartiesProduct && `@media (max-width: 858px) {
    height: 690px;
  }`}

  ${props => props.privatePartiesProduct && `@media (max-width: 840px) {
    height: 692px;
  }`}

  ${props => props.giftCards && `@media (max-width: 832px) {
    height: 25em;
  }`}

  ${
    props => props.privateParties ? `@media (max-width: 1008px) {
      height: auto;
      width: 50%;
    }` :
  props.icon && `@media (max-width: 1008px) {
    height: 20em;
  }`}

  ${props => props.spaceRental && `@media (max-width: 1008px) {
    height: 18em;
  }`}; 

  ${props => props.privateParties && `@media (max-width: 1003px) {
    height: auto;
    width: 50%;
  }`}

  ${props => props.icon && `@media (max-width: 877px) {
    height: 18em; 
  }`}

  ${props => props.privateParties && `@media (max-width: 877px) {
    height: auto;
    width: 50%;
  }`}

  ${props => props.icon && `@media (max-width: 817px) {
    height: 20em; 
  }`}

  ${props => props.privatePartiesProduct && `@media (max-width: 809px) {
    height: 732px;
  }`}
  
  
  ${props => props.icon && `@media (max-width: 851px) {
    height: ${props => props.spaceRental ? '20em' : '22em'}; 
  }`}
  
  ${props => props.privateParties && `@media (max-width: 851px) {
    height: auto; 
  }`}

  ${props => props.homeScreen && `@media (max-width: 1010px) {
    width: ${props.blog ? 'auto' : '100%'};
    // height: ${props.blog ? '41em' : props.homeScreen ? '' : 'auto'};
    height: ${props.homeScreen ? '' : 'auto'};
  }`}

  ${props => props.homeScreen && `@media (max-width: 1010px) {
    // ${props.blog ? 'height: auto;' : props.homeScreen ? 'height: 25em;' : ''};
    ${(props.homeScreen && !props.blog) ? 'height: 25em;' : ''};
  }`}

  ${props => props.blog && `@media (max-width: 1008px) {
    position: relative;
    margin: 2em auto;
  }`}

  ${props => props.blog && `@media (max-width: 843px) {
    position: relative;
    margin: 3em auto;
  }`}

  ${props => props.giftCards && `@media (max-width: 788px) {
    height: 24.8em;
  }`}

  ${props => props.giftCards && `@media (max-width: 770px) {
    height: 26.4em;
  }`}

  ${props => props.privateParties && `@media (max-width: 767px) {
    height: auto; 
    ${props => props.partiesOnly ? 'margin: .8em' : props.privateParties ? 'margin: .8em' : ''};
    margin-right: 16px;
  }`}

  ${props => props.icon && `@media (max-width: 767px) {
    height:  ${props.icon ? 'auto' : '22em'}; 
    margin-bottom: ${props.icon ? '1em' : ''}; 
   }`}

  @media (max-width: 766px) {
    height: ${props => props.icon || props.blog ? 'auto' : (props.homeSecond || props.homeFirst) ? '24em' : props.homeThird ? '26em' : '25em'}; 
    ${props => props.privateParties ? 'margin-bottom: 12px; margin-top: 12px' : 'margin-bottom: 1.5em'};
    width: ${props => props.privateParties ? '50%' : 'auto'};
    ${props => !props.homeScreen && 'padding: 30px'};
    ${props => props.privatePartiesProduct && 'padding: 24px 16px;'}
    ${props => props.privateParties && 'margin: 6px;'}
    ${props => props.giftCards && 'padding: 24px'};
  }

  @media (max-width: 764px) {
    ${props => (props.homeScreen && props.homeThird) && 'height: 25em'};
  }

  ${props => (props.homeScreen && props.homeThird) && `@media (max-width: 739px) {
    height: 26em;
  }`}

  ${props => (props.privateParties) && `@media (max-width: 687px) {
    width: auto;
    margin: 12px 32px;
  }`}


  ${props => (props.homeScreen && props.homeFirst) && `@media (max-width: 649px) {
    height: 26em;
  }`}

  // ${props => props.blog && `@media (max-width: 783px) {
  //   height: 28em;
  // }`}

  ${props => props.bottomPrice && `@media (max-width: 767px) {
    width: 100%;
    height: auto;
    margin-bottom: 2em;
  }`}

  ${props => props.privatePartiesProduct && `@media (max-width: 810px) {
    width: 100%;
    height: auto;
    margin-bottom: 2em;
  }`}

  ${props => props.giftCards && `@media (max-width: 766px) {
    height: auto;
  }`}

  ${props => props.price ? '' : `@media (max-width: 628px) {
    // height: ${props.blog ? '31em' : props.giftCards ? '18.5em' : props.icon ? 'auto' : '25em'}; 
    height: ${props.giftCards ? '18.5em' : props.icon || props.blog ? 'auto' : '25em'}; 
  }`}

  ${props => (props.homeScreen && props.homeThird) && `@media (max-width: 628px) {
    height: 26em;
  }`}

  ${props => (props.homeScreen && (props.homeFirst)) && `@media (max-width: 628px) {
    height: 25.5em;
  }`}

  ${props => (props.homeScreen && (props.homeSecond)) && `@media (max-width: 628px) {
    height: 26em;
  }`}

  ${props => (props.homeScreen && props.homeFirst) && `@media (max-width: 617px) {
    height: 26.2em;
  }`}

  ${props => props.homeScreen && !props.privatePartiesProduct && `@media (max-width: 586px) {
    ${props => !props.blog && 'height: 26em; '} 
  }`}

  ${props => props.blog ? '' : `@media (max-width: 565px) {
    ${props.blog && 'height: 29em'}; 
  }`}

  ${props => props.price ? '' : `@media (max-width: 539px) {
    // height: ${props.blog ? '31em' : props.giftCards ? '19.8em' : props.icon ? 'auto' : '25em'}; 
    height: ${props.giftCards ? '19.8em' : props.icon || props.blog ? 'auto' : '25em'}; 
  }`}

  ${props => props.homeScreen && `@media (max-width: 539px) {
    ${props => !props.blog && 'height: 28em; '} 
  }`}

  ${props => (props.homeScreen && (props.homeFirst || props.homeSecond)) && `@media (max-width: 539px) {
    height: 26em;
  }`}

  ${props => (props.homeScreen && props.homeThird) && `@media (max-width: 539px) {
    ${props => !props.blog && 'height: 28em; '}
  }`}

  ${props => props.price ? '' : `@media (max-width: 519px) {
    height: ${(props.giftCards || props.blog) ? 'auto' : '27em'}; 
  }`}

  ${props => props.price ? '' : `@media (max-width: 519px) {
    height: ${(props.icon || props.giftCards || props.blog) ? 'auto' : '27em'}; 
    ${props => props.blog && 'height: auto; '}
  }`}

  ${props => props.homeScreen && `@media (max-width: 519px) {
    ${props => !props.blog && 'height: 26em; '}
  }`}

  ${props => (props.homeScreen && props.homeSecond) && `@media (max-width: 519px) {
    ${props => !props.blog && 'height: 26em; '}
  }`}

  ${props => (props.homeScreen && props.homeThird) && `@media (max-width: 519px) {
    height: 28em; 
  }`}

  ${props => (props.homeScreen && props.homeSecond) && `@media (max-width: 493px) {
    height: 28em; 
  }`}

  ${props => props.homeScreen && `@media (max-width: 472px) {
    ${props => !props.blog && 'height: 27em;'} 
  }`}

  ${props => props.homeScreen && props.homeSecond && `@media (max-width: 472px) {
    height: 28em; 
  }`}

  ${props => (props.homeScreen && props.homeThird) && `@media (max-width: 472px) {
    height: 28em; 
  }`}

    ${props => (props.homeScreen && props.homeFirst) && `@media (max-width: 472px) {
    height: 26em; 
  }`}

  ${props => (props.homeScreen && props.homeSecond) && `@media (max-width: 461px) {
    height: 28em; 
  }`}

  ${props => (props.homeScreen && props.homeFirst) && `@media (max-width: 466px) {
    height: 28em; 
  }`}

  ${props => props.homeScreen && `@media (max-width: 445px) {
    ${props => !props.blog && 'height: 27em;'}
  }`}

  ${props => (props.homeScreen && props.homeFirst) && `@media (max-width: 445px) {
    height: 28em; 
  }`}

  ${props => (props.homeScreen && (props.homeSecond || props.homeThird)) && `@media (max-width: 445px) {
    height: 28em; 
  }`}

  ${props => (props.homeScreen && props.homeFirst) && `@media (max-width: 435px) {
    height: 26.5em;
  }`}

  ${props => (props.homeScreen && (props.homeFirst || props.homeSecond)) && `@media (max-width: 435px) {
    height: 28.4em;
  }`}

  ${props => (props.homeScreen && props.homeThird) && `@media (max-width: 445px) {
    height: 30em; 
  }`}

  ${props => props.price ? '' : `@media (max-width: 414px) {
     ${props => props.icon ? 'height: auto' : 'height: 30em'}; 
  }`}

  @media (max-width: 414px) {
    ${props => props.firstParty && !props.privateParties ? 'margin-top: 60px' : ''};
    ${props => props.lastParty ? 'margin-bottom: 32px' : ''};
  }

  ${props => props.homeScreen && `@media (max-width: 414px) {
    ${props => !props.blog && 'height: 25.2em;'}
   }`}

  ${props => (props.homeScreen && props.homeFirst) && `@media (max-width: 414px) {
    height: 27.5em;
  }`}

  ${props => (props.homeScreen && props.homeSecond) && `@media (max-width: 414px) {
      height: 28.2em;
   }`}

   ${props => (props.homeScreen && props.homeThird) && `@media (max-width: 414px) {
      height: 29.6em;
   }`}

   ${props => (props.homeScreen && (props.homeSecond || props.homeThird)) && `@media (max-width: 410px) {
      height: 30.2em;
   }`}

   ${props => (props.homeScreen && props.homeThird) && `@media (max-width: 401px) {
      height: 29.8em;
   }`}

   ${props => (props.homeScreen && props.homeSecond) && `@media (max-width: 400px) {
      height: 31.3em;
   }`}

   ${props => (props.homeScreen && props.homeFirst) && `@media (max-width: 394px) {
      height: 29.5em;
   }`}

   ${props => (props.homeScreen && props.homeSecond) && `@media (max-width: 394px) {
      height: 31.5em;
   }`}

    ${props => (props.homeScreen && props.homeThird) && `@media (max-width: 394px) {
      height: 31.8em;
    }`}

   ${props => (props.homeScreen && props.homeSecond) && `@media (max-width: 386px) {
    height: 31.4em;
   }`}

   ${props => (props.homeScreen && props.homeThird) && `@media (max-width: 386px) {
    height: 31.3em;
   }`}

   ${props => (props.homeScreen && props.homeThird) && `@media (max-width: 369px) {
    height: 33.3em;
   }`}

   ${props => (props.homeScreen && props.homeSecond) && `@media (max-width: 377px) {
    height: 31.4em;
   }`}

   ${props => (props.homeScreen && props.homeFirst) && `@media (max-width: 375px) {
    height: 29.4em;
   }`}

   ${props => (props.homeScreen && props.homeSecond) && `@media (max-width: 375px) {
    height: 31.5em;
   }`}

   ${props => (props.homeScreen && props.homeFirst) && `@media (max-width: 360px) {
    height: 31.4em;
   }`}

   ${props => (props.homeScreen && props.homeSecond) && `@media (max-width: 360px) {
    height: 33.5em;
   }`}

  // ${props => props.blog && `@media (max-width: 1008px) {
  //   height: 26em;
  // }`}

  // ${props => props.blog && `@media (max-width: 594px) {
  //   height: 28em;
  // }`}

  // ${props => props.blog && `@media (max-width: 560px) {
  //   height: 28em;
  // }`}

  // ${props => props.blog && `@media (max-width: 480px) {
  //   height: 30em;
  // }`}

  // ${props => props.blog && `@media (max-width: 456px) {
  //   height: 30em;
  // }`}

  // ${props => props.blog && `@media (max-width: 398px) {
  //   height: 32em;
  // }`}

  // ${props => props.blog && `@media (max-width: 386px) {
  //   height: 32em;
  // }`}

  // ${props => props.blog && `@media (max-width: 342px) {
  //   height: 37em;
  // }`}

  ${props => props.rentalThirdCard && `@media (max-width: 375px) {
    margin-bottom: -33px;
  }`}

  ${props => (props.spaceRental && props.threeCards) && `@media (max-width: 375px) {
    padding: 24px;
  }`}
`;

const CardImage = styled.img`
  width: ${props => props.privatePartiesProduct ? '100%' : props.price ? '91%' : '100%'};
  height: ${props => props.privatePartiesProduct ? '352px' : props.price ? 'auto' : '192px'};
  ${props => props.privatePartiesProduct && 'min-height: 352px;'}
  margin: ${props => props.privatePartiesProduct ? '0' : props.price ? '1em' : '0'};
  object-fit: cover;
  border-radius: ${props => props.privatePartiesProduct ? '8px' : '8px 8px 0 0'};
  position: relative;
  right: ${props => (props.homeScreen || props.privatePartiesProduct) ? '0' : '0.1em'}; 
  ${props => props.privatePartiesProduct && 'margin-bottom: 24px;'}

  @media (max-width: 1042px) {
    right: ${props => (props.homeScreen || props.privatePartiesProduct) ? '0' : '0.3em'}; 
  }

  @media (max-width: 810px) {
    width: ${props => (props.privatePartiesProduct || props.blog || props.homeScreen) ? '100%' : '95%'}; 
  }

  @media (max-width: 767px) {
    width: ${props => (props.homeScreen || props.privatePartiesProduct) ? '100%' : '95%'}; 
  }
`;

const CardIcon = styled.img`
  ${props => props.check ? 'width: 24px; height: 24px;' : ''}
`;

const CardBottomSection = styled.div`
    padding: 0 ${props => props.privatePartiesProduct ? '0' : '1em'} ${props => props.blog ? '1em' : props.privatePartiesProduct ? '0em' : props.giftCards ? '.2em' : '2em'} ${props => props.icon ? '0em;' : '1em;'}
    ${props => props.privateParties && 'padding: 0;'}
    ${props => (props.spaceRental && props.threeCards) && 'padding: 0;'}
    text-align: left;
    margin-right: ${props => props.price ? '.5em' : '0'};
    position: relative;
    ${props => props.price && 'right: 1em;'}
    ${props => props.privatePartiesProduct && 'width: 102%;'}

    ${props => props.homeScreen && !props.blogEntry && 'position: static;'}

    ${props => props.giftCards && `@media (max-width: 628px) {
      padding: 0;
    }`}
`

const CardHeader = styled.h4`
  font-weight: ${props => props.privatePartiesProduct ? 600 : props.price ? 400 : 700};
  font-size: 24px;
  font-family: Inter;
  line-height: ${props => props.privatePartiesProduct ? props.bottomPrice ? '30px' : '36px' : '30px'};
  color: ${props => props.icon ? '#6B7280' : props.subline ? '#156763' : '#111928'}; 
  margin-top: ${props => props.price ? '.03em' : props.homeScreen ? '20px' : '.5em'};
  ${props => (props.bottomPrice && !props.privatePartiesProduct) && 'position: absolute; top: 6.5em;'}
  ${props => (props.bottomPrice && props.privatePartiesProduct && !props.privatePartiesProductThird) && 'top: 7.1em;'}
  ${props => props.bottomPrice && props.privatePartiesProduct && 'font-style: italic;'}

  ${props => (props.privatePartiesProductThird) && `@media (max-width: 1180px) {
    top: 7.8em;
  }`}

  ${props => (props.privatePartiesProduct && !props.privatePartiesProductThird) && `@media (max-width: 1180px) {
    top: 8.3em;
  }`}


  ${props => (props.homeScreen) && `@media (max-width: 375) {
    margin-top: 0.545em;
  }`}


  ${props => props.bottomPrice && !props.privatePartiesProduct && !props.privatePartiesProduct && `@media (max-width: 1078px) {
    position: absolute;
    top: 8.5em;
  }`}

  ${props => props.privatePartiesProductThird && !props.privatePartiesProduct && `@media (max-width: 1078px) {
    position: absolute;
    top: 8em;
  }`}

  ${props => props.bottomPrice && !props.privatePartiesProduct && `@media (max-width: 1016px) {
    position: absolute;
    top: 9.8em;
  }`}

  ${props => props.privatePartiesProductThird && !props.privatePartiesProduct && `@media (max-width: 1016px) {
    position: absolute;
    top: 9em;
  }`}


  ${props => props.bottomPrice && !props.privatePartiesProduct && !props.privatePartiesProduct && `@media (max-width: 881px) {
    position: absolute;
    top: 9.8em;
  }`}

  ${props => props.privatePartiesProductThird && !props.privatePartiesProduct && `@media (max-width: 881px) {
    position: absolute;
    top: 9.2em;
  }`}

  ${props => props.bottomPrice && !props.privatePartiesProduct && `@media (max-width: 856px) {
    position: absolute;
    top: 10.3em;
  }`}

  ${props => props.privatePartiesProductThird && !props.privatePartiesProduct && `@media (max-width: 856px) {
    position: absolute;
    top: 9.7em;
  }`}

  ${props => props.bottomPrice && !props.privatePartiesProduct && !props.privatePartiesProduct && `@media (max-width: 840px) {
    position: absolute;
    top: 11.3em;
  }`}

  ${props => props.privatePartiesProductThird && !props.privatePartiesProduct && `@media (max-width: 840px) {
    position: absolute;
    top: 10.7em;
  }`}

  ${props => props.bottomPrice && !props.privatePartiesProduct && `@media (max-width: 795px) {
    position: absolute;
    top: 11.1em;
  }`}

  ${props => props.bottomPrice && !props.privatePartiesProduct && `@media (max-width: 810px) {
    position: relative;
    top: 3.5em;
  }`}

  ${props => props.privatePartiesProductThird && !props.privatePartiesProduct && `@media (max-width: 810px) {
    position: relative;
    top: 3.1em;
  }`}

  ${props => props.bottomPrice && !props.privatePartiesProduct && `@media (max-width: 586px) {
    position: relative;
    top: 4.8em;
  }`}

  ${props => props.privatePartiesProductSecond && !props.privatePartiesProduct && `@media (max-width: 586px) {
    position: relative;
    top: 3.8em;
  }`}

  ${props => props.privatePartiesProductSecond && !props.privatePartiesProduct && `@media (max-width: 562px) {
    position: relative;
    top: 4.8em;
  }`}

  ${props => props.privatePartiesProductThird && !props.privatePartiesProduct && `@media (max-width: 586px) {
    position: relative;
    top: 4.4em;
  }`}

  ${props => props.privatePartiesProductThird && !props.privatePartiesProduct && `@media (max-width: 441px) {
    position: relative;
    top: 5.4em;
  }`}

  ${props => (props.bottomPrice && !props.privatePartiesProductThird && !props.privatePartiesProduct) && `@media (max-width: 441px) {
    position: relative;
    top: 5em;
  }`}

  ${props => (props.bottomPrice && !props.privatePartiesProduct && !props.privatePartiesProductThird) && `@media (max-width: 429px) {
    position: relative;
    top: 6em;
  }`}

  ${props => props.privatePartiesProductSecond && !props.privatePartiesProduct && `@media (max-width: 414px) {
    position: relative;
    top: 6em;
  }`}

  ${props => props.privatePartiesProductThird && !props.privatePartiesProduct && `@media (max-width: 377px) {
    position: relative;
    top: 6.4em;
  }`}
`;

const CardParagraph = styled.p`
  color: ${props => props.privatePartiesProduct ? '#6B7280' : 'rgba(107, 114, 128, 1)'};
  font-size: ${props => props.parties ? '17px;' : '20px;'}
  ${props => props.privatePartiesProduct ? 'line-height: 30px;' : props.parties && 'line-height: 24px;'}
  font-weight: 400;
  font-family: Inter;
  text-align: left;
  margin-top: 8px;
  // ${props => (props.spaceRental && props.threeCards) && 'font-size: 16px; line-height: 24px; color: #6B7280;'}
`;

const Subline = styled.p`
  color: #0C120E;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin: .5em 0;
`

export const Card = ({ lastParty, partiesOnly, parties, firstParty, secondParty, htmlText, rentalThirdCard, homeFirst, homeSecond, homeThird, blogEntry, privatePartiesProductSecond, privatePartiesProductThird, link,  privatePartiesProduct, spaceRental, categories, author, content, postId, blog, homeScreen, giftCards, privateParties, noLeft, threeCards, subline, buttonText, icon, price, check, image, headerText, paragraphText }) => {
  const [animate, setAnimate] = useState(true)

  useEffect(() => {

    const timer = setTimeout(() => {
      setAnimate(false);
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  return (
  <CardContainer firstParty={firstParty} secondParty={secondParty} partiesOnly={partiesOnly} lastParty={lastParty} parties={parties} rentalThirdCard={rentalThirdCard} homeFirst={homeFirst} homeSecond={homeSecond} homeThird={homeThird} animate={animate && blogEntry} privatePartiesProduct={privatePartiesProduct} spaceRental={spaceRental} blog={blog} homeScreen={homeScreen} giftCards={giftCards} bottomPrice={price} privateParties={privateParties} threeCards={threeCards} buttonText={buttonText} icon={icon} price={price}>
    {!image ? '' : icon ? <CardIcon src={image} check={check} /> : <CardImage blog={blog} privatePartiesProduct={privatePartiesProduct} privateParties={privateParties} homeScreen={homeScreen} price={price} src={image} />}
    <CardBottomSection blog={blog} blogEntry={blogEntry} privatePartiesProduct={privatePartiesProduct} privateParties={privateParties} spaceRental={spaceRental} threeCards={threeCards} giftCards={giftCards} homeFirst={homeFirst} homeSecond={homeSecond} homeThird={homeThird} homeScreen={homeScreen} icon={icon} price={price}>
        {blog ?     
        <CardHeader
          dangerouslySetInnerHTML={{__html: headerText.slice(0,42) + '...'}}
        /> : <CardHeader homeScreen={homeScreen} subline={subline} icon={icon} price={price}>{headerText}</CardHeader>}
        {subline && <Subline>{subline}</Subline>}
        {htmlText ?
        <div dangerouslySetInnerHTML={{__html: htmlText}}/>
        :
        <CardParagraph privatePartiesProduct={privatePartiesProduct} parties={parties} spaceRental={spaceRental} threeCards={threeCards} icon={icon}>{paragraphText}</CardParagraph>}
        {price ? 
        !privatePartiesProduct ?
          <div style={{position: 'absolute', top: '1px'}}><CardHeader privatePartiesProduct={privatePartiesProduct} privatePartiesProductSecond={privatePartiesProductSecond} privatePartiesProductThird={privatePartiesProductThird} bottomPrice={price}>{`$${price}`}</CardHeader></div> :
          <CardHeader privatePartiesProduct={privatePartiesProduct} privatePartiesProductSecond={privatePartiesProductSecond} privatePartiesProductThird={privatePartiesProductThird} bottomPrice={price}>{`$${price}`}</CardHeader>
        : !icon && <CallToActionButton giftCards={giftCards} homeFirst={homeFirst} homeSecond={homeSecond} homeThird={homeThird} link={link} categories={categories} title={headerText} author={author} date={subline} content={content} postId={postId} blog={blog} homeScreen={homeScreen} noLeft={noLeft} buttonText={blog ? "READ MORE" : buttonText ? "BUY NOW" : "LEARN MORE"} absolute={true} bottom={'17px'} bgColor={'#fffeeb'} textColor={'rgba(21, 103, 99, 1)'} />}
    </CardBottomSection>
  </CardContainer>
)};