import React, { useState } from 'react';
import styled from 'styled-components';

const ButtonSwitchContainer = styled.div`
  display: flex;
  max-width: 768px;
  height: 58px;
  margin: 0 auto;
`;

const Button = styled.button`
  flex: 1;
  text-align: center;
  font-weight: 500;
  line-height: 30px;
  font-size: 20px;
  color: ${(props) => (props.isSelected ? '#0C120E' : '#6B7280')};
  background-color: ${(props) => (props.isSelected ? '#F5F5F5' : '#fff')};
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
  }
`;

const ButtonSwitch = ({ buttonText1, buttonText2, setButtonSelected }) => {
  const [selectedButton, setSelectedButton] = useState(buttonText1);

  const handleButtonClick = (buttonText) => {
    setSelectedButton(buttonText);
    setButtonSelected(buttonText === buttonText1 ? 0 : 1)
    // onClickHandler(buttonText);
  };

  return (
    <ButtonSwitchContainer>
      <Button
        isSelected={selectedButton === buttonText1}
        onClick={() => handleButtonClick(buttonText1)}
      >
        {buttonText1}
      </Button>
      <Button
        isSelected={selectedButton === buttonText2}
        onClick={() => handleButtonClick(buttonText2)}
      >
        {buttonText2}
      </Button>
    </ButtonSwitchContainer>
  );
};

export default ButtonSwitch;