import { useEffect, useState, useContext, useRef } from 'react';
import { ChooseStudio } from '../components/choose-studio';
import styled from 'styled-components';
import HeroBanner from '../components/hero';
import { Navbar } from '../components/nav';
import PromoBanner from '../components/promo-banner';
import Footer from '../components/footer';
import RedefineDanforth from '../assets/rdf-dan-studio.webp';
import StyledDiv from '../components/styled-div';
import TopLeft from '../assets/rdf-bw-team-compressed.webp';
// import TopRight from '../assets/group-four-topright.jpg';
import MobileQuoteOne from '../assets/quote-mobile-1.svg';
import MobileQuoteTwo from '../assets/quote-mobile-2.svg';
import TopRight from '../assets/review-jb.svg';
// import BottomLeft from '../assets/group-four-bottomleft.png';
import BottomLeft from '../assets/review-victoria-p.svg';
import BottomRight from '../assets/rdf-bw-instructor-crucifix-compressed.webp';
import { scaleNoShadow } from '../utils/keyframes.js';
import { LogoContext } from '../context/LogoContext.js';
import { useWindowSize } from '../hooks/use-window-size.js';
import Workshop1 from '../assets/workshop-1.png';
import { ReactComponent as InstagramIcon } from './../assets/InstagramIcon.svg';
import { CallToActionButton } from '../utils/cta-button';
import Joanne from '../assets/instructor-joanne.png';
import Kerri from '../assets/instructor-kerri.jpg';
import Karen from '../assets/instructor-karen.jpg';
import Mac from '../assets/instructor-mac.jpg';
import Default from '../assets/instructor-default.png';
import Christine from '../assets/instructor-christine.png';
import Elspeth from '../assets/instructor-elspeth.jpg';
import Ashlee from '../assets/instructor-ashlee.png';
import CC from '../assets/instructor-cc.jpg';
import Som from '../assets/instructor-som.jpg';
import Stephanie from '../assets/instructor-stephanie.jpg';
import Tanya from '../assets/instructor-tanya.jpg';


const StyledH3 = styled.h3`
  font-size: 36px;
  font-weight: 700;
  text-align: left;
  color: rgba(12, 18, 14, 1);
  font-family: Inter;
  margin-bottom: 24px;
`;

const ContainerDiv = styled.div`
    ${props => props.maxWidth ? `max-width: ${props.maxWidth}px;` : ''}
    margin: 0 ${props => (props.logoleftposition && window.innerWidth > 767) ? `${props.logoleftposition}px` : 'auto'};
    margin-top: -3em;
    margin-bottom: 3em;
`

const StyledP = styled.p`
    text-align: left;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #0C120E;
`

const QuarterImage = styled.img`
  width: 100%;
  height: 320px;
  max-width: ${props => props.addedWidth ? props.maxWidth ? `${props.maxWidth};` : '570px;' : '546px;'}
  object-fit: ${props => props.contain ? 'contain' : 'cover'};
  border-radius: 8px;
  margin: 12px;
  display: inline;
  &:hover {
    animation: ${scaleNoShadow} .5s ease 0s 1 normal forwards;
  }

  @media (max-width: 621px) {
    margin: 24px 0;
  }

  @media (max-width: 621px) {
    margin: 8px 0;
  }

  ${props => {
    return (props.mobileSecond && `@media (max-width: 406px) {
      object-fit: none;
      height: auto;
  }`)}}
`;

const InstructorCard = styled.div`
  color: #FFF;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  min-height: 727px;
  min-width: ${props => ((props.logoleftposition) && (window.innerWidth <= 480)) ? '90%' : (window.innerWidth <= 480) ? '90%' : '384px'};
  ${props => ((props.logoleftposition) && window.innerWidth > 463) && 'width: 384px;'}
  margin: 17px;
  border-radius: 8px;
`

const IconLink = styled.a`
    width: 21.99px;
    height: 22px;   
    
    @media (max-width: 414px) {
      margin: 0px auto 16px;
    }
`

const InstructorImage = styled.img`
  border-radius: 8px;
  width: 144px; 
  height: 144px;
  object-fit: cover;
  
  @media (max-width: 414px) {
    width: ${props => props.instructorCardRef?.current?.offsetWidth}px; 
    height: ${props => props.instructorCardRef?.current?.offsetWidth}px;
  }
`


function WhoWeAre() {
  const { logoLeftPosition } = useContext(LogoContext);
  const [logoLeftPos, setLogoLeftPos] = useState();
  const [width, height] = useWindowSize(); 
  const instructorCardRef = useRef(null);

  useEffect(() => {
    document.title = 'Redefine Fit - Who We Are';
  }, []);

  useEffect(() => {
    if (logoLeftPosition) {
      //  
      setLogoLeftPos(logoLeftPosition)
    }
  }, [logoLeftPosition])

  return (
    <div className="App">
      <PromoBanner />
      <Navbar />
      <HeroBanner titleText='Who We Are' coverImages={[RedefineDanforth]} height={'520px'} />
      <StyledDiv noBottomMargin={true} whoWeAre={true} topArea={true} logoleftposition={logoLeftPos && logoLeftPos} bodyText='Our pole dance and fitness community prioritizes inclusivity, safety, and support for all students, regardless of fitness background, age, or gender identity. Our curriculum is carefully designed with over a decade of pole experience, and a PhD in Education, ensuring you’ll feel right at home here.' bgColor={'#f5fff7'} textAlign={'left'} 
       />

      <StyledDiv whoWeAre={true} bgColor={'#fff'}>
        <ContainerDiv logoleftposition={logoLeftPos && logoLeftPos}>
            <StyledH3>Our Story</StyledH3>

            <StyledP>2 Studios. 1 Family! Welcome to our Redefine Fit Family!</StyledP>
            <br/>

            <StyledP>Located in Toronto, Ontario, we’re more than just a pole dance studio—we’re a community, a family, and a second home. With two welcoming locations on the Danforth and in Bloor West, we create a safe and inclusive space for everyone, regardless of fitness experience, age, or gender identity. Join us for studio socials, outings, and much more!</StyledP>
            <br/>
            
            <StyledP>Founded by a passionate pole enthusiast who began pole dancing in 2012 and started teaching in 2013, our studio combines over a decade of experience with a PhD in Social Justice Education from the University of Toronto. Redefine Fit is built on an anti-oppressive framework and a trauma-informed lens. Our curriculum is thoughtfully designed to incorporate adult learning theories, ensuring the best experience for our students. With small class sizes (1:7 at Danforth and 1:9 at Bloor West), you’ll receive personalized attention, feel comfortable with the content, and make new friends along the way.</StyledP>
            <br/>
            
            <StyledP>Since opening our first studio on Danforth Avenue in October 2015 and our second in Bloor West in April 2023, we’ve been redefining fitness. From pole dancing to alternative workouts like chair, lolli and flex fit, we offer something for everyone. Whether you're new to pole or a seasoned pro, our supportive instructors are here to help you every step of the way. Come join our family and redefine what fitness means to you!</StyledP>
        </ContainerDiv>
      </StyledDiv>

      {width > 1229 ?
      <StyledDiv whoWeAre={true} bgColor={'#fff'}>
        <ContainerDiv logoleftposition={logoLeftPos && logoLeftPos} >
            <QuarterImage  addedWidth={true} src={TopLeft}/>
            <QuarterImage contain={true} addedWidth={true} src={TopRight}/>
            <QuarterImage contain={true} addedWidth={true} src={BottomLeft}/>
            <QuarterImage  addedWidth={true} src={BottomRight}/>
        </ContainerDiv>
      </StyledDiv>
      :
      <StyledDiv whoWeStacked={true} whoWeAre={true} bgColor={'#fff'}>
      <ContainerDiv logoleftposition={logoLeftPos && logoLeftPos} maxWidth='1176'>
          <QuarterImage addedWidth={true} src={TopLeft}/>
          <QuarterImage addedWidth={true} src={width <= 512 ? MobileQuoteOne : TopRight} mobileSecond={true}/>
          <QuarterImage addedWidth={true} src={BottomRight}/>
          <QuarterImage addedWidth={true} src={width <= 512 ? MobileQuoteTwo : BottomLeft}/>
      </ContainerDiv>
      </StyledDiv>}

      <StyledDiv whoWeAre={true} actualWidth={width} titleText={true} titleCopy='Our Instructors & Admin Team!' logoleftposition={logoLeftPos && logoLeftPos} bodyText='Introducing the Redefine Fit family! Each team member comes from a unique background and set of experiences. Choose who you want to learn from and grow with.' bgColor={'#f5fff7'} textAlign={'left'} 
      />

      <StyledDiv instructors={true} overflowX={true} row={true} bgColor={'#fff'}>
        {[
          {
            "name": "Joanne Jade",
            "image": Joanne,
            "roles": ["Instructor (pole)", "Admin"],
            "insta": "https://www.instagram.com/08jojo/",
            "description": [
              "Joanne fell in love with pole after trying it for the first time in 2012 and has never looked back since! Joanne began teaching pole as a sub in 2013, and teaching her regular classes in January 2014. Joanne loves the progress she sees in students, and is thrilled to be part of the vibrant Redefine Fit family and is always thrilled to represent the studio <3",
              "She loves the support within the pole community, having both competed and placed in CPFA and PSO as well as lending her expertise in pole as a judge for competitions from Vertical Love’s Great White North, CPFA and PSO. Joanne also lends her expertise and experience in pole and previously sat for a term on the IPSF (International Pole Sport Federation) sub committee, which is the international governing body applying for Olympic status for pole sports."
            ]
          },
          {
            "name": "Kerri",
            "image": Kerri,
            "roles": ["Instructor (pole)", "Admin"],
            "insta": "https://www.instagram.com/kcorail/",
            "description": [
              "Kerri started her pole journey with the Redefine Fit family in 2019 after being inspired by videos of tricks online.  She added Lolli classes in 2022 and Flex Fit classes in 2023.  While choreography is not her specialty, she does enjoy attending Heels Conditioning and Belly Dancing with Swords classes.  In 2024 Kerri joined the administrative team and helps as a substitute teach when possible.  She enjoys combining her love of pole, lolli and shibari and looks forward to continuing to grow with Redefine Fit.  Her preference is to be upside down and spinning and hopes to find a way to complete her administrative work like this."
            ]
          },
          {
            "name": "Karen Lee",
            "image": Karen,
            "roles": ["Instructor (pole)"],
            "insta": "https://www.instagram.com/kayyleeshapes/",
            "description": [
              "Karen had been teaching ballet for 7 years before first stepping up to the pole in 2020. New to pole sport but not to movement, she fell hard and fast and worked hard to build the upper body strength she's always dreamed of. Exotic and sensual flow became the perfect playground to experiment and combine her new found strength with her ballet foundations. It wasnt long before pole opened doors to lollipop Lyra, Lyra, spin pole, floor flow, and chair flow. Reigniting her love for dance, she now spends all of her time dabbling in many styles of dance including heels, salsa, bachata, theatre jazz, and of course, classical ballet. ",
              "Fun fact: Despite dancing her whole life, Karen has never competed in any dance competition!"
            ]
          },
          {
            "name": "Mac",
            "image": Mac,
            "roles": ["Instructor (pole)"],
            "insta": "https://www.instagram.com/captainmac.circus/",
            "description": [
              "Mac (they/them) is a fiery Aries, queer cryptid, circus Ken and them fatale here to inspire you on your lollipop and/or flexibility journey. In 2023 they spent three months in Seattle training contortion full-time with two coaches.",
              "As a naturally inflexible human, their tenacity and progress in contortion training makes them the perfect flex coach for beginners and those who are worried it might not be possible for them.",
              "Mac started coaching in 2019, and teaches all over Toronto and online. They commute by bike, drink too much coffee, and rotate through contortion, aerial, hand balancing, martial arts, and weightlifting in any given week. Expect kpop, classic rock and synthwave to be on their class playlists. If you make a Dune reference in class you will get a gold star."
            ]
          },
          {
            "name": "Amanda Jean",
            "image": Default,
            "roles": ["Instructor (pole + lyrapole)"],
            "insta": "",
            "description": [
              "I am a dancer, certified personal trainer and group fitness instructor. I hold additional certifications & experience in teaching to typically under-represented groups in the dance and fitness industry, such as plus-sized pole dancers and people with disabilities.",
              "There's nothing I love more than seeing my students gain confidence in their abilities!"
            ]
          },
          {
            "name": "Ashlee Michelle",
            "image": Ashlee,
            "roles": ["Instructor (pole)"],
            "insta": "https://www.instagram.com/ashleemichelle_pole",
            "description": [
              "Ashlee has been active in Toronto's Pole dance community since 2013, and teaching at Redefine Fit since 2022. She aims to provide a fun environment in class with a thorough breakdown on pole technique- and of course, a healthy sprinkling of conditioning! Join her for beginner, spin, and intermediate classes."
            ]
          },
          {
            "name": "CC Darling",
            "image": CC,
            "roles": ["Instructor (lyrapole + flexibility)"],
            "insta": "https://www.instagram.com/cc.darling/",
            "description": [
              "CC Darling is an award-winning pole dancer and aerialist, and has been teaching and performing professionally since 2019. She specializes in teaching lollipop lyra, pole, and flexibility at Redefine."
            ]
          },
          {
            "name": "Christine",
            "image": Christine,
            "roles": ["Instructor (pole)", "Admin"],
            "insta": "",
            "description": [
              "Christine (she/her) is a digital designer by day and pole dancer by night. With no formal background in dance, she stumbled head over (Hella) heels over a chrome pole in 2021. She loves building strength in both body and mind by exploring gravity-defying shapes. She enjoys helping students with no background in dance or fitness discover and delight in what their bodies can do. Sometimes you’ll catch her at the front desk—say hi and she’ll greet you with a big smile!",
              "When she's not collecting pole bruises (kisses), she's capturing the beauty and oddities of nature with her FUJIFILM Instax camera."
            ]
          },
          {
            "name": "Elspeth",
            "image": Elspeth,
            "roles": ["Instructor (pole)"],
            "insta": "https://www.instagram.com/itselspeth/",
            "description": [
              "Elspeth (they/she) is a Toronto-based circus artist and pole instructor, who is on a never-ending journey to explore the realm of pole to their fullest ability. With over 8 years of experience, Elspeth's signature style intertwines fluid dynamics with dramatic flair, earning them recognition, such as winning Pole Theatre Canada in 2022, and several other accolades throughout their career. Elspeth is also a proud competition coach, guiding many of their own students toward competition recognition!",
              "At Redefine Fit, Elspeth teaches Pole Intermediate 102, 103, and Acro Pole (Flips and Dynamics, and handstands), with an emphasis on conditioning and progressive steps toward advanced skills. Outside of the pole world, they are currently completing their PhD in Physics, and can often be found petting cats, or training other disciplines such as contortion, handbalancing, and aerial circus."
            ]
          },
          {
            "name": "Grace",
            "image": Default,
            "roles": ["Instructor (pole)"],
            "insta": "",
            "description": [
              "Bio coming soon 💙"
            ]
          },
          {
            "name": "Som (SO-Mmm)",
            "image": Som,
            "roles": ["Instructor (pole)"],
            "insta": "https://www.instagram.com/SOMKONG/",
            "description": [
              "I started pole dancing during Covid, and it completely redefined my fitness experiece. Pole brings me joy, and sharing that joy with others brings me even greater happiness. While I can't choose a favorite move, sequences like infiniti/loop conditionings hold a special place in my heart for their endless combinations, improving strength, coordination & stamina.",
              `My teaching style is encouraging & supportive, fostering a positive environment for growth. My paradigm-shifting books include "A New Earth" by Eckhart Tolle, "Big Magic" by Elizabeth Gilbert, & "The Four Agreements" by Don Miguel Ruiz`
            ]
          },
          {
            "name": "Stephanie",
            "image": Stephanie,
            "roles": ["Instructor (pole)"],
            "insta": "https://www.instagram.com/sdpham/",
            "description": [
              "Stephanie began her pole journey in 2012. She was inspired by circus artists and performers as a kid and thought hey, I'd love to do that one day! With skills of a couch potato and little flexibility, she embarked on a dance and fitness adventure that gave her confidence, strength, flexibility, self-love, patience, and a community filled with people from all walks of life. Today she continues to grow in her passion for pole while sharing that same energy and admiration for her students as they blossom in their own journey.", 
              "In 2019 Stephanie placed 3rd in Semi-Pro, Artistic Pole at CPSF. She is also a full-time engineer and loves to bake desserts. Pole dancing is one way to enjoy sweets guilt-free!"
            ]
          },
          {
            "name": "Tanya",
            "image": Tanya,
            "roles": ["Instructor (pole)"],
            "insta": "",
            "description": [
              "After moving to Toronto from Ireland, I started pole dance as a student at Redefine Fit, and have never left. I began my journey in 2019, and in 2021 I became an instructor at the studio! Catch me exploring and experimenting with different tricks on the spin pole. I especially love moves that test my strength. Come join one of my classes, and we'll get stronger together.",
              "Outside of the studio, I like to wind down under the sun with a good book in one hand and a sweet cider in the other. Fun fact: I read around 50–60 books a year!"
            ]
          },
          {
            "name": "Vina",
            "image":  Default,
            "roles": ["Instructor (pole)"],
            "insta": "https://www.instagram.com/Vibrant.vina",
            "description": [
              "Vina is a passionate midsize Pole Dancing and Pilates instructor who discovered the transformative power of creative movement after a life-changing diagnosis. She excels in assisting beginners by simplifying and breaking down movements, while also encouraging consistent practice. Her classes celebrate individuality and strength to enhance body confidence. You might find her participating in classes alongside students - don't be shy, say hi!"
            ]
          },
          {
            "name": "Zoe",
            "image": Default,
            "roles": ["Instructor (pole)"],
            "insta": "https://www.instagram.com/zo.pole",
            "description": [
              "I began pole dancing in 2017 and haven't looked back since! Pole dance has been such a powerful journey for me, helping to build my confidence, strength, and mind body connection. I love sharing my passion with my students, seeing them progress, and cheering them on when they achieve something they didn't think they would at the start of a class! I enjoy many styles of pole, however, I especially love the slinky, wavy and sensual side."
            ]
          },
        ].map((instructor) => {
        return (
         <InstructorCard ref={instructorCardRef} logoleftposition={logoLeftPos && logoLeftPos}>
            <div style={{display: 'flex', flexDirection: width <= 414 ? 'column' : 'row', margin: '1em'}}>
              <InstructorImage instructorCardRef={instructorCardRef} src={instructor.image} />
              <div style={{display: 'flex', flexDirection: 'column', marginLeft: '.5em', textAlign: width <= 414 ? 'center' : 'left', marginTop: '20px'}}>
                <p style={{color: '#0C120E', fontSize: '20px', fontWeight: '500', lineHeight: '25px'}}>{instructor.name}</p>
                {instructor.roles.map(role => {
                  return (
                    <p style={{color: '#6B7280', fontSize: '16px', fontWeight: '400', lineHeight: '24px', fontFamily: '"Inter-Regular", Helvetica'}}>{role}</p>
                  )
                })}
                {instructor.insta !== "" && <IconLink target="_blank" href={instructor.insta}><InstagramIcon /></IconLink>}
              </div>
            </div>
          {instructor.description.map(para => <p style={{color: '#6B7280', fontWeight: '400', lineHeight: '24px', fontSize: '16px', margin: '1em', textAlign: 'left'}}>{para}</p>)}
          <CallToActionButton bookClass={true} instructorBook={true} noLeft={true} buttonText={"BOOK CLASS"} bottom={'17px'} bgColor={'#fffeeb'} textColor={'rgba(21, 103, 99, 1)'} />   
        </InstructorCard>
        )})}
      </StyledDiv>

      <ChooseStudio background={'teal'} />

      <StyledDiv whoWeAre={true} bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default WhoWeAre;