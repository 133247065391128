import { useState } from "react";
import styled from 'styled-components';
import { ReactComponent as ChevronDown } from './../assets/chevron-down.svg';
import { ReactComponent as ChevronUp } from './../assets/chevron-up.svg';
import { slideDown } from "../utils/keyframes";
import { slideUp } from "../utils/keyframes";
import { Link } from "react-router-dom";

const StyledH3 = styled.h3`
  color: rgba(12, 18, 14, 1);
  text-align: left;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  max-width: 86%;
  color: ${props => props.isOpen ? '#156763' : '#0C120E'};
`;

const StyledP = styled.p`
    text-align: left;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 1em;
    ${props => props.afterInline && 'margin-top: 1em;'}
    color: #0C120E;
    ${props => props.inline && 'display: inline; text-align: left !important;'}
    ${props => props.inlineBlock && 'display: inline-block; text-align: left !important; margin-bottom: 0;'}

    @media (max-width: 939px) {
      line-height: 20px;
    }
`

const AccordionWrapper = styled.div`
  border: 1px solid green;
  border-radius: ${props => props.isOpen? '8px 8px 0 0' : '8px'};
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  background-color: ${props => props.isOpen ? '#f5fff7' : '#fff'};
  color: ${props => props.isOpen ? '#156763' : '#0C120E'};
  z-index: 9998;
  position: relative;
  text-align: left;
`;

const AccordionContent = styled.div`
  max-height: ${props => props.isOpen ? '1000px' : '0'}; 
  overflow: hidden;
  animation: ${props => props.hasToggled ? (props.isOpen ? slideDown : slideUp) : 'none'} 1s forwards;
  border: 1px solid green;
  border-top: none;
  border-radius: 0 0 8px 8px;
  padding: 2.2em 1.5em 1em 1.5em;
  position: relative;
  bottom: 52.2px;
  z-index: 999;
  text-align: left;
`;

const StyledLi = styled.li`
    text-align: left;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    @media (max-width: 939px) {
      line-height: 22px;
    }
    color: #0C120E;
`;

const StyledLink = styled(Link)`
  color: #156763;
  font-family: Inter;
  font-weight: 400;
  text-decoration: underline;
  line-height: 24px;
  font-size: 16px;
  cursor: pointer;
`

export const Accordion = ({titleText, bodyObject}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasToggled, setHasToggled] = useState(false);

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
      setHasToggled(true); // Set hasToggled to true on first toggle
  };
  
    return (
      <>
      <br/>
      <div style={{margin: '-28px auto'}}>

        <AccordionWrapper onClick={toggleAccordion} isOpen={isOpen} >
        <StyledH3 isOpen={isOpen}>{titleText}</StyledH3>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
        </AccordionWrapper>

        <AccordionContent isOpen={isOpen} hasToggled={hasToggled}>
          <div style={{marginTop: '28px', marginBottom: '13px', top: '6px', position: 'relative'}}>
            {bodyObject.map(bodyElement => {
                switch(bodyElement[0]) {
                  case 'a':
                    return <StyledLink target="_blank" to={bodyElement[1].href}>{bodyElement[1].text}</StyledLink>
                  case 'pib':
                      return <StyledP inlineBlock={true} afterInline={true} >{bodyElement[1]}</StyledP>;
                  case 'pai':
                      return <StyledP inline={true} afterInline={true} >{bodyElement[1]}</StyledP>;
                  case 'ps':
                    return <StyledP inline={true}>{bodyElement[1]}</StyledP>;
                  case 'pa':
                      return <StyledP afterInline={true} >{bodyElement[1]}</StyledP>;
                  case 'p':
                      return <StyledP >{bodyElement[1]}</StyledP>;
                  case 'ul':
                    return (
                      <ul style={{marginBottom: '12px'}}>
                        {bodyElement[1].map(item => <StyledLi>{item}</StyledLi>)}
                      </ul>
                    );
                  default:
                    return null;
                }
            })}
          </div>
        </AccordionContent>
      </div>
      </>
    );
};