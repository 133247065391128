import { useEffect, useState, useContext } from 'react';
import { ChooseStudio } from '../components/choose-studio';
import { useLocation } from 'react-router-dom';
import HeroBanner from '../components/hero';
import { Navbar } from '../components/nav';
import PromoBanner from '../components/promo-banner';
import Footer from '../components/footer';
import RedefineDanforth from '../assets/rdf-dan-studio.webp';
import StyledDiv from '../components/styled-div';
import { useWindowSize } from '../hooks/use-window-size.js';
import styled from 'styled-components';
import RedefineTeam from '../assets/redefine-team.png';
import { useNavigate } from 'react-router-dom';
import { readingTime } from 'reading-time-estimator';
import { LogoContext } from '../context/LogoContext.js';

const PostContainer = styled.div`
    max-width: 1134px;
    margin: 0 ${props => props.logoleftposition}px;
    margin-top: -3em;
    margin-bottom: 3em;
    display: flex;
    text-align: left;
    line-height: 12px;
    ${props => props.signature && 'flex-direction: column;'}

    iframe {
        margin-top: 1em;
    }

    div {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #0C120E;
    }

    p {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #0C120E;
        margin: 0.2em 0;
    }

    a {
        color: #156763;
        font-family: Inter;
        font-weight: 500;
        text-decoration: underline;
        font-size: 1em;
        cursor: pointer;
        margin-right: 0.5em;
    }

    iframe {
        height: 329px;
        width: 585px;
    }

    @media (max-width: 852px) {
        iframe {
            height: 246px;
            width: 438px;
        }
    }

    @media (max-width: 506px) {
        iframe {
            height: 70%;
            width: 90%;
        }
    }
`

const StyledP = styled.p`
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;  
    color: #0C120E;
    font-style: italic;
`

const StyledDivContent = styled.div`
    line-height: 30px;
    font-weight: 400;
    font-family: Inter;
    div {
        margin: 16px 0;
    }
`

const StyledLink = styled.a`
    color: #156763;
    font-family: Inter;
    font-weight: 500;
    text-decoration: underline;
    font-size: 1em;
    cursor: pointer;
    ${props => props.category && 'margin-right: .5em'}
`

function Post() {
    const [width, height] = useWindowSize(); 
    const {state} = useLocation();
    const navigate = useNavigate();

    const { logoLeftPosition } = useContext(LogoContext);
    const [logoLeftPos, setLogoLeftPos] = useState();
  
    useEffect(() => {
      if (logoLeftPosition) {
        //   
        setLogoLeftPos(logoLeftPosition)
      }
    }, [logoLeftPosition])

    const authorKey = {
        "1": "Redefine Fit"
    }

    const categoryKey = {
        "1": "Uncategorized",
        "4": "Events"
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleAuthor = () => {
         
        navigate(`/blog?author=${state.author}`, {state: {author: state.author}})
    }

    const handleCategory = (category) => {
        navigate(`/blog?category=${category}`, {state: {category: category}})
    }

  return (
    <div className="App">
      <PromoBanner />
      <Navbar />
      <HeroBanner titleText='Blog' coverImages={[RedefineDanforth]} height={'520px'} />

      <StyledDiv bgColor={'#fff'}>
        <br/>
        <PostContainer logoleftposition={logoLeftPos && logoLeftPos}>
            <p style={{color: '#6B7280', fontSize: '20px', lineHeight: '30px', fontWeight: '400'}}>{new Date(state.date).toString().split(' ').slice(1, 4).join(' ') } / {state.categories.map(category => categoryKey[category]).map(cat => <StyledLink category={true} onClick={() => handleCategory(cat)}>{cat}</StyledLink>)}</p>
        </PostContainer>
        <br/>
        <PostContainer logoleftposition={logoLeftPos && logoLeftPos} signature={true}>
            <h2 style={{margin: '0 0 .2em 0', lineHeight: '30px'}} dangerouslySetInnerHTML={{__html: state.title}} />
            <p style={{marginBottom: '.5em', lineHeight: '30px'}}>by <StyledLink onClick={handleAuthor}>{authorKey[state.author]}</StyledLink> <i style={{marginLeft: '.5em'}}>{readingTime(state.unparsedContent).text}</i></p>
        </PostContainer>
        <PostContainer logoleftposition={logoLeftPos && logoLeftPos}>
            <StyledDivContent dangerouslySetInnerHTML={{ __html: state.unparsedContent.split('button::')[0] }} />
        </PostContainer>
        <br/>
        <PostContainer logoleftposition={logoLeftPos && logoLeftPos} signature={true}>
            <StyledP>Dance with strength and joy,</StyledP>
            <img style={{display: 'block', width: '201px', height: '40px', position: 'relative', right: '1em'}} src={RedefineTeam} />
        </PostContainer>
      </StyledDiv>

      <ChooseStudio background={'teal'} />

      <StyledDiv bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default Post;