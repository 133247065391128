import React from 'react';
import styled from 'styled-components';
import { CallToActionButton } from '../utils/cta-button';
import GreenStar from '../assets/check-circle.png';
import BrownStar from '../assets/star.png';
import { useWindowSize } from '../hooks/use-window-size.js';

const Card = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: 2em;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  display: flex;
  min-height: ${props => props.second ? (props.privateParties ? '490px' : '602px') : props.first ? (props.privateParties ? '333px' : '456px') : props.third ? (props.privateParties ? '490px' : '562px') : (props.width <= 850 && props.width > 814) ? '872px' : (props.width <= 931 && props.width > 831) ? '822px' : (props.width <= 957 && props.width > 932) ? '737px' : props.width <= 831 ? '666px' : props => props.width <= 1014 ? '690px' : props.width <= 1099 ? '664px' : props.width <= 1215 ?  '642px' : '610px'}; 
  width:  ${props => props.width <= 900 ? 'auto' : '33'}%;
  flex-direction: column;
  text-align: left; 
  position: relative;
  margin: ${props => props.width <= 831 ? '1em 0' : '1em'};

  ${props => props.passes && `@media (max-width: 910px) {
    width: auto;
  }`}

  ${props => props.passes && `@media (max-width: 1285px) {
    min-height: 627px;
  }`}

  ${props => props.passes && `@media (max-width: 1099px) {
    min-height: 670px;
  }`}

  ${props => props.passes && `@media (max-width: 1080px) {
    min-height: 670px;
  }`}

  ${props => props.passes && `@media (max-width: 1014px) {
    min-height: 685px;
  }`}

  ${props => props.passes && `@media (max-width: 976px) {
    min-height: 711px;
  }`}

  ${props => props.passes && `@media (max-width: 948px) {
    min-height: 790px;
  }`}

  ${props => props.passes && `@media (max-width: 933px) {
    min-height: 822px;
  }`}

  ${props => props.passes && props.secondCard && `@media (max-width: 910px) {
    min-height: 646px;
  }`}

  ${props => props.passes && props.firstCard && `@media (max-width: 910px) {
    min-height: 477px;
  }`}

  ${props => props.passes && props.thirdCard && `@media (max-width: 910px) {
    min-height: 585px;
  }`}

  ${props => props.passes && `@media (max-width: 868px) {
    min-height: 864px;
  }`}

  ${props => props.passes && props.firstCard && `@media (max-width: 868px) {
    min-height: 464px;
  }`}

  ${props => props.passes && props.secondCard && `@media (max-width: 868px) {
    min-height: 630px;
  }`}

  ${props => props.passes && props.secondCard && `@media (max-width: 868px) {
    min-height: 630px;
  }`}

  ${props => props.passes && props.thirdCard && `@media (max-width: 868px) {
    min-height: 579px;
  }`}

  ${props => props.passes && `@media (max-width: 842px) {
    min-height: 905px;
  }`}

  ${props => props.passes && props.firstCard && `@media (max-width: 842px) {
    min-height: 472px;
  }`}

  ${props => props.passes && props.secondCard && `@media (max-width: 842px) {
    min-height: 472px;
  }`}

  ${props => props.passes && props.thirdCard && `@media (max-width: 842px) {
    min-height: 472px;
  }`}

  ${props => props.passes && `@media (max-width: 834px) {
    min-height: 898px;
  }`}

  ${props => props.passes && `@media (max-width: 831px) {
    min-height: ${props.first ? '473px' : props.second ? '653px' : '603px'}
  }`}

  ${props => props.passes && `@media (max-width: 442px) {
    min-height: ${props.first ? '473px' : props.second ? '653px' : '599px'}
  }`}

  ${props => props.passes && `@media (max-width: 437px) {
    min-height: ${props.first ? '473px' : props.second ? '653px' : '599px'}
  }`}

  ${props => props.passes && `@media (max-width: 422px) {
    min-height: ${props.first ? '473px' : props.second ? '653px' : '618px'}
  }`}

  ${props => props.passes && `@media (max-width: 387px) {
    min-height: ${props.first ? '473px' : props.second ? '683px' : '684px'}
  }`}
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonDiv = styled.div`
    ${props => !props.privateParties && 'position: relative;'}
    bottom: 0px;
    width: 100%;



    @media (max-width: 583px) {
      width: 97%
    }

    @media (max-width: 437px) {
      width: 90%
    }

    @media (max-width: 389px) {
      margin-right: 25px;
      left: -18px;
      width: 100%
    }
`

const Title = styled.h3`
  color: #0C120E;
  font-size: 20px;
  font-weight: 500;
`;

const Popular = styled.span`
  background-color: #FDF6B2;
  padding: 0.5em 0;
  border-radius: 8px;
  color: #723B13;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  font-family: Inter;
  position: relative;
  ${props => props.top ? 'top: -18px' : ''};

  @media (max-width: 1168px) {
    margin-top: 18px;
  }

  @media (max-width: 831px) {
    margin-top: 32px;
  }

`;

const Price = styled.h4`
  color: #111928;
  font-weight: 800;
  font-size: 48px;
`;

const PerMonth = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #6B7280;
`;

const OrText = styled.p`
  color: #6B7280;
  font-weight: 500;
  font-size: 18px;
  font-style: italic;
`;

const ListItem = styled.li`
  display: flex;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: #6B7280;
  list-style-type: none;
`;

const PricingCard = ({ firstCard, secondCard, thirdCard, link, right, linkArray, first, second, third, passes, privateParties, noLeft, membershipTitle, favorite, perMonthPrice, orText, listItems, buttonText1, buttonText2, fullLength }) => {
  const [width, height] = useWindowSize(); 

  return (
    <Card passes={passes} firstCard={firstCard} secondCard={secondCard} thirdCard={thirdCard} first={first} second={second} third={third} privateParties={privateParties} width={width}>
      {(width <= 1168 && favorite) && <HeaderSection><Popular top={true}><img style={{display: 'inline', width: '16px', height: '16px', marginLeft: '14px', marginRight: '4px'}} src={BrownStar}/><span style={{fontSize: '14px', lineHeight: '21px', marginRight: '14px'}}>Popular</span></Popular></HeaderSection>}
      <HeaderSection>
        <Title>{membershipTitle}</Title>
        {(width > 1168 && favorite) && <Popular><span style={{fontSize: '14px', lineHeight: '21px', marginRight: '14px'}}><img style={{display: 'inline', width: '16px', height: '16px', marginLeft: '14px', marginRight: '4px'}} src={BrownStar}/>Popular</span></Popular>}
      </HeaderSection>
      <Price>${perMonthPrice}<PerMonth> /month</PerMonth></Price>
      <OrText>{orText}</OrText>
      <br/>
      <ul style={{padding: '0'}}>
        {listItems.map((item, index) => (
          <>
          <ListItem key={index}><div style={{marginRight: '12px'}}><img style={{display: 'inline', position: 'relative', top: `${width > 900 ? '30%' : '11%'}`, marginRight: '20px', height: '20px', width: '20px'}} src={GreenStar} alt="bullet point" /></div> {item}</ListItem>
          <br/>
          </>
        ))}
      </ul>
      {fullLength && 
      <>
        <br/>
        <br/>
      </>}
      <ButtonDiv second={second} right={right} privateParties={privateParties} fullLength={fullLength} width={width}>
        <CallToActionButton passes={passes} firstButton={true} noLeft={second} link={linkArray ? linkArray[0] : link} privateParties={privateParties} fullLength={fullLength} buttonWidth={'100%'} buttonText={buttonText1} bgColor={'#fffeeb'} textColor={'rgba(21, 103, 99, 1)'} />
        {buttonText2 && <CallToActionButton link={linkArray[1]} passes={passes} noLeft={noLeft} buttonWidth={'100%'} buttonText={buttonText2} bgColor={'#fff'} textColor={'rgba(21, 103, 99, 1)'} />}
      </ButtonDiv>
    </Card>
  );
};

export default PricingCard;