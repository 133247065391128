import { createContext, useState } from 'react';

export const LogoContext = createContext(null);

export const LogoProvider = ({ children }) => {

  const [logoLeftPosition, setLogoLeftPosition] = useState(null)

  return (
    <LogoContext.Provider value={{logoLeftPosition, setLogoLeftPosition}}>
      {children}
    </LogoContext.Provider>
  )
}
