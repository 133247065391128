import { useEffect, useState, useContext } from 'react';
import { ChooseStudio } from '../components/choose-studio';
import styled from 'styled-components';
import HeroBanner from '../components/hero';
import { Navbar } from '../components/nav';
import PromoBanner from '../components/promo-banner';
import Footer from '../components/footer';
import RedefineDanforth from '../assets/rdf-dan-studio.webp';
import StyledDiv from '../components/styled-div';
import { CallToActionButton } from '../utils/cta-button';
import { useWindowSize } from '../hooks/use-window-size.js';
import { LogoContext } from '../context/LogoContext.js';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StyledH3 = styled.h3`
  font-size: 36px;
  font-weight: 700;
  text-align: left;
  color: #0C120E;
  font-family: Inter;
  margin-bottom: 0.6em;
`;

const StyledH3Contract = styled.h3`
  font-size: 30px;
  font-weight: 600;
  text-align: left;
  line-height: 45px;
  color: #6B7280;
  font-family: Inter;
  margin-bottom: 0.6em;
`;

const StyledMiniHeader = styled.h6`
    font-size: 24px;
    font-weight: 600;
    left: 0;
    letter-spacing: 0;
    line-height: 36px;
    font-family: Inter;
    color: #0C120E;
    text-align: left;
    margin: .8em 0 0.4em 0;
`

const StyledMediumHeader = styled.h5`
    font-size: 24px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: 30px;
    font-family: "Inter-Bold", Helvetica;
    color: rgba(12, 18, 14, 1);
    text-align: left;
    margin: .8em 0 0.4em 0;
`

const StyledLi = styled.li`
    text-align: left;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
`;

const StyledA = styled.a`
    color: #156863;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.width > 757 ? 'row' : 'column'};
    max-width: 1120px;
    ${props => (props.logoleftposition && props.width > 757) ? `left: ${props.logoleftposition}px` : ''};
    position: relative;
`

const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${props => props.right ? '0 2em' : ''};
    position: relative;
    width: ${props => props.firstColumn ? props.width > 1012 ? '50%' : '45%' : props.width > 1012 ? '582px' : '45%'};
    margin: 0 1em 0 ${props => props.firstColumn ? props.width <= 757 ? '0' : '0' : props.width > 1012 ? '0' : '0'};
    ${props => !props.firstColumn && props.width > 1012 && 'left: 4em;'}

    @media (max-width: 1358px) {
      ${props => !props.firstColumn && props.width < 1012 && 'left: 3em;'}
      ${props => !props.firstColumn ? 'width: 45%;' : 'width: 48%;'} 
    }

    @media (max-width: 1187px) {
      ${props => !props.firstColumn ? 'width: 45%;' : 'width: 42%;'} 
    }


    @media (max-width: 1275px) {
      ${props => !props.firstColumn && props.width < 1012 && 'left: 0em;'}
    }

    @media (max-width: 1241px) {
      ${props => !props.firstColumn && 'left: 2em;'}
    }

    @media (max-width: 1219px) {
      ${props => !props.firstColumn && 'left: 0em;'}
    }

    @media (max-width: 757px) {
      width: 101%;
    }

    @media (max-width: 576px) {
      padding: 0;
    }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1em 1em 2em 1em;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
`;

const StyledLabel = styled.label`
  color: ##111928;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  margin: 8px 0px;
  ${props => props.marginTop && 'margin-top: 20px;'};
`;

const StyledInput = styled.input`
  align-items: center;
  background-color: #f9fafb;
  border: 1px solid;
  border-color: #d1d5db;
  border-radius: 8px;
  padding: 8px 16px 8px;
  font-size: 14px;
  color: #6B7280;
  font-family: Inter;
`;

const StyledTextArea = styled.textarea`
  font-family: "Inter-Regular", Helvetica;
  background-color: #f9fafb;
  border: 1px solid;
  border-color: #d1d5db;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 200;
  padding: 10px;
  resize: none;
  height: 185px;
`;

const StyledP = styled.p`
    font-family: Inter;
    color: #0C120E;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
`

const StudioSpan = styled.span`
    color: #0C120E;
    font-size: Inter; 
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
`

function ContactUs() {
  const [width, height] = useWindowSize(); 
  const { logoLeftPosition } = useContext(LogoContext);
  const [logoLeftPos, setLogoLeftPos] = useState();
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    document.title = 'Redefine Fit - Contact Us';
  }, []);

  useEffect(() => {
    if (logoLeftPosition) {
      //  
      setLogoLeftPos(logoLeftPosition)
    }
  }, [logoLeftPosition])

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(`${process.env.REACT_APP_EMAIL_SERVICE_ID}`, `${process.env.REACT_APP_EMAIL_TEMPLATE_ID}`, e.target, `${process.env.REACT_APP_EMAIL_USER_ID}`)
    .then((result) => {
      if (result.text === 'OK') {
        toast.success(`🎉 Email sent! We'll get back to you soon!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error('Something went wrong. Please try again!', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }, (error) => {
      toast.error('Something went wrong. Please try again!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  }

  return (
    <div className="App">
      <PromoBanner />
      <Navbar />
      <HeroBanner plusTwenty={true} titleText='Contact Us' coverImages={[RedefineDanforth]} height={'520px'} />

      <StyledDiv contactUsContainer={true} noBottomMargin={true} bgColor={'#F5FFF7'}>
        <StyledContainer logoleftposition={logoLeftPos && logoLeftPos} width={width}>
            <StyledColumn firstColumn={true} width={width}>
                <StyledH3>Need something?</StyledH3>

                <StyledP>Reach out to us with any inquiries about our studio or class offerings! If you haven’t already, <StyledA href="/studio-policies">check our studio policies</StyledA> or <StyledA href="/faq">FAQ page</StyledA>.</StyledP>
                <br/>
                <StyledP>For information about hosting private parties, <StyledA href="/private-parties">check our Private Parties page.</StyledA></StyledP>
                <br/>
                <StyledP>If interested in renting our studio for an event or workshop, <StyledA href="/space-rental">check our Space Rental page</StyledA>.</StyledP>
            </StyledColumn>

            {width <= 757 && <><br/><br/></>}
            <StyledColumn right={true} onSubmit={sendEmail} width={width}>
                <StyledForm>
                  <StyledLabel htmlFor="name">Name</StyledLabel>
                  <StyledInput value={name} onChange={(e) => {
                    setName(e.target.value)}} name="from_name" id="name" type="text" placeholder="Jane Doe" />

                  <StyledLabel marginTop={8} htmlFor="email">Email</StyledLabel>
                  <StyledInput value={email} onChange={(e) => {
                    setEmail(e.target.value)}} name="from_email" id="email" type="text" placeholder="email@domain.ca" />

                  <StyledLabel marginTop={8} htmlFor="message">Your message</StyledLabel>
                  <StyledTextArea value={message} onChange={(e) => {
                    setMessage(e.target.value)}} name="html_message" id="message" placeholder="What would you like to ask?" rows="4" />

                  <CallToActionButton fullLength={width <= 403} noLeft={true} margin={'1em 0 0 auto'} disabled={!((email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) && message && name) ? true : false} buttonText={"SEND MESSAGE"} />
                </StyledForm>
            </StyledColumn>
        </StyledContainer>
        <br/>
      </StyledDiv>
      <StyledDiv contactUsContainer={true} contactUs={true} logoleftposition={logoLeftPos && logoLeftPos} bgColor={'#fff'}>
        <StyledContainer width={width}>
          <StyledColumn>
            <StyledH3Contract>Contact & locations</StyledH3Contract>
          </StyledColumn>
          <StyledColumn>
                <StyledMediumHeader>Contact</StyledMediumHeader>
                <div style={{display: 'flex', flexDirection: 'column', marginBottom: '.45em', textAlign: 'left', lineHeight: '30px'}}>
                    <StyledA href="tel:16475399439">(647) 539-9439</StyledA>   
                  
                    <StyledP>General Inquiries: <StyledA href="mailto:hello@redefine-fit.com">hello@redefine-fit.com</StyledA></StyledP>
                    <StyledP>Looking to rent our space? </StyledP> 
                    <StyledP>Or book a party/private event?: <StyledA href="mailto:info@redefine-fit.com">info@redefine-fit.com</StyledA></StyledP>
                </div>
                <br/>
                <div style={{display: 'flex', flexDirection: width <= 400 ? 'column' : 'row' }}>
                    <div style={{marginRight: '1em'}}>
                        <StyledMiniHeader>Danforth</StyledMiniHeader>
                        <StyledP>403 Danforth Ave</StyledP>
                        <StyledP>Toronto, ON, M4K 1P1</StyledP>
                        <StyledP><StyledA href="https://maps.app.goo.gl/PDSqTSwBB8T3SvjHA" target="_blank">Directions</StyledA></StyledP>
                    </div>
                    <div>
                        <StyledMiniHeader>Bloor West</StyledMiniHeader>
                        <StyledP>1270 Bloor St W</StyledP>
                        <StyledP>Toronto, ON, M6H 1N8</StyledP>
                        <StyledP><StyledA href="https://maps.app.goo.gl/qhXvRBGbcNEvyGpY6" target="_blank">Directions</StyledA></StyledP>
                    </div>
                </div>
          </StyledColumn>
        </StyledContainer>
      </StyledDiv>
      <StyledDiv contactUs={true} logoleftposition={logoLeftPos && logoLeftPos} >
        <StyledContainer width={width}>
        <StyledColumn>
            <StyledH3Contract>How to find us</StyledH3Contract>
          </StyledColumn>
          <StyledColumn>
            <StyledMiniHeader>Taking the TTC—</StyledMiniHeader>
                <ul>
                    <StyledLi><StudioSpan>Danforth studio</StudioSpan>: Exit at Chester Station. We are a one minute walk across the subway station.</StyledLi>
                    <StyledLi><StudioSpan>Bloor West studio</StudioSpan>: Exit at Lansdowne Station. There are 2 exits: If you choose Emerson, we are on the same side of the subway and are under a one minute walk away. If you exit on Lansdowne, turn on Bloor St W and we are a 2 minute walk away.</StyledLi>
                </ul>
                <StyledMiniHeader>Driving—</StyledMiniHeader>
                <ul>
                    <StyledLi><StudioSpan>Danforth studio</StudioSpan>: There are several Green P lots located close to us. You can find free parking at Chester Station and in the neighbourhood.</StyledLi>
                    <StyledLi><StudioSpan>Bloor West studio</StudioSpan>: There are several Green P lots located close to us. The closest is at Margueretta (Location ID 082).</StyledLi>
                </ul>
                <br/>
          </StyledColumn>
        </StyledContainer>
      </StyledDiv>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ChooseStudio background={'teal'} />

      <StyledDiv bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default ContactUs;