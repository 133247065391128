import { useEffect, useState, useContext } from 'react';
import { ChooseStudio } from '../components/choose-studio';
import styled from 'styled-components';
import HeroBanner from '../components/hero';
import { Navbar } from '../components/nav';
import PromoBanner from '../components/promo-banner';
import Footer from '../components/footer';
import RedefineDanforth from '../assets/rdf-dan-studio.webp';
import StyledDiv from '../components/styled-div';
import { Card } from '../components/card';
import { LogoContext } from '../context/LogoContext.js';


const CardContainer = styled.div`
  display: flex; 
  justify-content: center;
  flex-direction: row;
  padding: 3.5em 0;
  max-width: ${props => props.price ? '105%' : props.icon ? '1920px' : ''};
  margin:  0 ${props => props.logoleftposition ? `${props.logoleftposition}px` : 'auto'};
  ${props => props.icon && 'position: relative; top: -1em;'}

  @media (max-width: 1010px) {
    flex-direction: ${props => props.icon || props.giftCards ? 'row' : 'column'}; 
    padding: 2.5em 0;
  }

  @media (max-width: 1253px) {
    padding: 3.5em 0em;
  }

  ${props => props.icon && `@media (max-width: 767px) {
   flex-direction: column;
  }`}

  ${props => props.giftCards && `@media (max-width: 767px) {
    flex-direction: column;
   }`}
`

const ContainerDiv = styled.div`
    max-width: 1120px;
    margin: 0 auto;
    margin: 0 ${props => props.logoleftposition ? `${props.logoleftposition}px` : 'auto'};
`

const StyledP = styled.p`
    text-align: left;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
`

const StyledA = styled.a`
    color: #156863;
    font-family: Inter;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
`;



function GiftCards() {
  const { logoLeftPosition } = useContext(LogoContext);
  const [logoLeftPos, setLogoLeftPos] = useState();

  useEffect(() => {
    document.title = 'Redefine Fit - Gift Cards';
  }, []);

  useEffect(() => {
    if (logoLeftPosition) {
      //  
      setLogoLeftPos(logoLeftPosition)
    }
  }, [logoLeftPosition])

  return (
    <div className="App">
      <PromoBanner />
      <Navbar />
      <HeroBanner titleText='Gift Cards' coverImages={[RedefineDanforth]} height={'520px'} />

      <StyledDiv giftCards={true} bgColor={'#fff'}>
        <ContainerDiv logoleftposition={logoLeftPos && logoLeftPos}>
            <StyledP>Give the gift of empowerment, strength, and self-expression with Redefine Fit digital gift cards!</StyledP>
            <br/>
            <StyledP>Whether your loved ones are seasoned pole enthusiasts or looking to embark on a new fitness journey, our digital gift cards open the door to a world of movement and joy. Take a look at our options below.</StyledP>
            <br/>
            <StyledP>For any inquiries, please email us at <StyledA href="mailto:info@redefine-fit.com">info@redefine-fit.com</StyledA>. Gift the experience of Redefine Fit today!</StyledP>
            <br/>
        </ContainerDiv>
        <CardContainer logoleftposition={logoLeftPos && logoLeftPos} giftCards={true}>
          <Card giftCards={true} buttonText="BUY NOW" link="https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=7&k_id=145064" headerText={'“A Gift For You” Gift Card'} subline={"Any amount"} paragraphText={"Working with a budget? Choose how much you want to surprise the pole dancer in your life!"} />
          <Card giftCards={true} buttonText="BUY NOW" link="https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=7&k_id=156309" headerText={'“Drop-in Class” Gift Card'} subline={"$29+HST"} paragraphText={"Make someone’s day with a single drop-in class! Perfect for the seasoned or beginner pole dancer in your life."} />
          <Card giftCards={true} buttonText="BUY NOW" link="https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=7&k_id=163342" headerText={'“Introductory Special” Gift Card'} subline={"$85+HST"} paragraphText={"Give the gift of surprise with our Introductory Special offer for the person who wants to try pole dance."} />
        </CardContainer>
      </StyledDiv>

      <ChooseStudio homePage={true} background={'teal'} />

      <StyledDiv bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default GiftCards;