import { useRef, useEffect, useState } from 'react';
import { ChooseStudio } from '../components/choose-studio';
import HeroBanner from '../components/hero';
import { Navbar } from '../components/nav';
import { CallToActionButton } from '../utils/cta-button';
import PromoBanner from '../components/promo-banner';
import styled from 'styled-components';
import { Card } from '../components/card';
// import Cooldown from '../assets/bw-cooldown.jpg';
import Cooldown from '../assets/rdf-bw-bride-group-dance-super-compressed.webp';
import Dance from '../assets/danforth-dance.png';
// import Inverts from '../assets/danforth-inverts.png';
import Inverts from '../assets/rdf-bw-group-backhookspin-transformed.webp';
import { HorizontalCard } from '../components/horizontal-card';
// import DanforthGroup from '../assets/danforth-group.jpg';
import DanforthGroup from '../assets/rdf-bw-bride-squad-compressed.webp';
import { Carousel } from '../components/carousel';
import Image1 from '../assets/carousel-image-1.jpg';
import Image2 from '../assets/carousel-image-2.png';
import Image3 from '../assets/carousel-image-3.png';
import Image4 from '../assets/carousel-image-4.jpg';
import Footer from '../components/footer';
// import HomeHero from './../assets/home-hero.png';
// import HomeHero from './../assets/home-banner.jpg';
// import HomeHero from './../assets/rdf-bw-group-outsideleghang-compressed.webp';
import WavyBorderTop from './../assets/border-desktop-wavy-top.svg';
import WavyBorderBottom from './../assets/border-desktop-wavy-bottom.svg';
import { useWindowSize } from '../hooks/use-window-size.js';
import CarouselOne from './../assets/carousel-two-transformed-desktop.webp';
import CarouselTwo from './../assets/carousel-three-transformed-desktop.webp';
import CarouselThree from './../assets/rdf-bw-group-outsideleghang-transformed-desktop.webp';
import CarouselFive from './../assets/home-hero-transformed-desktop.png';
import useFontFaceObserver from 'use-font-face-observer';

const StyledDiv = styled.div`
  width: 100%;
  background-color: ${props => props.bgColor};
  font-family: 'Inter-Medium', Helvetica, sans-serif;
  text-align: center;
  padding: 3em;
  ${props => props.privateParties && 'padding-bottom: 0;'}
  position: relative;

  @media (max-width: 680px) {
    padding: 2em;
  }

  ${props => props.couponDiv && `@media (max-width: 764px) {
    padding: 2em;
  }`}

  ${props => props.privateParties && `@media (max-width: 1020px) {
    padding: 0 3em;
  }`}

  ${props => props.privateParties && `@media (max-width: 612px) {
    padding: 0 1.8em;
  }`}

  ${props => props.privateParties && `@media (max-width: 575px) {
    padding: 0 28px;
  }`}

  ${props => props.privateParties && `@media (max-width: 448px) {
    padding: 0 22px;
  }`}

  ${props => props.privateParties && `@media (max-width: 412px) {
    padding: 0 16px;
  }`}
`;

// const StyledDiv = styled.div`
//   width: 100%;
//   background-color: ${props => props.bgColor};
//   font-family: 'Inter-Medium', Helvetica, sans-serif;
//   text-align: center;
//   padding: 3em;
//   border-top: ${props => props.wavy ? '8px solid transparent' : 'none'};
//   border-bottom: ${props => props.wavy ? '8px solid transparent' : 'none'};
//   border-image: ${props => props.wavy ? 'url("path_to_wavy_border_image.png") 30 stretch' : 'none'};

//   @media (max-width: 680px) {
//     padding: 2em;
//   }
// `;

const StyledH2 = styled.h2`
  font-size: 48px;
  font-family: Inter;
  max-width: 1056px;
  margin: 0 auto;
  font-weight: 600;
  color: rgba(12, 18, 14, 1);

  @media (max-width: 515px) {
    font-size: 36px;
    margin-bottom: 24px;
  }
`;

const StyledP = styled.p`
  font-weight: 500;
  max-width: 27em;
  margin: 0 auto;
  font-size: 2.25em; /* 36px/16px */
  color: rgba(107, 114, 128, 1);
  margin-top: .5em;
  font-size: 30px;
  font-family: Inter;
`;

const Coupon = styled.div`
  background-color: #fff;
  display: flex;
  max-width: 768px;
  padding: 2em 3em 3em 3em;
  border-radius: 8px; 
  flex-direction: column;
  margin: 0 auto;
  border: 2.5px dashed rgba(21, 103, 99, 1);
`

const CouponHeading = styled.h2`
  font-weight: 600;
  font-size: 3em;
  font-family: "Inter-SemiBold", Helvetica;
  color: rgba(21, 103, 99, 1);
`;

const CouponText = styled.p`
  font-size: 1.25em; 
  font-weight: 400;
  text-align: center;
  max-width: 36em; 
  color: rgba(107, 114, 128, 1);
  font-family: "Inter-Bold", Helvetica;
`

const ButtonDiv = styled.div`
  margin: 0 auto;
`

const CardContainer = styled.div`
  display: flex; 
  justify-content: center;
  flex-direction: row;
  padding: 3.5em;


  @media (max-width: 1105px) {
    padding: 3.5em 0 0 0;
  }

  @media (max-width: 1010px) {
    flex-direction: column;
    padding: 2.5em 0;
    width: 96%;
  }

  @media (max-width: 766px) {
    width: auto;
  }
`

const SocialTag = styled.a`
  color: ${props => props.color ? props.color : 'rgba(107, 114, 128, 1)'};
  font-family: "Inter-Medium", Helvetica;
  font-size: 30px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
`

const Wavy = styled.img`
  display: ${props => {
    return props.heroHeight ? 'block;' : 'none;'
  }}
  left: 0px;
  position: absolute;
  ${props => {
    return props.top ? `bottom: ${props.heroHeight}px;` : `top: ${props.heroHeight}px;`
  }}
  width: calc(100% + 4em);
  z-index: 999;
`

const ChooseDiv = styled.div`
  padding: 5em 0;

  @media (max-width: 1060px) {
    padding: 4em 0;
  }

  @media (max-width: 630px) {
    padding: 3em 0;
  }

  @media (max-width: 485px) {
    padding: 2em 0;
  }
`

const ClassesCard = styled.div`
  padding-bottom: 5em;

  @media(max-width: 764px) {
    padding-bottom: 2em;
  }
`

const FeedDiv = styled.div`
  padding: 5em 0;

  @media(max-width: 764px) {
    padding: 3em 0;
  }
`

function Home() {
  const heroRef = useRef(null);
  const couponRef = useRef(null);
  const feedRef = useRef(null);
  const [width, height] = useWindowSize(); 
  const [heroHeight, setHeroHeight] = useState();
  const [couponHeight, setCouponHeight] = useState();
  const [feedHeight, setFeedHeight] = useState();

  const isFontLoaded = useFontFaceObserver([
    {
      family: 'Inter'
    }
  ])

  useEffect(() => {
    window.history.replaceState(null, "Redefine Fit - Home", "/")
    document.title = 'Redefine Fit - Home';
  }, []);

  useEffect(() => {
    if (heroRef.current) {
      setHeroHeight(heroRef.current.clientHeight)
    }

    if (couponRef.current) {
      setCouponHeight(couponRef.current.clientHeight)
    }

    if (feedRef.current) {
      setFeedHeight(feedRef.current.clientHeight)
    }
  }, [width, height, isFontLoaded])

  return (
    <div className="App">
      <PromoBanner />
      <Navbar />
      <HeroBanner homePage={true} link='/schedule' titleText='Welcome to Redefine Fit!' bodyText=' ' callToAction={true} coverImages={[CarouselOne, CarouselTwo, CarouselThree,  CarouselFive]} />
      <StyledDiv ref={heroRef} bgColor={'#f5fff7'}>
        <Wavy heroHeight={heroHeight} top={true} src={WavyBorderTop} />
        <StyledH2>Redefine Fit is a pole dance and fitness studio in Toronto.</StyledH2>
        <StyledP>
          We are a pole dance and fitness community that prioritizes inclusivity, safety, and a supportive community for all students, regardless of their fitness background, age, or gender identity.
        </StyledP>
        <Wavy heroHeight={heroHeight} src={WavyBorderBottom} />
      </StyledDiv>
      <ChooseDiv>
        <ChooseStudio homePage={true} />
      </ChooseDiv>
      <ClassesCard>
        <StyledDiv couponDiv={true} ref={couponRef} bgColor={'#f5fff7'}>
          <Wavy heroHeight={couponHeight} top={true} src={WavyBorderTop} />
          <Coupon>
            <CouponHeading>Get 5 classes for $85</CouponHeading>
            <CouponText>For first-time students, get the in-studio Introductory Special Offer</CouponText>
            <ButtonDiv>
              <CallToActionButton link="https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=1&k_id=1869384" noLeft={true} buttonText={"CLAIM OFFER"} />
            </ButtonDiv>
          </Coupon>

          <CardContainer>
            <Card link="/our-classes" homeFirst={true} homeScreen={true} image={Dance} headerText={"Class Descriptions"} paragraphText={"We offer a range of classes weekly including static pole, spin pole, core fit, flexibility, floor work, and more!"} />
            <Card link="/passes" homeSecond={true} homeScreen={true} image={Inverts} headerText={"Passes & Memberships"} paragraphText={"We offer drop-in classes, class passes, and monthly memberships! Find the option that fits your schedule."} />
            <Card link="/workshop-and-events" homeThird={true} homeScreen={true} image={Cooldown} headerText={"Events & Workshops"} paragraphText={"Take a look at our upcoming events and workshops and learn from competitor-level guest pole dancers from around the world!"} />
          </CardContainer>
          <Wavy heroHeight={couponHeight} src={WavyBorderBottom} />
        </StyledDiv>
      </ClassesCard>
      <StyledDiv privateParties={true} bgColor={'#fff'}>
        <HorizontalCard homePage={true} link="/private-parties" headerText={'Looking for a fun event? Book a private party with us! 🎉'} bodyColor={'#6B7280'} paragraphText={`We enjoy celebrating and throwing parties of all kinds! We'll work with you to plan memorable bachelorette, birthday, bridal, or friends' night out!`} imagePath={DanforthGroup} />
      </StyledDiv>
      <FeedDiv>
        <StyledDiv ref={feedRef} bgColor={'#f5fff7'}>
          {feedHeight && <Wavy heroHeight={feedHeight} top={true} src={WavyBorderTop} />}
            <StyledH2>Here's what we're up to 💪🏼</StyledH2>
            <SocialTag target="_blank" href="https://www.instagram.com/redefine_fitLtd" color={'#156763'}>@redefine_fitLtd</SocialTag>
            <Carousel images={[Image1, Image2, Image3, Image4]} />
          {feedHeight && <Wavy heroHeight={feedHeight} src={WavyBorderBottom} />}
        </StyledDiv>
      </FeedDiv>
      <StyledDiv bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default Home;