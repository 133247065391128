import React from 'react';
import styled from 'styled-components';
import DanforthStudio from './../assets/rdf-dan-studio.webp';
import BloorWestStudio from './../assets/redefine-bwest.jpeg';
import { CallToActionButton } from '../utils/cta-button';
import { keyframes } from 'styled-components';

const waveAnimation = keyframes`
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) } 
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) } 
  100% { transform: rotate( 0.0deg) }
`;

const scale = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        transform: scale(1);
    }

    100% {
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
        transform: scale(1.05);
    }
`

const Background = styled.div`
    background-color: ${props => props.background === 'white' ? '#fff' : props.background === 'teal' ? '#f5fff7' : 'transparent'};
    width: 100%
`

const Container = styled.div`
  max-width: 85em;
  margin: 0 auto;
  padding: 3em;

  @media (max-width: 872px) {
    margin: 0 auto;
    padding: 3em 2em;
  }

  @media (max-width: 767px) {
    margin: 0 auto;
    padding: 3em;
  }

  @media (max-width: 612px) {
    margin: 0 auto;
    padding: 3em 2em;
  }

  @media (max-width: 580px) {
    margin: 0 auto;
    padding: 3em 1em;
  }

  @media (max-width: 548px) {
    margin: 0 auto;
    padding: 3em .8em;
  }

  @media (max-width: 492px) {
    margin: 0 auto;
    padding: 3em -.9em;
  }
`;

const HeadingDiv = styled.div`
    margin: 0 auto;
    max-width: 44em;
    text-align: center;
`

const Heading = styled.h2`
  font-weight: 700;
  font-size: 36px;
  font-family: Inter;
`;

const StudioLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2em;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const StudioLink = styled.div`
  margin: 1em;
  width: auto;
  height: 318px;
  background: ${({image}) => `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${image}) center no-repeat`};
  justify-content: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  &:hover {
    animation: ${scale} .5s ease 0s 1 normal forwards;
  }

  @media (min-width: 768px) {
    width: 618px;
  }
`;

const StudioName = styled.h4`
  color: #fff;
  font-weight: 700;
  margin-bottom: 1em;
  font-size: 2.2em;

  @media (max-width: 420px) {
    margin-bottom: .5em;
  }
`;

const WavingHand = styled.span`
  display: inline-block;
  animation: ${waveAnimation} 2s infinite;
`;

export const ChooseStudio = ({ background, homePage }) => (
  <Background background={background}>
    <Container>
      <HeadingDiv>
          <Heading>New or returning student? <WavingHand>👋🏼</WavingHand></Heading>
          <Heading>Choose your studio:</Heading>
      </HeadingDiv>
      <StudioLinks>
        <StudioLink homePage={homePage} image={DanforthStudio}>
          <StudioName>Danforth</StudioName>
          <CallToActionButton link="/schedule" chooseStudio={true} noLeft={true} margin={'0 auto'} buttonText={"BOOK CLASS"} bgColor={'#fffeeb'} textColor={'rgba(21, 103, 99, 1)'} />
        </StudioLink>
        <StudioLink homePage={homePage} image={BloorWestStudio}>
          <StudioName>Bloor West</StudioName>
          <CallToActionButton link="/schedule" chooseStudio={true} noLeft={true} margin={'0 auto'} buttonText={"BOOK CLASS"} bgColor={'#fffeeb'} textColor={'rgba(21, 103, 99, 1)'} />
        </StudioLink>
      </StudioLinks>
    </Container>
  </Background>
);