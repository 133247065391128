import YouTube from "react-youtube";

const MovieClip = ({videoId, height, width}) => {
    const options = {
        height: height,
        width: width,
        playerVars: {
          autoplay: 0,
          controls: 1,
        }
    };

    return (
        <YouTube videoId={videoId} opts={options}  id="video"/>
    )
};
    

  
export default MovieClip;