import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CallToActionButton } from '../utils/cta-button';
import { scale } from '../utils/keyframes';
import ShowHide from './show-hide';
import { useWindowSize } from '../hooks/use-window-size.js';
import MovieClip from './movie-clip.js';

const CardContainer = styled.div`
    display: flex;
    ${props => !props.logoleftposition && 'justify-content: center;'}
    align-items: center;
    ${props => (!props.logoleftposition && !props.workshopEvents) && 'padding: 1em 1em 4em 1em;'}
    ${props => props.ourClasses && 'padding: 1em 0 4em 0;'}
    ${props => props.workshopEvents && 'padding: 64px 0;'}

    @media (max-width: 785px) {
      flex-direction: column;
      padding: ${props => props.video ? '.1em' :  (props.workshopEvents) ? '.5em' : '2em'} ${props => props.passesBottom ? '0.6em' : props.ourClasses ? '0' : props.workshopEvents ? '' : '0.8em'};
      ${props => props.passesBottom && props.firstPassCard && 'padding: 0 0.6em 2em 0.6em;'}
    }
`;

const TextSide = styled.div`
    width: ${props => props.width ? props.width : '549'}px; 

    text-align: left;
    margin-left: ${props => props.marginLeft ? props.marginLeft: '0'}em; 
    margin-right: ${props => props.marginLeft ? '0': '5'}em;

    @media (max-width: 785px) {
      flex-direction: column;
      width: 100%;
      margin: ${props => props.ourClasses ? '1em 32px 0em 32px' : props.passesBottom ?  '2em 4em 0em 4em' : !(props.workshopEvents && props.eventBody && props.actualWidth <= 375)  ? '1em 4em 0em 5em' : '0em'};
      ${props => (props.workshopEvents && props.eventBody && props.actualWidth <= 375) && 'padding: 21px 0;'}
      ${props => (props.workshopEvents && props.eventBody && props.actualWidth <= 375) && 'margin-left: 0em;'}
    }
`;

const StyledH3 = styled.h3`
    font-size: ${props => props.eventsBottom || props.passesBottom ? '30px' : '36px'};
    font-family: ${props => props.fontFamily ? props.fontFamily: 'Inter'};
    font-weight: ${props => props.fontWeight? props.fontWeight: "700"};
    color: #0C120E;
`

const StyledP = styled.p`
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;  
    padding: 12px ${props =>  props.video ? '3px' : props.ourClasses ? '0' : props.passesBottom ? '1px' : '24px'} 0 0; 
    ${props => props.ourClasses && 'padding: 12px 0 0 0;'}
    color: ${props => props.color ? props.color : ''};
    ${props => props.eventsBottom && 'padding: 12px 0 0;'}
    font-style: ${props => props.italic ? 'italic' : ''};
`

const StyledSpan = styled.span`
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;  
    line-height: 30px;
    padding: 12px 24px 0 0; 
    color: ${props => props.color ? props.color : '#6B7280'};
    font-style: ${props => props.italic ? 'italic' : ''};
`

const ImageSide = styled.img`
    width: ${props => props.width ? props.width : '592'}px; 
    position: relative;
    height: ${props => props.height ? props.height : '320'}px; 
    border-radius: 16px;
    object-fit: cover;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    &:hover {
      animation: ${scale} .5s ease 0s 1 normal forwards;
    }
    ${props => props.ourClasses && 'margin-bottom: 16px;'}

    @media (max-width: 785px) {
      width: 700px;
    }

    @media (max-width: 450px) {
      height: 380px;
    }

    @media (max-width: 375px) {
      ${props => !props.ourClasses && !props.passesBottom && 'width: 296px;'}
      height: 320px;
    }
`;

const StyledLi = styled.li`
    text-align: left;
    font-family: "Inter-Regular", Helvetica;
    font-size: 20px;
    font-weight: ${props => props.pricing ? '600' : '400'};
    line-height: 30px;
    color: rgba(107, 114, 128, 1);
`;

const BoldSpan = styled.span`
    font-family: "Inter-Regular", Helvetica;
    color: #6B7280;
    font-size: 20px;
    font-weight: 400;
    margin-left: .2em;
`

const ImageDiv = styled.div`
    ${props => props.ourClasses && 'margin-bottom: 4px;'}
    height: ${props => props.height ? `${props.height}px` : 'auto'}; 
`

const formatText = (specialFormatting, bodyColor, passesBottom, eventsBottom, ourClasses) => {
  let markupKeys = Object.keys(specialFormatting) 
  return markupKeys.map((markupKey, i) => {
        switch(true) {
          case  markupKey.includes('pbs'):
            return (
              <StyledP ourClasses={ourClasses} passesBottom={passesBottom} key={`${markupKey}-${i}`} color={bodyColor}>{specialFormatting[markupKey].p1}<StyledSpan>{specialFormatting[markupKey].bs}</StyledSpan></StyledP>
          );
          case markupKey.includes('p'):
            return <StyledP ourClasses={ourClasses} eventsBottom={eventsBottom} passesBottom={passesBottom} key={`${markupKey}-${i}`} color={bodyColor}>{specialFormatting[markupKey]}</StyledP>
          case  markupKey.includes('b'):
            return (
              <StyledP ourClasses={ourClasses} eventsBottom={eventsBottom} passesBottom={passesBottom} key={`${markupKey}-${i}`} italic={markupKey.includes('i')} color={bodyColor}><span style={{fontWeight: "700"}}>{specialFormatting[markupKey][0]}</span> {specialFormatting[markupKey][1]}</StyledP>
            );
          case markupKey.includes('ul'):
            return ( 
            <>
              <br/>
              {specialFormatting[markupKey].map((listItem, j) => {
                return (
                  <ul key={`${markupKey}-ul-${j}`}>
                    {listItem.includes('(') ?
                    <><StyledLi pricing={listItem.includes('$')} key={`${markupKey}-li-${j}`}>{listItem.split(' (')[0]}<BoldSpan>{`(${listItem.split(' (')[1]}`}</BoldSpan></StyledLi></>
                    :
                    <StyledLi pricing={listItem.includes('$')} key={`${markupKey}-li-${j}`}>{listItem}</StyledLi>
                    }
                  </ul>
                )
              })}
            </>  
          );
          default:
            return null;
        }
      })
}

export const HorizontalCard = ({ disabled, firstPassCard, passes, eventsBottom, workshopEvents, eventBody, ourClasses, video, passesBottom, homePage, linkArray, link, logoleftposition, listOffers, textHeight, textWidth, headingFontFamily, headingFontWeight, imagePath, imageWidth, imageHeight, headerText, paragraphText, buttonText, imageLeft, bodyColor, specialFormatting, specialFormattingTwo, longerText, buttonWidth, noButton, videoId }) => {
  const textRef = useRef(null);
  const [textHeightState, setTextHeightState] = useState('auto');
  const [width, height] = useWindowSize(); 

  const handleReadMoreClick = () => {
    if (width > 785) {
      if (textRef.current?.offsetHeight) {
        setTextHeightState(`${textRef.current.offsetHeight}`);
      }
    }
  }

  useEffect(() => {
    if (width <= 785 && !textHeightState.includes('auto')) {
      setTextHeightState(`auto`);
    }
  }, [width])

  return (<CardContainer firstPassCard={firstPassCard} ourClasses={ourClasses} video={video} passesBottom={passesBottom} workshopEvents={workshopEvents} logoleftposition={logoleftposition}>
    {imageLeft ? 
    <>
      <ImageDiv ourClasses={ourClasses} height={textHeightState}>{imagePath ? <ImageSide passesBottom={passesBottom} ourClasses={ourClasses} height={imageHeight} width={imageWidth} src={imagePath} /> : videoId ? <MovieClip height={window.innerWidth > 1246 ? '390' : '195'} width={window.innerWidth > 1246 ? '640' : '320'} videoId={videoId}/> : ''}</ImageDiv>
      <TextSide workshopEvents={workshopEvents} eventBody={eventBody} actualWidth={width} ourClasses={ourClasses} passesBottom={passesBottom} ref={textRef} marginLeft={3} width={textWidth} height={textHeight}>
        <StyledH3 eventsBottom={eventsBottom} passesBottom={passesBottom} fontFamily={headingFontFamily} fontWeight={headingFontWeight}>{headerText}</StyledH3>
        {specialFormatting ?
        <>
        {formatText(specialFormatting, bodyColor, passesBottom, eventsBottom, ourClasses)}
        {specialFormattingTwo && <>
        {!noButton && <CallToActionButton disabled={disabled} passes={passes} ourClasses={ourClasses} fullLength={width <= 375} passesBottom={passesBottom} homePage={homePage} link={linkArray[0]} noTopMargin={workshopEvents ? true : false} buttonWidth={buttonWidth} buttonText={buttonText ? buttonText : "LEARN MORE"} bgColor={'#fffeeb'} textColor={'rgba(21, 103, 99, 1)'} />}
        <br/>
       {width > 785 ? <br/> : <div style={{marginTop: '14px'}}></div>}
        </>
        }
        </>
        : <StyledP ourClasses={ourClasses} video={video} eventsBottom={eventsBottom} passesBottom={passesBottom} color={bodyColor}>{paragraphText}</StyledP>}
        {specialFormattingTwo ?
        <>
        {formatText(specialFormattingTwo, bodyColor, passesBottom, eventsBottom, ourClasses)
        }
        {!noButton && <CallToActionButton disabled={disabled} passes={passes} ourClasses={ourClasses} fullLength={width <= 375} passesBottom={passesBottom} homePage={homePage} link={linkArray[1]} noTopMargin={workshopEvents ? true : false} buttonWidth={buttonWidth} buttonText={buttonText ? buttonText : "LEARN MORE"} bgColor={'#fffeeb'} textColor={'rgba(21, 103, 99, 1)'} />}
        </>
        : ''}
        {longerText && <><br/><br/><ShowHide handleReadMoreClick={handleReadMoreClick} markup={longerText} /><br/></>}
        {specialFormattingTwo || listOffers ? '' : !noButton && <CallToActionButton disabled={disabled} passes={passes} ourClasses={ourClasses} fullLength={width <= 405} passesBottom={passesBottom} homePage={homePage} link={link} noTopMargin={workshopEvents ? true : false} buttonWidth={buttonWidth} buttonText={buttonText ? buttonText : "LEARN MORE"} bgColor={'#fffeeb'} textColor={'rgba(21, 103, 99, 1)'} />}
        {listOffers && 
        <>
          {listOffers.map((offer, i) => {
            return (
              <div style={{display: 'flex', marginTop: width <= 785 ? '24px' : '', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div style={{margin: '.5em 0', display: 'flex', flexDirection: 'column', fontSize: '20px', lineHeight: '30px', color: '#6B7280', width: '258px'}}>
                  <p style={{fontWeight: 700}}>{offer.title}</p>
                  <p>${offer.price}</p>
                </div>
                <br/>
                {!noButton && <CallToActionButton disabled={disabled} passes={passes} ourClasses={ourClasses} fullLength={width <= 405} passesBottom={passesBottom} listOffers={true} homePage={homePage} link={linkArray[i]} noTopMargin={workshopEvents ? true : false} buttonWidth={buttonWidth} buttonText={buttonText ? buttonText : "LEARN MORE"} bgColor={'#fffeeb'} textColor={'rgba(21, 103, 99, 1)'} />}
                <br/>
                <br/>
              </div>
            )
          })}
        </>
        }
      </TextSide>
    </>
    :
    <>
      <TextSide workshopEvents={workshopEvents} eventBody={eventBody} actualWidth={width} ourClasses={ourClasses} passesBottom={passesBottom} ref={textRef} width={textWidth} height={textHeight}>
        <StyledH3 eventsBottom={eventsBottom} passesBottom={passesBottom} fontFamily={headingFontFamily} fontWeight={headingFontWeight}>{headerText}</StyledH3>
        {specialFormatting ? 
        formatText(specialFormatting, bodyColor, passesBottom, eventsBottom, ourClasses)
        : <StyledP ourClasses={ourClasses} eventsBottom={eventsBottom} video={video} passesBottom={passesBottom} color={bodyColor}>{paragraphText}</StyledP>}
        {longerText && <><br/><br/><ShowHide handleReadMoreClick={handleReadMoreClick} markup={longerText} /><br/></>}
        {!noButton && <CallToActionButton ourClasses={ourClasses} passes={passes} fullLength={width <= 405} passesBottom={passesBottom} homePage={homePage} link={link} noTopMargin={workshopEvents ? true : false} buttonWidth={buttonWidth} buttonText={buttonText ? buttonText : "LEARN MORE"} bgColor={'#fffeeb'} textColor={'rgba(21, 103, 99, 1)'} />}
        <br/>
        <br/>
      </TextSide>
      <ImageDiv ourClasses={ourClasses} height={textHeightState}>{imagePath ? <ImageSide passesBottom={passesBottom} ourClasses={ourClasses} height={imageHeight} width={imageWidth} src={imagePath} /> : videoId ? <MovieClip height={window.innerWidth > 1246 ? '390' : '260'} width={window.innerWidth > 1246 ? '640' : window.innerWidth > 380 ? '430' : '300'} videoId={videoId}/> : ''}</ImageDiv>
    </>
    }
  </CardContainer>)
};