import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import HeroBanner from '../components/hero.js';
import { Navbar } from '../components/nav.js';
import PromoBanner from '../components/promo-banner.js';
import Footer from '../components/footer.js';
import RedefineDanforth from '../assets/rdf-dan-studio.webp';
import StyledDiv from '../components/styled-div.js';
import { HorizontalCard } from '../components/horizontal-card.js';
import Workshop1 from '../assets/workshop-1.png';
import Workshop2 from '../assets/workshop-2.png';
import Workshop3 from '../assets/workshop-3.png';
import { CallToActionButton } from '../utils/cta-button.js';
import { ChooseStudio } from '../components/choose-studio.js';
import { useWindowSize } from '../hooks/use-window-size.js';
import { LogoContext } from '../context/LogoContext.js';
import { useContext } from 'react';
import Loader from '../components/loader.js';

const ContainerDiv = styled.div`
    max-width: 1170px;
    ${props => !props.logoleftposition && 'margin: 0 auto;'}
    margin-top: -3em;
    margin-bottom: 3em;
`

function WorkshopAndEvents() {
  const [width, height] = useWindowSize(); 

  const { logoLeftPosition } = useContext(LogoContext);
  const [logoLeftPos, setLogoLeftPos] = useState();
  const [entries, setEntries] = useState([])
  const [entriesToShow, setEntriesToShow] = useState([])
  const [pagination, setPagination] = useState(3)

  const containerRef = useRef(null);

  useEffect(() => {
    document.title = 'Redefine Fit - Events & Workshops';
  }, []);

  useEffect(() => {
    if (logoLeftPosition) {
      //  
      setLogoLeftPos(logoLeftPosition)
    }
  }, [logoLeftPosition])

  useEffect(() => {
    window.scrollTo(0, 0)

    fetch('https://redefine-fit-shop.com/wp-json/wp/v2/posts?categories=4')
    .then(data => data.json())
    .then(json => {
           
        setEntries(json)
        setEntriesToShow(json.slice(0,3))
    })
    .catch(err => {
        console.log('err', err)
    })
    
  }, [])

  const formatWorkshopDetails = (entry, i) => {
    let htmlString = entry.content.rendered;

    const eventTitle = entry.title.rendered
    const bylineMatch = htmlString.match(/<h1>(.*?)<\/h1>/);
    const excerpt = htmlString.match(/<div>(.*?)<\/div>/)[0].replace(/<div>/g, "").replace(/<\/div>/g, "") 
    const byline = bylineMatch ? bylineMatch[1] : '';

    // Remove the byline from the original string
    htmlString = htmlString.replace(/<h1>.*?<\/h1>/, '');

    // Replace <div></div> with <p class="regular"></p>
    htmlString = htmlString.replace(/<div>(.*?)<\/div>/g, (match, content) => {
        if (content.trim()) {
            return `<p class="regular">${content}</p>`;
        }
        return '';
    });

    // Extract image path and remove the <img> tag
    let imagePath = '';
    htmlString = htmlString.replace(/<img[^>]*srcset="([^"]*)"[^>]*>/, (match, srcset) => {
        // Parse the srcset to find the largest image
        const sources = srcset.split(',').map(src => {
            const [url, width] = src.trim().split(' ');
            const size = parseInt(width.slice(0, -1)); // Remove the 'w' and convert to integer
            return { url, size };
        });
        // Sort by size in descending order and pick the largest one
        sources.sort((a, b) => b.size - a.size);
        imagePath = sources[0].url;
        return ''; // Remove the img tag from the string
    });
    
    // Convert <a href> to <a class="redefine-link">
    htmlString = htmlString.replace(/<a href="([^"]*)">/g, '<a target="_blank" class="redefine-link" href="$1">');
    // Convert <strong><em> to <p class="bold-italic">
    htmlString = htmlString.replace(/<strong><em>(.*?)<\/em><\/strong>/g, '<p class="bold-italic">$1</p>');

    // Convert p tags with '-' at the beginning into <ul class="redefine-ul">
    htmlString = htmlString.replace(/<p>(-.*?)<\/p>/gs, (match, content) => {
        const items = content.split('<br />');
        const labels = [
            '🗓 <span class="bold">Dates:</span> ',
            '⌚️ <span class="bold">Time:</span> ',
            '📍 <span class="bold">Location:</span> ',
            '💵 <span class="bold">Cost:</span> '
        ];
        const listItems = items.map((item, index) => `<li>${labels[index]}${item.trim().substring(1)}</li>`).join('');
        return `<ul class="redefine-ul">${listItems}</ul>`;
    });

    // Clean up newlines and escaped quotes
    htmlString = htmlString.replace(/\\n/g, '').replace(/\\"/g, '"');

    const registerLink = htmlString?.split('button::')[1]?.includes('href') ? htmlString?.split('button::')[1]?.split('href="')[1]?.split('"')[0] : htmlString?.split('button::')[1]?.split('</p>')[0]

    htmlString = htmlString?.split('button::')[0]

    return (
      <>
        <HorizontalCard noButton={!registerLink} workshopEvents={true} eventsBottom={true} eventBody={true} logoleftposition={logoLeftPos && logoLeftPos} headerText={eventTitle} specialFormatting={{'bi': [byline, ''], 'br': '', 'p1': excerpt.replace(/(&#(\d+);)/g, (match, capture, charCode) => String.fromCharCode(charCode))}} link={registerLink} imagePath={imagePath} buttonText='BOOK WORKSHOP' imageWidth='528' 
        imageHeight='484' textWidth='542' headingFontFamily='Inter' longerText={htmlString.replace(/\n/g,'')} headingFontWeight='600' bodyColor='#0C120E' buttonWidth='194px' imageLeft={width <= 785 ? true : false} />
        {((entries.length > 1) && (i < entries.length - 1)) &&
        <>
        <br />
        <br />
        <br />
        </>
        }
      </>
    )
  }

  useEffect(() => {
    let less = entriesToShow.length > entries.slice(0, pagination).length
    setEntriesToShow(entries.slice(0, pagination));

    if (containerRef.current && less) {
      setTimeout(() => { 
        window.scrollBy(document.documentElement.scrollTop, -(document.documentElement.scrollTop - containerRef.current.clientHeight));
      }, 5)
    }

}, [pagination])

  return (
    <div className="App">
      <PromoBanner />
      <Navbar />
      <HeroBanner plusTwenty={true} titleText='Events &amp; Workshops' coverImages={[RedefineDanforth]} height={'515px'} />
      <div style={{margin: '0 .4em'}}>
        <StyledDiv trueWidth={width} firstParagraph={true} workshopEvents={true} contactUs={true} events={true} logoleftposition={logoLeftPos && logoLeftPos} specialFormatting={{'p1': `Join an exciting workshop with competitor-level guest pole dancers from around the world! In the past, we've hosted lyrical pole flow, exotic pole flow, pole flips and tumbles, pole tricks, and more.`, 'br': '', 'p2': `Check back often, or`, 'a1': ['follow us on Instagram', 'https://www.instagram.com/redefine_fitLtd'], 'p3': 'to stay updated.'}} bgColor={'#fff'} textAlign={'left'} 
        width={'31em'} />
      </div>
      {width > 375 && <br/>}
      <StyledDiv workshopEvents={true} contactUs={true} logoleftposition={logoLeftPos && logoLeftPos} bgColor={'#fff'}>
        <ContainerDiv ref={containerRef} logoleftposition={logoLeftPos && logoLeftPos}>
          {!entriesToShow.length ?
          <Loader />
          :
          entriesToShow.map((entry, i) => formatWorkshopDetails(entry, i))
          }
        </ContainerDiv>
        <br/>
        {(entries?.length > 3) ? (entriesToShow?.length !== entries.length) ? <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1em', marginTop: '-3em'}}>
        <CallToActionButton seeMore={true} setPagination={setPagination} pagination={pagination} buttonText={"SEE MORE"} bottom={'17px'} bgColor={'#fffeeb'} textColor={'rgba(21, 103, 99, 1)'} />   
        </div>
        :
        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1em', marginTop: width < 400}}>
          <CallToActionButton seeLess={true} setPagination={setPagination} pagination={pagination} buttonText={"SEE LESS"} bottom={'17px'} bgColor={'#fffeeb'} textColor={'rgba(21, 103, 99, 1)'} />   
        </div>
        :
        <></>
        }
      </StyledDiv>
      {width > 375 ? <br/> : <div></div>}
      <ChooseStudio background={'teal'} />

      <StyledDiv bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default WorkshopAndEvents;