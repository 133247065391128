import React from 'react';
import styled from 'styled-components';

const Banner = styled.div`
  width: 100%;
  background-color: #87C996;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  cursor: pointer;
`;

const BannerText = styled.p`
  color: #FFFEEB;
  text-align: center;
`;

const BoldedSpan = styled.span`
    font-weight: bold;
    cursor: pointer;
`;

const PromoBanner = () => {
  const handleClick = () => {
    window.open('https://www.wellnessliving.com/rs/catalog-view.html?k_business=248216&id_sale=1&k_id=1869384', '_blank', 'noreferrer');
  }

  return (
    <Banner onClick={handleClick}>
      <BannerText>First time? <BoldedSpan>Get 5 classes for $85 </BoldedSpan>✨🎁</BannerText>
    </Banner>
  );
};

export default PromoBanner;