import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

// Create a bobbing animation using keyframes
const bobbingAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

// Styled component for the chevron

const Chevron = styled.div`
  position: fixed;
  bottom: 70px;
  right: 40px;
  cursor: pointer;
  width: 50px; /* Circle size */
  height: 50px; /* Circle size */
  border-radius: 50%; /* Makes the div a circle */
  background-color: #87C996; /* Circle color */
  display: flex;
  justify-content: center;
  box-shadow: 4px 3px 15px -9px rgba(0,0,0,0.52);
  align-items: center;
  animation: ${bobbingAnimation} 5s ease-in-out infinite;
  display: ${({ show }) => (show ? 'block' : 'none')};
  &:before {
    content: url('data:image/svg+xml;utf8,<svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v13m0-13 4 4m-4-4-4 4"/></svg>');
    position: relative;
    margin: 0 auto;
    top: 24%;
  }
  z-index: 999999999;
`;

// React component
const ScrollToTopChevron = () => {
  const [showChevron, setShowChevron] = useState(false);

  const handleScroll = () => {
    const quarterWayDown = document.documentElement.scrollHeight / 4;
    if (window.pageYOffset > quarterWayDown) {
      setShowChevron(true);
    } else {
      setShowChevron(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return <Chevron onClick={scrollToTop} show={showChevron} />;
};

export default ScrollToTopChevron;