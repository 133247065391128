import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledContainer = styled.div`
  background-color: ${props => props.bgColor};
  font-family: Inter;
  text-align: center;
  padding: ${props => props.passesMiddle ? '58px' : props.ourClasses ? '48px' : props.passesMemberships ? '32px' : props.passesBottom ? '22px' : '64px'} ${props => props.spaceRental ? '0' : props.workshopEvents ? '.5em' : '0'} ${props => props.ourClasses ? '32px' : props.spaceRental ? '0' : props.workshopEvents ? '0' : (props.whoWeAre && !props.topArea) ? '32px' : props.faq ? '0px' : props.spaceRentalCard ? '0' : '64px'} ${props => props.spaceRental ? '0' : props.workshopEvents ? '.5em' : '0'};
  ${props => (props.workshopEvents && props.firstParagraph && (props.trueWidth <= 375)) && 'padding: 48px 32px; margin: 0 !important;'}
  ${props => (props.privateParties && props.footerDiv && (props.trueWidth <= 375)) && 'padding: 64px 0 64px 0;'}
  ${props => props.voucher && props.trueWidth <= 767 && 'padding: 48px 32px 64px 32px;'}
  position: relative;
  margin: 0 ${props => props.noBottomMargin ? '0;' : props.privateParties && !props.headerCopy ? `2px`: (props.logoleftposition && props.contactUs && window.innerWidth > 767 && !props.whoWeAre) ? `${props.logoleftposition}px` : props.workshopEvents ? '32px' : 'auto'};
  ${props => props.passesMemberships && props.pricing && props.innerWidth > 767 && 'padding: 32px 32px 0 32px;'}
  ${props => props.row && 'display: flex; flex-direction: row;'}
  ${props => props.overflowX && 'overflow-x: auto;'}
  ${props => props.spaceRental && 'padding: 0;'}
  ${props => props.spaceRentalCard && 'margin: 48px auto;'}
  ${props => props.spaceRentalCard && 'padding: 38px 0 0px 0;'}
  ${props => props.spaceRentalCard && 'max-width: 1363px;'}
  ${props => props.whoWeStacked && 'padding: 6px 0 32px 0;'}

  @media (max-width: 767px) {
    ${props => (props.whoWeAre) && `padding: 2em;`}
    ${props => props.whoWeAre && 'margin: 0 .5em'};
    ${props => (props.workshopEvents && props.firstParagraph && (props.innerWidth > 767)) && `margin: .5em ${props => props.ourClasses ? '32px' : '0'};`}
    ${props => props.privateParties && !props.headerCopy && 'margin: 0 25px;'}
    ${props => props.spaceRentalCard && 'margin: 48px 32px;'}
    ${props => props.ourClasses && 'margin: 0 auto;'}
    ${props => props.spaceRental && 'margin: 0; padding: 0 25px;'}
  }

 ${props => props.faq && `@media (max-width: 939px) {
  padding: 44px 0 0px 0;
 }`}

  @media (max-width: 766px) {
    ${props => (props.bottomPrivateParties && props.pricingDiv ? '48px 32px;' : !props.privateParties && !props.spaceRental && !props.headerCopy && !props.faq && !props.trueClasses && !props.classImages && !props.voucher && !props.workshopEvents && !props.blog) && 'padding: 0 32px;'}
    ${props => props.instructors && 'padding: 48px 32px;'}
    ${props => props.spaceRental && 'padding: 0 2em;'}
    ${props => props.contactUsContainer && 'padding: 64px 32px;'}
    ${props => props.privateParties && 'margin: 0 .5em'};
    ${props => (!props.workshopEvents && !props.passes) && `margin: .5em ${props.ourClasses ? '32px' : '0'}`};
    ${props => props.voucher && 'margin: 0;'}
    ${props => props.whoWeAre && 'padding: 48px 32px; margin: 0 .5em;'}
    ${props => props.trueClasses && 'top: -1.8em;'}
    ${props => (props.studioPolicies && !props.whoWeStacked) && 'padding: 48px 32px;'}
    ${props => props.whoWeStacked && 'padding: 0;'}
    ${props => props.classImages && 'top: -2em;'}
    ${props => props.spaceRentalCard && 'margin: 48px 32px;'}
    ${props => (props.whoWeAre && props.titleCopy?.includes('Instructors')) && 'padding: 48px 32px 20px 32px;'}
    ${props => props.blog && 'margin: 0 32px;'}
  }

  ${props => props.contactUsContainer && `@media (max-width: 757px) {
    padding: 48px 32px;
  }`}

  ${props => props.whoWeStacked && `@media (max-width: 621px) {
    padding: 0 32px;
  }`}

  ${props => props.contactUsContainer && 'padding: 64px 32px;'}

  @media (max-width: 680px) {
    ${props => (props.bottomPrivateParties && props.pricingDiv ? '48px 32px;' : !props.workshopEvents && !props.passes && !props.faq && !props.spaceRental && props.headerCopy && props.pricingDiv) ? 'padding: 2em;' : props.pricingDiv && !props.headerCopy ? 'padding: 0 32px;' : ''}
    ${props => props.giftCards && 'padding: 48px 2em 2em 2em;'}
    ${props => (!props.passes && !props.faq && !props.spaceRental && !props.schedule && !props.pricing) && 'margin: 0 .5em'};
    // ${props => (!props.pricing && !props.passesMemberships) && `margin: .5em ${props.pricing ? '32px' : '0'}`};
    ${props => props.workshopEvents && !props.pricing && !props.passes && 'margin: .5em 16px;'}
    ${props => props.spaceRental && 'margin: 0;'}
    ${props => (props.privateParties && props.footerDiv) && 'padding: 48px 0 48px 0;'}
    ${props => props.pricingDiv && 'padding: 48px 32px;'}
    ${props => props.classImages && 'margin: 0 32px;'}
    ${props => props.trueClasses && 'margin: 52px 32px; padding: 0;'}
    ${props => props.voucher && 'margin: 0;'}
    ${props => props.spaceRentalCard && 'margin: 48px 32px;'}
    ${props => props.spaceRentalCard && 'margin: 48px 32px 0px 32px;'}
    ${props => props.blog && 'margin: 0 32px;'}
  }

  ${props => props.passes && `@media (max-width: 785px) {
    padding: 48px 0px;
    padding-bottom: 40px;
  }`}

  ${props => props.passes && `@media (max-width: 754px) {
    padding-top: 48px;
    padding-right: 32px;
    padding-left: 32px;
    padding-bottom: 48px;
  }`}

  ${props => props.overflowX && `@media (max-width: 480px) {
    margin: .5em 0em;
    padding: 0.5em;
  }`}

  ${props => props.instructors && `@media (max-width: 480px) {
    padding: 28px 0;
  }`}

  ${props => props.trueClasses && `@media (max-width: 375px) {
    top: 0;
  }`}
`;

const StyledH2 = styled.h2`
  font-size: 36px;
  font-family: Inter;
  max-width: 1056px;
  margin: 0 ${props => (props.logoleftposition && window.innerWidth > 767) ? `${props.logoleftposition}px;` : 'auto;'}
  font-weight: 700;
  color: rgba(12, 18, 14, 1);
  ${props => props.logoleftposition && 'display: flex;'}
  ${props => props.whoWeAre && 'text-align: left;'}
`;

const StyledP = styled.p`
  font-weight: 500;
  ${props => props.width ? `max-width: ${props.width}` : ''};
  margin: 0 ${props => (props.logoleftposition && window.innerWidth > 767) ? `${props.logoleftposition}px` : props.whoweare ? '.8em' : 'auto'};
  font-size: 30px;
  line-height: 45px;
  font-family: Inter; 
  color: rgba(107, 114, 128, 1);
  ${props => props.noBottomMargin ? '' : 'margin-top: 42px'}; 
  text-align: ${props => props.textAlign ? props.textAlign : 'center'};
  ${props => props.noBottomMargin ? '' : 'margin-bottom: 42px'}; 
`;

const StyledLink = styled(Link)`
  color: #156763;
  font-family: Inter;
  font-weight: 500;
  text-decoration: underline;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
`

const FormattedP = styled.p`
  color: rgba(12, 18, 14, 1);
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  ${props => props.noWidth ? '' : 'max-width: 1120px;'}  
  ${props => (props.logoleftposition && (props.actualWidth > 767) && !props.voucher) ? `margin: 0 ${props.logoleftposition}px` : !props.events && 'margin: 0 auto'};
  text-align: left;
`

const formatText = (voucher, specialFormatting, noWidth = false, logoleftposition = false, events = false, width = null) => {
  let markupKeys = Object.keys(specialFormatting) 
  return (<FormattedP voucher={voucher} events={events} logoleftposition={logoleftposition} noWidth={noWidth} actualWidth={width}>
    {
      markupKeys.map(markupKey => {
        switch(true) {
          case markupKey.includes('p'):
            return ` ${specialFormatting[markupKey]} `
          case markupKey.includes('br'): 
            return <><br /><br/></>
          case  markupKey.includes('a'):
            return (
              <StyledLink target="_blank" to={specialFormatting[markupKey][1]}>{specialFormatting[markupKey][0]}</StyledLink>
            );
          default:
            return null;
        }
      })
    }
  </FormattedP>)
}

const StyledDiv = ({blog, instructors, whoWeStacked, passesMiddle, spaceRental, classImages, trueClasses, contactUsContainer, studioPolicies, pricingDiv, footerDiv, bottomPrivateParties, headerCopy, trueWidth, ourClasses, spaceRentalCard, pricing, passesMemberships, passesBottom, giftCards, schedule, faq, topArea, privateParties, overflowX, row, titleCopy, whoWeAre, passes, firstParagraph, voucher, workshopEvents, events, contactUs, noWidth, noBottomMargin, logoleftposition, titleText, bodyText, bgColor, textAlign, actualWidth, children, specialFormatting}) => (
    <StyledContainer noBottomMargin={noBottomMargin} blog={blog} schedule={schedule} instructors={instructors} titleCopy={titleCopy} whoWeStacked={whoWeStacked} spaceRental={spaceRental} classImages={classImages} trueClasses={trueClasses} contactUsContainer={contactUsContainer} studioPolicies={studioPolicies} pricingDiv={pricingDiv} footerDiv={footerDiv} bottomPrivateParties={bottomPrivateParties} headerCopy={headerCopy} voucher={voucher} trueWidth={trueWidth} ourClasses={ourClasses} spaceRentalCard={spaceRentalCard} pricing={pricing} passesMemberships={passesMemberships} passesMiddle={passesMiddle} passesBottom={passesBottom} giftCards={giftCards} topArea={topArea} whoWeAre={whoWeAre} faq={faq} privateParties={privateParties} overflowX={overflowX} row={row}  passes={passes} firstParagraph={`${firstParagraph}`} workshopEvents={workshopEvents} contactUs={contactUs} logoleftposition={logoleftposition} bgColor={bgColor}>
        {titleText ? titleCopy ? <StyledH2 whoWeAre={whoWeAre} logoleftposition={logoleftposition}>{actualWidth > 790 ? titleCopy : 'Our Instructors'}</StyledH2> : <StyledH2>We are a pole dance and fitness studio in Toronto.</StyledH2> : ''}
        {specialFormatting ? 
            formatText(voucher, specialFormatting, noWidth, logoleftposition, events, actualWidth)
        :
          !passesBottom && !contactUs && !schedule && !giftCards && !spaceRental &&!ourClasses && !privateParties && !passesMiddle && <StyledP whoWeAre={whoWeAre} events={events} noBottomMargin={noBottomMargin} logoleftposition={logoleftposition} textAlign={textAlign} >
            {bodyText}
        </StyledP>}
        {children}
    </StyledContainer>
)

export default StyledDiv;