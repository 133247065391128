import React from 'react';
import styled from 'styled-components';
import { ReactComponent as InstagramIcon } from './../assets/InstagramIcon.svg'
import { ReactComponent as FacebookIcon } from './../assets/FacebookIcon.svg'
import { useWindowSize } from '../hooks/use-window-size.js';
import ScrollToTopArrow from '../utils/scroll-to-top';

const FooterContainer = styled.div`
  // max-width: 1330px;
  max-width: 1250px;
  margin: 0 auto;
  padding: 1em;

  @media (max-width: 680px) {
    margin-left: 1.5em;
  }
`;

const FooterColumns = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3em;
  flex-direction: ${props => props.width <= 679 ? 'column' : 'row'};
  align-items: ${props => props.width <= 679 ? 'center' : 'flex-start'};
`;

const Column = styled.div`
  flex: 1;
  text-align: ${props => props.width <= 679 ? 'center' : 'left'};
  font-family: "Inter-Regular", Helvetica;
  line-height: 30px;

  @media (max-width: 992px) {
    margin-right: 3em;
  }

  @media (max-width: 725px) {
    margin-right: 2em;
  }
`;

const ColumnIcons = styled.div`
  flex: 1;
  text-align: left;
  font-family: "Inter-Regular", Helvetica;
  line-height: 30px;
  margin-right: ${props => props.width <= 679 ? '2em' : '0'}; 
`;

const Heading = styled.h3`
  font-family: "Inter-Bold", Helvetica;
  font-weight: 700;
  font-size: ${props => props.width ? props.width <= 784 ? '28px': props.width <= 1000 ? '28px' : props.width <= 1127 ? '32px' : props.width <= 1178 ? '24px' : '24px' : '24px'};
  text-align: ${props => props.width <= 679 ? 'center' : 'left'};
  color: rgba(12, 18, 14, 1);
  line-height: 125%;
  margin-bottom: .4em;
`;

const Link = styled.a`
  display: ${props => props.inline ? 'inline' : 'block'};
  color: #156763 !important;
  font-size: 20px;
  line-height: 30px;
  font-family: Inter;
  font-weight: 500;
  margin: 15px 0;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 770px) {
    margin-right: 0em;
  }  
`;

const IconLink = styled.a`
    margin: 0 10px;

    @media (max-width: 992px) {
      width: 48px;
    }     
`

const ListingDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => props.width <= 679 ? 'column' : 'row'};

  @media (max-width: 680px) {
    position: relative;
    right: 1em;
  }
`
const FooterListing = styled.p`
    font-family: Inter;
    margin: 8px 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: ${props => props.color ? props.color : 'rgba(12, 18, 14, 1)'}; 
    text-align: ${props => props.width <= 679 ? 'center' : 'left'};
`

const Footer = () => {
  const [width, height] = useWindowSize(); 

  return (
    <FooterContainer>
      <FooterColumns width={width}>
        {width <= 679 && <ColumnIcons width={width}>
          <IconContainer>
            <IconLink href="https://www.instagram.com/redefine_fitLtd"><InstagramIcon /></IconLink>
            <IconLink href="https://www.facebook.com/RedefineFitLtd"><FacebookIcon /></IconLink>
            {/* <IconLink href="https://www.twitter.com/redefine_fitLtd"><TwitterIcon /></IconLink> */}
          </IconContainer>
        </ColumnIcons>} 
        {width <= 679 && <br/>}
        {width <= 679 && <br/>}
        <Column width={width}>
          <Heading width={width}>LEARN</Heading>
          <Link href="/studio-policies">Studio Policies</Link>
          <Link href="/faq">FAQ</Link>
          <Link href="/schedule">Schedule</Link>
          <Link href="/passes">Passes & Memberships</Link>
          <Link href="/workshop-and-events">Events & Workshops</Link>
          <Link href="/private-parties">Private Parties</Link>
          <Link href="/space-rental">Space Rental</Link>
          <Link href="/blog">Blog</Link>
        </Column>
        {width <= 679 && <br/>}
        <Column width={width}>
          <Heading width={width}>EAST STUDIO</Heading>
          <FooterListing width={width}>403 Danforth Ave</FooterListing>
          <FooterListing width={width}>Toronto ON, M4K1P1</FooterListing>
          <Link target="_blank" href="https://maps.app.goo.gl/PDSqTSwBB8T3SvjHA">Directions</Link>
          <br/>
          <br/>
          {width <= 679 ? 
          <>
          <Heading width={width}>WEST STUDIO</Heading>
          <FooterListing width={width}>1270 Bloor St W</FooterListing>
          <FooterListing width={width}>Toronto, ON, M6H 1N8</FooterListing>
          <Link target="_blank" href="https://maps.app.goo.gl/qhXvRBGbcNEvyGpY6">Directions</Link>   
          </>       
          :
          <>
          <Heading width={width}>SUPPORT</Heading>
          <Link href="/contact-us">Contact Us</Link>
          <Link target="_blank" href="mailto:info@redefine-fit.com">info@redefine-fit.com</Link>
          <Link target="_blank" href="tel:16475399439" width={width}>(647) 539-9439</Link>
          </>
          }
        </Column>
        <Column width={width}>
          {width > 679 ? 
          <>
          <Heading width={width}>WEST STUDIO</Heading>
          <FooterListing width={width}>1270 Bloor St W</FooterListing>
          <FooterListing width={width}>Toronto, ON, M6H 1N8</FooterListing>
          <Link target="_blank" href="https://maps.app.goo.gl/qhXvRBGbcNEvyGpY6">Directions</Link>
          <br/>
          <br/>
          </>
          :
          <>
          {width <= 678 && <><br/><br/></>}
          <Heading width={width}>HOURS</Heading>
          <FooterListing width={width}>M to F | 5PM – 9:30PM</FooterListing>
          <FooterListing width={width}>Sat | 10AM – 12PM</FooterListing>
          <FooterListing width={width}>Sun | 9AM – 2:30PM</FooterListing>
          </>
          }
          {/* {(width >= 840 || (width < 785 && width > 759)) && <br/>} */}
          {
            width <= 679 ? 
            <>
            {width <= 678 && <><br/><br/></>}
            <Heading width={width}>SUPPORT</Heading>
            <Link href="/contact-us">Contact Us</Link>
            <Link target="_blank" href="mailto:info@redefine-fit.com">info@redefine-fit.com</Link>
            <Link target="_blank" href="tel:16475399439" width={width}>(647) 539-9439</Link>
            </>
            :
            <>
            <Heading width={width}>HOURS</Heading>
            <FooterListing width={width}>M to F | 5PM – 9:30PM</FooterListing>
            <FooterListing width={width}>Sat | 10AM – 12PM</FooterListing>
            <FooterListing width={width}>Sun | 9AM – 2:30PM</FooterListing>
            </>
          }
        </Column>
        {width > 679 && <ColumnIcons>
          <IconContainer>
            <IconLink href="https://www.instagram.com/redefine_fitLtd"><InstagramIcon /></IconLink>
            <IconLink href="https://www.facebook.com/RedefineFitLtd"><FacebookIcon /></IconLink>
            {/* <IconLink href="https://www.twitter.com/redefine_fitLtd"><TwitterIcon /></IconLink> */}
          </IconContainer>
        </ColumnIcons>}  
      </FooterColumns>

      <ListingDiv width={width}>
        <FooterListing width={width} color={'rgba(107, 114, 128, 1)'}>Copyright @ 2024 Redefine Fit Ltd.</FooterListing>
        {width <= 679 && <br/>}
        <FooterListing width={width} color={'rgba(107, 114, 128, 1)'}>❤️ Site designed with love by <Link inline={true} target="_blank" href="https://xine.design">Christine</Link></FooterListing>
      </ListingDiv>
      <ScrollToTopArrow />
    </FooterContainer>
  )
};

export default Footer;