import React, { useState } from 'react';
import styled from 'styled-components';
// import MobileLogo from './../assets/mobile-logo.png';
import MobileLogo from './../assets/redefinefit-logo-black.svg';
import HamburgerIcon from './../assets/hamburger.png';
import { Link } from "react-router-dom";
import { ReactComponent as ChevronDown } from './../assets/chevron-down.svg';
import { ReactComponent as ChevronUp } from './../assets/chevron-up.svg';

const Bar = styled.div`
  width: 100%;
  height: 116px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const Menu = styled.div`
  width: 100%;
  height: ${props => props.isOpen ? '100vh' : '0'};
  background-color: white;
  transition: height 0.5s ease;
  position: absolute;
  top: 116px;
  left: 0;
  overflow: hidden;
  padding-bottom: 1em;
`;

const MenuItem = styled.div`
  padding: 0 1em;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  font-family: Inter;
  &:hover {
    color: #156763;
  }
  color: ${props => props.isOpen ? '#156763;' : '#0C120E'}; 
`;

const MenuTextContainer = styled.div`
  background-color: ${props => props.isOpen ? '#F5FFF7' : '#fff'}; 
  padding: 12px 15px;
  &:hover {
    color: #156763;
  }
`

const SubMenu = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  background-color: #fff;

  a {
    color: #6B7280;
    display: block; 
    padding: 10px 20px; 
    text-decoration: none; 
    font-family: Inter;
    font-weight: 400;
    margin-left: 48px;
    &:hover {
      color: #156763;
    }
  }
`;

const Chevron = styled.span`
  float: right;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
`

export const MobileNav = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState({});

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // const toggleSubMenu = (menu) => {
  //   setSubMenuOpen(prevState => ({ ...prevState, [menu]: !prevState[menu] }));
  // };

  const toggleSubMenu = (menu) => {
    setSubMenuOpen(prevState => {
      // Close all submenus
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
      // Open the clicked submenu
      newState[menu] = !prevState[menu];
      return newState;
    });
  };

  return (
    <>
      <Bar>
        <Link to="/"><img src={MobileLogo} alt="Logo" style={{width: '97px', height: '84px'}} /></Link>
        <img src={HamburgerIcon} alt="Menu" onClick={toggleMenu} style={{ cursor: 'pointer' }} />
        {/* <svg style={{ cursor: 'pointer' }} class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="25" height="20" fill="none" viewBox="0 0 24 24"> 
          <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h14"/>
        </svg> */}
      </Bar>
      <br/>
      <Menu isOpen={menuOpen}>
        <MenuItem isOpen={subMenuOpen.about} onClick={() => toggleSubMenu('about')}><MenuTextContainer isOpen={subMenuOpen.about}>ABOUT<Chevron>{subMenuOpen.about ? <ChevronUp /> : <ChevronDown />}</Chevron></MenuTextContainer>
          <SubMenu isOpen={subMenuOpen.about}>
            <Link to="/who-we-are">Who We Are</Link>
            <Link to="/studio-policies">Studio Policies</Link>
            <Link to="/faq">FAQ</Link>
            <Link to="/voucher-redemption">Voucher Redemption</Link>
            <Link to="/contact-us">Contact Us</Link>
          </SubMenu>
        </MenuItem>
        <MenuItem isOpen={subMenuOpen.classes} onClick={() => toggleSubMenu('classes')}><MenuTextContainer isOpen={subMenuOpen.classes}>CLASSES & SCHEDULE<Chevron>{subMenuOpen.classes ? <ChevronUp /> : <ChevronDown />}</Chevron></MenuTextContainer>
          <SubMenu isOpen={subMenuOpen.classes}>
            <Link to="/our-classes">Our Classes</Link>
            <Link to="/schedule">Schedule</Link>
            <Link to="/workshop-and-events">Events &amp; Workshops</Link>
          </SubMenu>
        </MenuItem>
        <MenuItem isOpen={subMenuOpen.pricing} onClick={() => toggleSubMenu('pricing')}><MenuTextContainer isOpen={subMenuOpen.pricing}>PRICING<Chevron>{subMenuOpen.pricing ? <ChevronUp /> : <ChevronDown />}</Chevron></MenuTextContainer>
          <SubMenu isOpen={subMenuOpen.pricing}>
            <Link to="/passes">Passes & Memberships</Link>
            <Link to="/private-parties">Private Parties</Link>
            <Link to="/gift-cards">Gift Cards</Link>
            <Link to="/space-rental">Space Rental</Link>
          </SubMenu>
        </MenuItem>
        <MenuItem><StyledLink to="/blog"><MenuTextContainer>BLOG</MenuTextContainer></StyledLink></MenuItem>
        <MenuItem><StyledLink target="_blank" to="https://redefine-fit-shop.com/shop"><MenuTextContainer>SHOP</MenuTextContainer></StyledLink></MenuItem>
        <MenuItem><StyledLink target="_blank" to="https://www.wellnessliving.com/login/redefine_fit"><MenuTextContainer>MY ACCOUNT</MenuTextContainer></StyledLink></MenuItem>
      </Menu>
    </>
  );
};