import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronDown } from './../assets/chevron-down.svg';
import { ReactComponent as ChevronUp } from './../assets/chevron-up.svg';

const StyledDiv = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-right: 1em;
`;

const StyledP = styled.p`
    color: rgba(21, 103, 99, 1);
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;
    position: relative;
    ${props => props.arrow && !props.show && 'bottom: 0.4em;'}
    ${props => props.top && 'top: 2px;'}
`

const ShowHide = ({ markup, handleReadMoreClick }) => {
  const [show, setShow] = useState(false);

  const toggleShow = async () => {
    await setShow(!show);

    handleReadMoreClick();
  };

  window.screen.orientation.addEventListener("change", function(e) {
    setShow(false);
  });

  return (
    <>
      <StyledDiv onClick={toggleShow}>
        <StyledP>{show ? 'Read less' : 'Read more'}</StyledP>
        <StyledP top={true} arrow={true} show={show}>{show ? <ChevronUp /> : <ChevronDown />}</StyledP>
      </StyledDiv>
      <br/>
      {show && <div dangerouslySetInnerHTML={{ __html: markup }} />}
    </>
  );
};

export default ShowHide;