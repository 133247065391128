import { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import HeroBanner from '../components/hero';
import { Navbar } from '../components/nav';
import PromoBanner from '../components/promo-banner';
import Footer from '../components/footer';
import RedefineDanforth from '../assets/rdf-dan-studio.webp';
import StyledDiv from '../components/styled-div';
import { ReactComponent as AppStore } from '../assets/redesign_apple.svg';
import PlayStore from '../assets/redesign_google.png';
import WidgetEmbed from '../components/widget-embed';
import { useWindowSize } from '../hooks/use-window-size.js';
import { LogoContext } from '../context/LogoContext.js';

const StyledH3 = styled.h3`
  font-size: 30px;
  font-weight: 600;
  text-align: left;
  line-height: 45px;
  color: #0C120E;
  font-family: Inter;
`;

const ContainerDiv = styled.div`
    margin: 0 ${props => (props.logoleftposition && props.actualWidth > 767) ? `${props.logoleftposition}px` : 'auto'};
    margin-top: -3em;
    margin-bottom: 3em;
`

const StyledP = styled.p`
    text-align: left;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
`

const StyledA = styled.a`
    color: #156863;
    font-family: Inter;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
`;

const StyledSpan = styled.span`
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
`

function Schedule() {
  const [width, height] = useWindowSize(); 
  const { logoLeftPosition } = useContext(LogoContext);
  const [logoLeftPos, setLogoLeftPos] = useState();

  useEffect(() => {
    document.title = 'Redefine Fit - Schedule';
  }, []);

  useEffect(() => {
    if (logoLeftPosition) {
      //  
      setLogoLeftPos(logoLeftPosition)
    }
  }, [logoLeftPosition])

  return (
    <div>
      <PromoBanner />
      <Navbar />
      <HeroBanner titleText='Schedule' coverImages={[RedefineDanforth]} height={'520px'} />

      <StyledDiv schedule={true} bgColor={'#fff'}>
        <ContainerDiv actualWidth={width} logoleftposition={logoLeftPos && logoLeftPos}>
            <br/>
            <br/>
            <StyledP>We have tons of fun and exciting classes offered daily! Register online for classes to secure your spot. Registration for classes opens 2 weeks in advance.</StyledP>
            <br/>
            <StyledP><StyledSpan>What does "Waitlist" mean?</StyledSpan> If a class shows up as “Waitlist”, then the class is currently full and you can add yourself to the waitlist. To learn more about the waitlist policy, visit this link.</StyledP>
            <br/>
            <StyledP><StyledSpan>What does "Disabled" mean?</StyledSpan> If a class shows up as “Disabled”, this means you will need to be at a certain level to access the class. If you have experience with pole dance and fitness, you need to take a placement test to assess your level. Please email us at <StyledA href="mailto:info@redefine-fit.com">info@redefine-fit.com</StyledA></StyledP>
            <br/>
            <StyledP>With 2 studios for you to pick from make sure you book at the location you want! “BloorW” is our Bloor West Studio and “Dan” is our Danforth Studio.</StyledP>
            <br/>
            <br/>
            <StyledH3>Download the Wellness Living App</StyledH3>
            <br/>
            <StyledP>To book classes quickly, download the Wellness Living App on your mobile phone. The app is available on both the Apple Store and Google Play Store!</StyledP>
            <br/>
            <br/>
            <div style={{display: 'flex', justifyContent: 'center', flexDirection: width <= 679 ? 'column' : 'row', alignItems: width <= 679 ? 'center' : ''}}>
                <a target="_blank" href="https://apps.apple.com/ca/app/wellnessliving-achieve/id1135932782" style={{cursor: 'pointer', textStyle: 'none', marginBottom: width <= 679 ? '20px' : ''}}><AppStore /></a>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.wellnessliving.achieve&hl=en_CA&pli=1" style={{cursor: 'pointer', textStyle: 'none'}}><img style={{'width': '210px', 'height': '91px', position: 'relative', bottom: '14px'}} src={PlayStore} /></a>
            </div>
            <br/>
            <br/>
            {/* {embed goes here} */}

            <WidgetEmbed />

        </ContainerDiv>
      </StyledDiv>

      <StyledDiv bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default Schedule;