import { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import HeroBanner from '../components/hero';
import { Navbar } from '../components/nav';
import PromoBanner from '../components/promo-banner';
import Footer from '../components/footer';
import RedefineDanforth from '../assets/rdf-dan-studio.webp';
import StyledDiv from '../components/styled-div';
import TopLeft from '../assets/redefine-danforth.png';
import TopRight from '../assets/redefine-bwest.jpeg';
import { scaleNoShadow } from '../utils/keyframes.js';
import { useWindowSize } from '../hooks/use-window-size.js';
import PricingCard from '../components/pricing-card.js';
import RedefineTank from '../assets/redefine-tank.png';
import { Card } from '../components/card.js';
import First from '../assets/rdf-bw-brass-monkies-&-beyond-compressed.webp';
// import Second from '../assets/danforth-group.jpg';
import Second from '../assets/review-natalie-m.svg';
import Third from '../assets/active-flex.png';
// import Fourth from '../assets/holidays.png';
import Fourth from '../assets/review-stl.svg';
import Fifth from '../assets/rdf-bw-tyson-tonic-compressed.webp';
import Sixth from '../assets/review-annette-l.svg';
import Seventh from '../assets/danforth-dance.png';
import Eighth from '../assets/group-2.png';
import Ninth from '../assets/danforth-inverts.png';
import Star from '../assets/star-vector.png';
import Mail from '../assets/mail-vector.png';
import Check from '../assets/check-vector.png';
import Heart from '../assets/heart-vector.png';
import { LogoContext } from '../context/LogoContext.js';


const StyledH3 = styled.h3`
  font-size: 36px;
  font-weight: 600;
  text-align: left;
  color: rgba(12, 18, 14, 1);
  font-family: "Inter-Bold", Helvetica;
`;

const ContainerDiv = styled.div`
    max-width: 85em;
    margin: 0 ${props => props.logoleftposition ? `${props.logoleftposition}px` : 'auto'};
    margin-top: -4em;
    padding-top: ${props => !props.pastEvents ? '45px' : props.width < 376 ? '0' : '45px'};
    padding-bottom: ${props => props.width <= 375 ? '15px' : '45px'};
    ${props => props.ourSpaceImages && 'padding-bottom: 133px;'}
    ${props => (props.ourSpaceImages && props.width <= 680) && 'padding-top: 0;'}
    ${props => props.width <= 375 && 'margin-bottom: 48px;'}
    ${props => props.ourSpaceImages && 'margin-top: -6em;'}

    @media (max-width: 680px) {
      margin: 0;
      ${props => props.ourSpace && 'padding-bottom: 0;'}
      ${props => (props.ourSpaceImages) && 'padding-top: 0;'}
      ${props => (props.ourSpaceImages) && 'padding-bottom: 48px;'}
    }
`

const ThreeByThree = styled.div`
    max-width: 1307px;
    margin: 0 auto;
    margin-top: -3em;
    margin-bottom: 3em;
    display: flex;
`

const OneThirdImage = styled.img`
  width: ${props => props.width <= 980 ? '100%' : '32%'};
  height: 320px;
  max-width: 546px;
  object-fit: cover;
  border-radius: 8px;
  margin: .4em .8em;
  display: inline;
  &:hover {
    animation: ${scaleNoShadow} .5s ease 0s 1 normal forwards;
  }
  ${props => props.width <= 980 && 'margin: 0 auto 24px auto;'}
`;

const CardContainer = styled.div`
  display: flex; 
  justify-content: center;
  flex-direction: row;
  padding: 3.5em;
  max-width: ${props => props.price ? '105%' : props.icon ? '' : ''};
  ${props => props.icon && 'position: relative; top: -5em;'}
  padding-bottom: 0;

  @media (max-width: 1010px) {
    flex-direction: ${props => props.icon ? 'row' : 'column'}; 
    padding: 2.5em 0;
    ${props => props.spaceRentalCard && 'padding: 2.5em 0 0 0;'}
  }

  ${props => props.icon && `@media (max-width: 767px) {
   flex-direction: column;

   @media (max-width: 767px) {
    padding-top: 48px;
    padding-bottom: 0px;
   }
  }`}
`

const Link = styled.a`
  display: inline;
  color: #156763 !important;
  margin-bottom: 4px;
`;

const QuarterImage = styled.img`
  width: 100%;
  height: 320px;
  max-width: 40em;
  object-fit: cover;
  border-radius: 8px;
  margin: ${props => props.width ? '10px 0' : '.2em .4em'};
  margin: 12px;
  display: inline;
  &:hover {
    animation: ${scaleNoShadow} .5s ease 0s 1 normal forwards;
  }

  @media (max-width: 711px) {
    margin: 0 0 12px 0;
   }
`;

// const OneThirdImage = styled.img`
//   width: 32%;
//   height: 320px;
//   max-width: 546px;
//   object-fit: cover;
//   border-radius: 8px;
//   margin: .2em .4em;
//   display: inline;
//   &:hover {
//     animation: ${scale} .5s ease 0s 1 normal forwards;
//   }
// `;

const OneTwiceImage = styled.img`
  width: 48%;
  height: 320px;
  max-width: 546px;
  object-fit: cover;
  border-radius: 8px;
  margin: .2em .4em;
  display: inline;
  &:hover {
    animation: ${scaleNoShadow} .5s ease 0s 1 normal forwards;
  }
`;

const StyledSubHeading = styled.p`
    text-align: left;
    font-family: Inter;
    color: #6B7280;
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;    
`

const StyledMiniHeading = styled.p`
    text-align: left;
    font-family: "Inter-Regular", Helvetica;
    color: #0C120E;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;    
    ${props => !props.noSpace && 'margin-bottom: 10px'};
`


function SpaceRental() {
  const [width, height] = useWindowSize(); 

  const { logoLeftPosition } = useContext(LogoContext);
  const [logoLeftPos, setLogoLeftPos] = useState();

  useEffect(() => {
    document.title = 'Redefine Fit - Space Rental';
  }, []);

  useEffect(() => {
    if (logoLeftPosition) {
      //  
      setLogoLeftPos(logoLeftPosition)
    }
  }, [logoLeftPosition])

  return (
    <div className="App">
      <PromoBanner />
      <Navbar />
      <HeroBanner titleText='Space Rental' coverImages={[RedefineDanforth]} height={'520px'} />

      <StyledDiv spaceRental={true} privateParties={"true"} bgColor={'#F5FFF7'}>
        <ContainerDiv logoleftposition={logoLeftPos && logoLeftPos}>

            <StyledH3>Need a space to host a gathering?</StyledH3>
            <br/>
            <StyledSubHeading>Our natural, light-filled spaces create the perfect backdrop for your next workshop, event, or creative project.</StyledSubHeading>
        </ContainerDiv>
      </StyledDiv>

      <StyledDiv spaceRentalCard={true} privateParties={"true"} bgColor={'#fff'}>
            <CardContainer spaceRentalCard={true} spaceRental={true} icon={true} width={width}>
              <Card spaceRental={true} threeCards={true} icon={true} image={Mail} headerText={"1. Email us"} htmlText={"<p style='font-size: 20px; font-weight: 400; font-family: Inter; text-align: left; margin-top: 8px; color: rgba(107, 114, 128, 1);'>Email us at <a href='mailto:info@redefine-fit.com' style='color: #156863; font-family: Inter; font-weight: 500; font-size: 20px; line-height: 30px; cursor: pointer;' >info@redefine-fit.com</a> and tell us what you have in mind! We can discuss availabilities and rates.</p>"} />
              <Card spaceRental={true} threeCards={true} icon={true} image={Check} headerText={"2. Initial deposit"} paragraphText={"After confirming occasion, guest, and location details, a $100 deposit will be required to secure your slot."} />
              <Card rentalThirdCard={true} spaceRental={true} threeCards={true} icon={true} image={Heart} headerText={"3. Have fun!"} paragraphText={"Sit back, relax, and look forward to a fun evening with family and friends! We will take care of the rest."} />
            </CardContainer>        
      </StyledDiv>

      <StyledDiv spaceRental={true} privateParties={"true"} bgColor={'#F5FFF7'}>
        <ContainerDiv ourSpace={true} width={width} logoleftposition={logoLeftPos && logoLeftPos}>
            {width >= 375 && <br/>}
            <StyledH3>Our Space</StyledH3>
            <br/>

            <StyledMiniHeading>We can host your gathering at either of our two beautiful studios located in Toronto! Our east location is steps away from Chester Subway station on the Danforth. Our west location is steps away from Lansdown Station on Bloor West.</StyledMiniHeading>

            <>
              <br/>
              <br/>
            </>
        </ContainerDiv>
        <ContainerDiv ourSpaceImages={true} >
            <QuarterImage width={width} src={TopLeft}/>
            <QuarterImage width={width} src={TopRight}/>
        </ContainerDiv>
        {width <= 375 && <div style={{marginBottom: '48px'}}></div>}
      </StyledDiv>

      <StyledDiv spaceRental={true} privateParties={"true"} bgColor={'#fff'}>
        <ContainerDiv width={width} pastEvents={true} logoleftposition={logoLeftPos && logoLeftPos}>
            <br/>
            <StyledH3>Past events</StyledH3>
            <br/>
            <StyledMiniHeading>In the past, we have organized workshops from competitor-level pole dancers from around the world, photoshoots, video productions, rehearsals, parties, private lessons, and personal creative projects!</StyledMiniHeading>
            <br/>
            <StyledMiniHeading noSpace={true}>Email us at <Link href="mailto:info@redefine-fit.com" target='_blank'>info@redefine-fit.com</Link> for availability and rates. We will get back to you in <strong>3–4 business days</strong>. In the meantime, check our Instagram to see the kinds of gatherings our studio can accomodate.</StyledMiniHeading>
        </ContainerDiv>
        {width > 980 &&
        <>
        <br/>
        <br/>
        <br/>
        <br/>
        </>}
        <br/>
        {
        width > 980 ? 
        <>
        <ThreeByThree>
            <OneThirdImage src={First}/>
            <OneThirdImage src={Second}/>
            <OneThirdImage src={Third}/>
        </ThreeByThree>
        <ThreeByThree>
            <OneThirdImage src={Fourth}/>
            <OneThirdImage src={Fifth}/>
            <OneThirdImage src={Sixth}/>
        </ThreeByThree>
        {/* <ThreeByThree>
            <OneThirdImage src={Seventh}/>
            <OneThirdImage src={Eighth}/>
            <OneThirdImage src={Ninth}/>
        </ThreeByThree> */}
        </>   
        :
        <>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <OneThirdImage width={width} src={First}/>
              <OneThirdImage width={width} src={Second}/>
              <OneThirdImage width={width} src={Third}/>
              <OneThirdImage width={width} src={Fourth}/>
              <OneThirdImage width={width} src={Fifth}/>
              <OneThirdImage width={width} src={Sixth}/>
            </div>
            {/* <ThreeByThree>
                <OneTwiceImage src={First}/>
                <OneTwiceImage src={Second}/>
            </ThreeByThree>
            <ThreeByThree>
                <OneTwiceImage src={Third}/>
                <OneTwiceImage src={Fourth}/>
            </ThreeByThree>
            <ThreeByThree>
                <OneTwiceImage src={Fifth}/>
                <OneTwiceImage src={Sixth}/>
            </ThreeByThree>
            <ThreeByThree>
                <OneTwiceImage src={Seventh}/>
                <OneTwiceImage src={Eighth}/>
            </ThreeByThree>
            <ThreeByThree>
                <OneTwiceImage ninth={true} src={Ninth}/>            
            </ThreeByThree> */}
        </>
        }
      </StyledDiv>
        <br/>
        <br/>
        {width > 980 ? <br/> : <div style={{marginBottom: '60px'}}></div>}
      <StyledDiv spaceRental={true} privateParties={"true"} bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default SpaceRental;