import { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import HeroBanner from '../components/hero';
import { Navbar } from '../components/nav';
import PromoBanner from '../components/promo-banner';
import Footer from '../components/footer';
import RedefineDanforth from '../assets/rdf-dan-studio.webp';
import StyledDiv from '../components/styled-div';
import TopLeft from '../assets/redefine-danforth.png';
import TopRight from '../assets/redefine-bwest.jpeg';
import { scaleNoShadow } from '../utils/keyframes.js';
import { useWindowSize } from '../hooks/use-window-size.js';
import PricingCard from '../components/pricing-card.js';
import ProductOne from '../assets/product-one.jpg';
import ProductTwo from '../assets/product-two.png';
import ProductThree from '../assets/product-three.png';
import { Card } from '../components/card.js';
// import First from '../assets/carousel-image-1.jpg';
import First from '../assets/review-maggie-h.svg';
// import Second from '../assets/danforth-group.jpg';
import Second from '../assets/rdf-bw-bride-squad-compressed.webp';
// import Third from '../assets/active-flex.png';
import Third from '../assets/review-jenn-l.svg';
// import Fourth from '../assets/holidays.png';
import Fourth from '../assets/rdf-bw-bride-group-dance-compressed.webp';
// import Fifth from '../assets/group.png';
import Fifth from '../assets/review-skye-rc.svg';
// import Sixth from '../assets/group-four-topleft.jpg';
import Sixth from '../assets/rdf-bw-bride-squad-2-transformed.webp';
import Star from '../assets/star-vector.png';
import Mail from '../assets/mail-vector.png';
import Check from '../assets/check-vector.png';
import Heart from '../assets/heart-vector.png';
import { LogoContext } from '../context/LogoContext.js';


const StyledH3 = styled.h3`
  font-size: 36px;
  font-weight: 700;
  line-height: 54px;
  text-align: left;
  color: #0C120E;
  font-family: Inter;
  margin-top: 1.5em;
`;

const StyledH4 = styled.h4`
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  text-align: left;
  color: #0C120E;
  font-family: Inter;
  margin-top: 1.5em;
`;

const ContainerDiv = styled.div`
    max-width: ${props => props.wider ? '85em' : props.pricingDiv ? '1280px' :  '1120px'};
    margin: 0 ${props => props.ourSpace ? 'auto' : (props.logoleftposition && window.innerWidth > 767) ? `${props.logoleftposition}px` : props.pricingDiv || props.ourPackages ? '' : '32px'};
    ${props => props.pricingDiv && window.innerWidth <= 1180 && 'margin: 0 32px;'}
    ${props => props.pricingDiv && window.innerWidth <= 680 && 'margin: 0 auto;' }
    margin-top: -3em;
`

const ThreeByThree = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    margin-top: -3em;
    margin-bottom: 3em;
    display: flex;
`

const TwoByTwo = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    margin-top: -3em;
    margin-bottom: 3em;
    display: flex;
`

const CardContainer = styled.div`
  display: flex; 
  justify-content: center;
  flex-direction: row;
  padding: ${props => props.privateParties ? '' : '3.5em'};
  max-width: ${props => props.price ? '105%' : props.icon ? '' : ''};
  ${props => props.icon && 'position: relative; top: -1em;'}
  margin: 0 23px;

  @media (max-width: 1010px) {
    flex-direction: ${props => props.price ? 'row' : props.icon ? 'column' : 'column'}; 
    padding: ${props => props.privateParties ? ' 0' : '2.5em 0'};
  }
`

const CardContainerTwo = styled.div`
  display: flex; 
  justify-content: center;
  flex-direction: row;
  padding: ${props => props.privateParties ? '' : '3.5em'};
  max-width: ${props => props.price ? '105%' : props.icon ? '1920px' : ''};
  ${props => props.icon && 'position: relative; top: -1em;'}
  margin-bottom: 6px;

  @media (max-width: 1010px) {
    flex-direction: ${props => props.icon ? 'row' : 'column'}; 
    padding: ${props => props.privateParties ? ' 0' : '2.5em 0'};
  }

  @media (max-width: 767px) {
    flex-direction: ${props => props.icon ? 'row' : 'column'}; 
    padding: ${props => props.privateParties ? ' 0' : '2.5em 0'};
  }

  @media (max-width: 687px) {
    flex-direction: column; 
  }
`

const CardContainerPrice = styled.div`
  display: flex; 
  justify-content: center;
  flex-direction: row;
  padding: ${props => props.price ? '' : '3.5em'};
  max-width: ${props => props.price ? '105%' : props.icon ? '1920px' : ''};
  ${props => props.icon && 'position: relative; top: -1em;'}

  @media (max-width: 1010px) {
    flex-direction: ${props => props.price ? 'row' : props.icon ? 'column' : 'column'}; 
    padding: ${props => props.privateParties ? ' 0' : '2.5em 0 0 0'};
  }

  @media (max-width: 810px) {
    flex-direction: column; 
    height: auto;
  }
`

const Link = styled.a`
  display: inline;
  color: #156763 !important;
  margin-bottom: 4px;
  text-decoration: underline;
`;

const QuarterImage = styled.img`
  max-width: 40em;
  width: 90%;
  height: 320px;
  object-fit: cover;
  border-radius: 8px;
  margin: 8px;
  display: inline;
  &:hover {
    animation: ${scaleNoShadow} .5s ease 0s 1 normal forwards;
  }
`;

const OneThirdImage = styled.img`
  width: ${props => props.width < 980 ? '90%' : '32%'};
  height: 320px;
  max-width: 546px;
  object-fit: cover;
  border-radius: 8px;
  margin: .4em .6em;
  display: inline;
  &:hover {
    animation: ${scaleNoShadow} .5s ease 0s 1 normal forwards;
  }
  ${props => props.width <= 980 && 'margin: 0 auto 24px auto;'}
`;

const OneTwiceImage = styled.img`
  width: 48%;
  height: 320px;
  max-width: 546px;
  object-fit: cover;
  border-radius: 8px;
  margin: .2em .4em;
  display: inline;
  &:hover {
    animation: ${scaleNoShadow} .5s ease 0s 1 normal forwards;
  }
`;

const PricingDiv = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: ${props => props.width <= 900 ? 'column' : 'row'};
  justify-content: space-around;

  @media (max-width: 900px) {
    padding: 2em;
  }

  @media (max-width: 680px) {
    padding: 0em;
  }
`

const StyledSubHeading = styled.p`
    text-align: left;
    font-family: Inter;
    color: #6B7280;
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;    
    margin-bottom: 18px;
`

const StyledMiniHeading = styled.p`
    text-align: left;
    font-family: Inter;
    color: #0C120E;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;    
`


function PrivateParties() {
  const [width, height] = useWindowSize(); 

  const { logoLeftPosition } = useContext(LogoContext);
  const [logoLeftPos, setLogoLeftPos] = useState();

  useEffect(() => {
    document.title = 'Redefine Fit - Private Parties';
  }, []);

  useEffect(() => {
    if (logoLeftPosition) {
      //  
      setLogoLeftPos(logoLeftPosition)
    }
  }, [logoLeftPosition])

  return (
    <div className="App">
      <PromoBanner />
      <Navbar />
      <HeroBanner titleText='Private Parties' coverImages={[RedefineDanforth]} height={'520px'} />

      <StyledDiv headerCopy={true} privateParties={"true"} bgColor={'#F5FFF7'}>
        <ContainerDiv logoleftposition={logoLeftPos && logoLeftPos}>

            <StyledH3>Let's celebrate something special</StyledH3>
            <br/>
            <StyledSubHeading>We offer competitive rates along with different packages to fit your party needs! We will work with you to plan a memorable bachelorette, birthday, bridal, or friend's night out.</StyledSubHeading>
        </ContainerDiv>
      </StyledDiv>

      <StyledDiv privateParties={"true"} bgColor={'#fff'}>
            {width > 1061 ? 
            <CardContainer partiesOnly={true} privateParties={true} icon={true} width={width}>
              <Card parties={true} icon={true} privateParties={true} image={Star} headerText={"1. Select package"} paragraphText={"We offer competitive rates along with different packages for you and your party to choose."} />
              <Card parties={true} icon={true} privateParties={true} image={Mail} headerText={"2. Email us"} htmlText={"<p style='font-size: 17px; font-weight: 400; font-family: Inter; text-align: left; margin-top: 8px; color: rgba(107, 114, 128, 1); line-height: 24px;'>Email us at <a href='mailto:info@redefine-fit.com' style='color: #156863; font-family: Inter; font-weight: 500; font-size: 17px; line-height: 24px; cursor: pointer;' >info@redefine-fit.com</a> and tell us the occasion and how many people you’re expecting.<p style='font-size: 20px; font-weight: 400; font-family: Inter; text-align: left; margin-top: 8px; color: rgba(107, 114, 128, 1);'>"} />
              <Card parties={true} icon={true} privateParties={true} check={true} image={Check} headerText={"3. Initial deposit"} paragraphText={"After confirming occasion, guest, and location details, a $100 deposit will be required to secure your slot."} />
              <Card lastParty={true} parties={true} icon={true} privateParties={true} image={Heart} headerText={"4. Have fun!"} paragraphText={"Sit back, relax, and look forward to a fun evening with family and friends! We will take care of the rest."} />
            </CardContainer>
            :
            <>
            <CardContainerTwo  partiesOnly={true} privateParties={true} icon={true} width={width}>
                <Card parties={true} firstParty={true} icon={true} privateParties={true} image={Star} headerText={"1. Select package"} paragraphText={"We offer competitive rates along with different packages for you and your party to choose."} />
                <Card parties={true} secondParty={true} icon={true} privateParties={true} image={Mail} headerText={"2. Email us"} htmlText={"<p style='font-size: 17px; font-weight: 400; font-family: Inter; text-align: left; margin-top: 8px; color: rgba(107, 114, 128, 1); line-height: 24px;'>Email us at <a href='mailto:info@redefine-fit.com' style='color: #156863; font-family: Inter; font-weight: 500; font-size: 17px; line-height: 24px; cursor: pointer;' >info@redefine-fit.com</a> and tell us the occasion and how many people you’re expecting.<p style='font-size: 20px; font-weight: 400; font-family: Inter; text-align: left; margin-top: 8px; color: rgba(107, 114, 128, 1);'>"} />
            </CardContainerTwo>
            <CardContainerTwo  partiesOnly={true} privateParties={true} icon={true} width={width}>
                <Card parties={true} firstParty={true} icon={true} privateParties={true} check={true} image={Check} headerText={"3. Initial deposit"} paragraphText={"After confirming occasion, guest, and location details, a $100 deposit will be required to secure your slot."} />
                <Card lastParty={true} secondParty={true} parties={true} icon={true} privateParties={true} image={Heart} headerText={"4. Have fun!"} paragraphText={"Sit back, relax, and look forward to a fun evening with family and friends! We will take care of the rest."} />
             </CardContainerTwo>
            </>
            }     
      </StyledDiv>

      <StyledDiv headerCopy={true} privateParties={"true"} bgColor={'#F5FFF7'}>
        <ContainerDiv logoleftposition={logoLeftPos && logoLeftPos}>
            <StyledH4>Our space</StyledH4>
            <br/>
            <StyledMiniHeading>We can host your gathering at either of our two beautiful studios located in Toronto! Our east location is steps away from Chester Subway station on the Danforth. Our west location is steps away from Lansdown Station on Bloor West.</StyledMiniHeading>
            <br/>
            <br/>
            <br/>
        </ContainerDiv>
            <br/>
            <br/>
        <ContainerDiv ourSpace={true} wider={true}>
            <QuarterImage src={TopLeft}/>
            <QuarterImage src={TopRight}/>
        </ContainerDiv>
      </StyledDiv>

      <StyledDiv pricingDiv={true} headerCopy={true} privateParties={"true"} bgColor={'#fff'}>
        <ContainerDiv ourPackages={true} logoleftposition={logoLeftPos && logoLeftPos}>
            <StyledH4>Our packages</StyledH4>
            <br/>
            <StyledMiniHeading>Choose from three packages below! We can customize each package based on the occasion and personal needs.</StyledMiniHeading>
            <br/>
            {width > 980 && <br/>}
            {width > 900 && <br/>}
        </ContainerDiv>      
        <PricingDiv width={width}>
              <PricingCard right={'10px'} link={'/contact-us'} first={true} privateParties={true} noLeft={true} membershipTitle='Basic' perMonthPrice={35} orText='60 minutes (1 hour)' listItems={['60 minutes of specialized choreography']} fullLength={true} buttonText1='CONTACT US'/>
              <PricingCard right={'10px'} link={'/contact-us'} second={true} noLeft={true} privateParties={true} membershipTitle='Extra' favorite={true} perMonthPrice={40} orText='70 minutes (1 hour + 10 min)' listItems={['60 minutes of specialized choreography', 'Photoshoot with up to 7 edited images', '2.5 inch party buttons/pins for the entire group']} fullLength={true} buttonText1='CONTACT US'/>
              <PricingCard right={'10px'} link={'/contact-us'} third={true} noLeft={true} privateParties={true} membershipTitle='Special Edition' perMonthPrice={45} orText='70 minutes (1 hour + 10 min)' listItems={['60 minutes of specialized choreography', 'Photoshoot with up to 10 edited images', '2.5 inch party buttons/pins for the entire group', 'Cupcakes (vegan and/or gluten-free options available upon request)', 'Free gift for the guest of honour']} fullLength={true} buttonText1='CONTACT US' />
        </PricingDiv>  
        <br/>
        <br/>
        <br/>
        <ContainerDiv pricingDiv={true} logoleftposition={logoLeftPos && logoLeftPos}>
            <StyledH3>Add-ons</StyledH3>
            <br/>
            <StyledMiniHeading>For bridal or bachelorette parties, add a tank top to for $10/person! The top can read "Bride" or "Bride Tribe".</StyledMiniHeading>
            <br/>
            <StyledMiniHeading>We will ship the entire order to your home. In addition, we will add custom 2.5 inch pins/buttons for your party. You can request what ever you would like it to say! If interested, in your email private party request, tell us which add-ons you and your party would like.</StyledMiniHeading>
            <br/>
            <StyledMiniHeading>If you need the items earlier we're happy to offer tracked shipping of your order to your home. In addition, we will add custom 2.5 inch pins/buttons for your party. You can request whatever you would like it to say! If interested, in your email private party request, tell us which add-ons you and your party would like.</StyledMiniHeading>
            <br/>
            <br/>
            <CardContainerPrice width={width} price={true}>
              <Card image={ProductOne} privatePartiesProduct={true} price={10} headerText={"Bride/Tribe Tank Top"} paragraphText={"Gift the bride their very own “Bride” tank top, celebrate with a “Bride Tribe” tank top for everyone!"} />
              <Card image={ProductTwo} privatePartiesProductSecond={true} privatePartiesProduct={true} price={19} headerText={"Custom Tank Top"} paragraphText={"Add your own flare! For example, “Amys Group”, or whatever you and your group would like!"} />
              <Card image={ProductThree} privatePartiesProductThird={true} privatePartiesProduct={true} price={15} headerText={"Custom Add-on"} paragraphText={"Reach out to us to discuss a custom design! Examples include a custom mug, make-up pouch, buttons, etc."} />
            </CardContainerPrice>
        </ContainerDiv> 
      </StyledDiv>

      <StyledDiv bottomPrivateParties={true} privateParties={"true"} bgColor={'#F5FFF7'}>
        <ContainerDiv logoleftposition={logoLeftPos && logoLeftPos}>
            <StyledH3>Past celebrations</StyledH3>
            <br/>
            <StyledMiniHeading>Take a look at the kinds of parties we've hosted in the past! You can also <Link href="https://www.instagram.com/redefine_fitLtd" target='_blank'>check us out on Instagram</Link> to see what else we get up to.</StyledMiniHeading>
            <br/>
            {width > 832 && <br/>}
            {width > 980 && <br/>}
        </ContainerDiv>
        {
        width > 980 ? 
        <>
        <br/>
        <ThreeByThree>
            <OneThirdImage src={First}/>
            <OneThirdImage src={Second}/>
            <OneThirdImage src={Third}/>
        </ThreeByThree>
        <ThreeByThree>
            <OneThirdImage src={Fourth}/>
            <OneThirdImage src={Fifth}/>
            <OneThirdImage src={Sixth}/>
        </ThreeByThree>
        {/* <ThreeByThree>
            <OneThirdImage src={Seventh}/>
            <OneThirdImage src={Eighth}/>
            <OneThirdImage src={Ninth}/>
        </ThreeByThree> */}
        </>   
        :
        <>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <OneThirdImage width={width} src={First}/>
            <OneThirdImage width={width} src={Second}/>
            <OneThirdImage width={width} src={Third}/>
            <OneThirdImage width={width} src={Fourth}/>
            <OneThirdImage width={width} src={Fifth}/>
            <OneThirdImage width={width} src={Sixth}/>
          </div>
            {/* <ThreeByThree>
                <OneTwiceImage src={First}/>
                <OneTwiceImage src={Second}/>
            </ThreeByThree>
            <ThreeByThree>
                <OneTwiceImage src={Third}/>
                <OneTwiceImage src={Fourth}/>
            </ThreeByThree>
            <ThreeByThree>
                <OneTwiceImage src={Fifth}/>
                <OneTwiceImage src={Sixth}/>
            </ThreeByThree>
            <ThreeByThree>
                <OneTwiceImage src={Seventh}/>
                <OneTwiceImage src={Eighth}/>
            </ThreeByThree>
            <ThreeByThree>
                <OneTwiceImage ninth={true} src={Ninth}/>            
            </ThreeByThree> */}
        </>
        }
      </StyledDiv>

      <StyledDiv footerDiv={true} privateParties={"true"} bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default PrivateParties;