import { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import HeroBanner from '../components/hero';
import { Navbar } from '../components/nav';
import PromoBanner from '../components/promo-banner';
import Footer from '../components/footer';
import RedefineDanforth from '../assets/rdf-dan-studio.webp';
import StyledDiv from '../components/styled-div';
import { useWindowSize } from '../hooks/use-window-size.js';
import { LogoContext } from '../context/LogoContext.js';

const StyledH3 = styled.h3`
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  text-align: left;
  color: #111928;
  font-family: Inter;
`;

const ContainerDiv = styled.div`
    margin: 0 ${props => (props.logoleftposition && props.width > 757) ? `${props.logoleftposition}px` : 'auto'};
    margin-top: -3em;
    margin-bottom: 3em;

    @media (max-width: 757px) {
      padding: 0 2em;
    }    

    @media (max-width: 679px) {
      margin-bottom: 0px;
    }    
`

const StyledP = styled.p`
    text-align: left;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
`

const StyledLi = styled.li`
    text-align: left;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
`;

const StyledA = styled.a`
    color: #156863;
    font-family: Inter;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
`;

const StyledB = styled.span`
    color: #0c120e;
    font-family: Inter;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
`


function VoucherRedemption() {
  const [width, height] = useWindowSize(); 
  const { logoLeftPosition } = useContext(LogoContext);
  const [logoLeftPos, setLogoLeftPos] = useState();

  useEffect(() => {
    document.title = 'Redefine Fit - Voucher Redemption';
  }, []);

  useEffect(() => {
    if (logoLeftPosition) {
      //  
      setLogoLeftPos(logoLeftPosition)
    }
  }, [logoLeftPosition])

  return (
    <div className="App">
      <PromoBanner />
      <Navbar />
      <HeroBanner titleText='Voucher Redemption' coverImages={[RedefineDanforth]} height={'515px'} />
      <StyledDiv trueWidth={width} voucher={true} actualWidth={width} noWidth={true} contactUs={true} logoleftposition={logoLeftPos && logoLeftPos} specialFormatting={{'p': "Congratulations on purchasing your voucher for Redefine Fit! We're excited to have you try pole dancing and begin your journey with us."}} bgColor={'#fff'} textAlign={'left'} 
      width={'31em'} />
        <ContainerDiv width={width} logoleftposition={logoLeftPos && logoLeftPos}>
            <br/>
            <br/>
            <StyledH3>How to redeem your Groupon voucher:</StyledH3>
            <br/>
            <ol style={{paddingLeft: '1.3em;'}}>
                <StyledLi>Visit the “MY ACCOUNT” page at the top of the website. You will be directed to the web page of the scheduling software Wellness Living App</StyledLi>
                <StyledLi>Complete creating your Wellness Living account.</StyledLi>
                <StyledLi><StyledA href="/contact-us">Visit our Contact Us page</StyledA> to send us a message or email us at <StyledA href="mailto:info@redefine-fit.com">info@redefine-fit.com</StyledA> with the following information: <StyledB>Your first and last name, and the email you used for creating a Wellness Living account. As well, please attach a copy of your voucher or include the 8-digit voucher number in the email.</StyledB> You can find the voucher number beside the barcode.</StyledLi>
                <StyledLi>We will add your class credits to your Wellness Living account and email you back with confirmation.</StyledLi>
                <StyledLi>Once you hear from us you can book classes! Have fun!</StyledLi>
            </ol>
            <br/>
            <StyledP>You will hear from us within <StyledB>3–4 business days</StyledB>. If you haven't, double-check the spelling of our email: there is a dash (-) between “redefine” and “fit”.</StyledP>
            <br/>
            <StyledP>We can't wait to see you!</StyledP>
            <br/>
            <StyledP>Friendly reminder that <StyledB><em>Groupon codes are valid for 1 per person only.</em></StyledB></StyledP>
        </ContainerDiv>
      <br/>

      <StyledDiv bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default VoucherRedemption;