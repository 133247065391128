import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const scale = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        transform: scale(1);
    }

    100% {
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
        transform: scale(1.05);
    }
`

export const scaleNoShadow = keyframes`
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.05);
    }
`

export const wobble = keyframes`
    0%,
	100% {
		transform: translateX(0%);
		transform-origin: 50% 50%;
	}

	15% {
		transform: translateX(-30px) rotate(-6deg);
	}

	30% {
		transform: translateX(15px) rotate(6deg);
	}

	45% {
		transform: translateX(-15px) rotate(-3.6deg);
	}

	60% {
		transform: translateX(9px) rotate(2.4deg);
	}

	75% {
		transform: translateX(-6px) rotate(-1.2deg);
	}
`

export const shakeBottom = keyframes`
	0%,
	100% {
		transform: rotate(0deg);
		transform-origin: 50% 100%;
	}

	10% {
		transform: rotate(2deg);
	}

	20%,
	40%,
	60% {
		transform: rotate(-4deg);
	}

	30%,
	50%,
	70% {
		transform: rotate(4deg);
	}

	80% {
		transform: rotate(-2deg);
	}

	90% {
		transform: rotate(2deg);
	}
`

export const slideDown = keyframes`
  0% { max-height: 0; }
  100% { max-height: 1000px; }
`;

export const slideUp = keyframes`
  0% { max-height: 1000px; }
  100% { max-height: 0px; }
`;
