import React, { useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import LogoWhite from "./../assets/redefine-logo-white.png"
import { CallToActionButton } from "../utils/cta-button";
import { Link } from "react-router-dom";
import { useWindowSize } from '../hooks/use-window-size.js';
import { MobileNav } from "./mobile-nav.js";
import { Analytics } from "@vercel/analytics/react";
import { LogoContext } from "../context/LogoContext.js";

const NavbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5em 0;
  max-width: 76em;
  margin: 0 auto;

  @media (max-width: 1320px) {
    margin: 0 2em;
  }

  @media (max-width: 754px) {
    padding: 0;
  }

  @media (max-width: 680px) {
    display: none;
  }
`;

const Logo = styled.img`
  background-image: url('../assets/redefine-logo-white.png');
  background-size: cover;
  height: 5.25em;
  width: 6.0625em;
  transition: transform .7s ease-in-out;
  &:hover {
    transform: rotate(360deg);
  }

  @media (max-width: 918px) {
    height: 4.25em;
    width: 5.0625em;
  }

  @media (max-width: 912px) {
    height: 4em;
    width: 4.5em;
    position: relative;
    margin: 0 auto;
    top: 0.5em;
  }
`;

const NavbarParent = styled.div`
    position: absolute;
    width: 100%;
    z-index: 9999;
`

const NavLinks = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  margin-top: 2em;
`;

const StyledLink = styled.a`
  color: rgba(255, 255, 255, 1);
  font-family: 'Inter-Medium', Helvetica;
  font-size: 1em;
  padding: 0 2em;
  margin-top: 2em;
  cursor: pointer;
  font-weight: bold;

  @media (max-width: 1221px) {
    font-size: 0.9em;
  }

  @media (max-width: 1132px) {
    font-size: 0.8em;
  }

  @media (max-width: 1043px) {
    font-size: 0.79em;
  }

  @media (max-width: 1034px) {
    font-size: 0.77em;
  }

  @media (max-width: 1016px) {
    font-size: 0.8em;
  }
`;

const Dropdown = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5em 0.8em;
  margin-top: 1px;
  margin-left: ${props => props.marginLeft ? props.marginLeft : '0'};
  width: ${props => props.width};
  border-radius: 8px;
  background-color: #fff;
  position: absolute;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`;

const StyledRouterLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  &:hover {
    text-decoration: underline;

    ${Dropdown} {
      display: flex;
    }
  }
`;

const DropdownLink = styled(Link)`
  color: rgba(107, 114, 128, 1);
  line-height: 17.5px;
  text-decoration: none;
  font-size: 14px;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  padding: 4px 0px 4px 0px;
  &:hover {
    color: rgba(21, 103, 99, 1);
    font-weight: 700;
  }
`;

export const Navbar = () => {
  const [width, height] = useWindowSize(); 
  const logoRef = useRef(null); 
  const { setLogoLeftPosition } = useContext(LogoContext);

  useEffect(() => {
    const updateLogoPosition = () => {
      if (logoRef.current) {
        const leftPosition = logoRef.current.getBoundingClientRect().left;
        setLogoLeftPosition(leftPosition);
      }
    };

    updateLogoPosition(); // Update on mount and resize
    window.addEventListener('resize', updateLogoPosition);

    return () => {
      window.removeEventListener('resize', updateLogoPosition);
    };
  }, []); 

  const handleClick = (e) => {
    e.preventDefault() 

    return;
  }

  return (
    <NavbarParent>
        <Analytics />
        {width <= 680 && <MobileNav />}
        {(width <= 754 && width > 680) && <Link to="/"><Logo src={LogoWhite} /></Link>}
        <NavbarWrapper ref={logoRef}>
            {width > 754 ? <Link to="/"><Logo src={LogoWhite} /></Link> : ''}
            <NavLinks>
                <StyledRouterLink>
                  <StyledLink onClick={(e) => handleClick(e)}>ABOUT</StyledLink>
                  <Dropdown width="155px">
                    <DropdownLink to="/who-we-are">Who We Are</DropdownLink>
                    <DropdownLink to="/studio-policies">Studio Policies</DropdownLink>
                    <DropdownLink to="/faq">FAQ</DropdownLink>
                    <DropdownLink to="/voucher-redemption">Voucher Redemption</DropdownLink>
                    <DropdownLink to="/contact-us">Contact Us</DropdownLink>
                  </Dropdown>
                </StyledRouterLink>
                <StyledRouterLink>
                  <StyledLink onClick={(e) => handleClick(e)}>{width <= 1016 ? "CLASSES" : "CLASSES & SCHEDULE"}</StyledLink>
                  <Dropdown marginLeft={width > 1221 ? "2em" : "1em"} width="186px">
                    <DropdownLink to="/our-classes">Our Classes</DropdownLink>
                    <DropdownLink to="/schedule">Schedule</DropdownLink>
                    <DropdownLink to="/workshop-and-events">Events &amp; Workshops</DropdownLink>
                  </Dropdown>
                </StyledRouterLink>
                <StyledRouterLink>
                  <StyledLink onClick={(e) => handleClick(e)}>PRICING</StyledLink>
                  <Dropdown width="188px">
                    <DropdownLink to="/passes">Passes &amp; Memberships</DropdownLink>
                    <DropdownLink to="/private-parties">Private Parties</DropdownLink>
                    <DropdownLink to="/gift-cards">Gift Cards</DropdownLink>
                    <DropdownLink to="/space-rental">Space Rental</DropdownLink>
                  </Dropdown>
                </StyledRouterLink>
                <StyledRouterLink to="/blog"><StyledLink>BLOG</StyledLink></StyledRouterLink>
                <StyledRouterLink target="_blank" to="https://redefine-fit-shop.com/shop"><StyledLink>SHOP</StyledLink></StyledRouterLink>
                <StyledRouterLink target="_blank" to="https://www.wellnessliving.com/login/redefine_fit"><StyledLink>{width <= 1016 ? "ACCOUNT" : "MY ACCOUNT"}</StyledLink></StyledRouterLink>
            </NavLinks>
            {width > 906 ? <CallToActionButton bookClass={true} buttonText={'BOOK CLASS'} /> : ''}
        </NavbarWrapper>
    </NavbarParent>
  );
};