import { Routes, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Home from "./pages/home";
import WhoWeAre from "./pages/who-we-are";
import StudioPolicies from "./pages/studio-policies";
import ContactUs from "./pages/contact-us";
import Faq from "./pages/faq";
import OurClasses from "./pages/our-classes";
import VoucherRedemption from "./pages/voucher-redemption";
import WorkshopAndEvents from "./pages/workshops-and-events";
import Schedule from "./pages/schedule";
import PassesAndMemberships from "./pages/passes-memberships";
import PrivateParties from "./pages/private-parties";
import GiftCards from "./pages/gift-cards";
import SpaceRental from "./pages/space-rental";
import Blog from "./pages/blog";
import Post from "./pages/post";

function App() {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames="fade"
        timeout={300}
      >
        <Routes >
            <Route path="/" exact element={<Home/>} />
            <Route path="/who-we-are" element={<WhoWeAre/>} />
            <Route path="/studio-policies" element={<StudioPolicies/>} />
            <Route path="/contact-us" element={<ContactUs/>} />
            <Route path="/faq" element={<Faq/>} />
            <Route path="/our-classes" element={<OurClasses/>} />
            <Route path="/voucher-redemption" element={<VoucherRedemption/>} />
            <Route path="/workshop-and-events" element={<WorkshopAndEvents/>} />
            <Route path="/schedule" element={<Schedule/>} />
            <Route path="/passes" element={<PassesAndMemberships/>} />
            <Route path="/private-parties" element={<PrivateParties/>} />
            <Route path="/gift-cards" element={<GiftCards/>} />
            <Route path="/space-rental" element={<SpaceRental/>} />
            <Route path="/blog/" element={<Blog/>} />
            <Route path="/blog/:id" element={<Blog/>} />
            <Route path="/post/:id" element={<Post/>} />
            <Route path="*" element={<Home/>} />
          </Routes>
        </CSSTransition>
    </TransitionGroup>
  );
}

export default App;


