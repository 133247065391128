import { useEffect, useState } from 'react';
import { ChooseStudio } from '../components/choose-studio';
import styled from 'styled-components';
import HeroBanner from '../components/hero';
import { Navbar } from '../components/nav';
import PromoBanner from '../components/promo-banner';
import Footer from '../components/footer';
import RedefineDanforth from '../assets/rdf-dan-studio.webp';
import StyledDiv from '../components/styled-div';
import { LogoContext } from '../context/LogoContext.js';
import { useContext } from 'react';

const StyledH3 = styled.h3`
  font-size: 36px;
  font-weight: 600;
  text-align: left;
  color: rgba(12, 18, 14, 1);
  font-family: "Inter-Bold", Helvetica;
`;

const ContainerDiv = styled.div`
    margin: 0 ${props => (props.logoleftposition && window.innerWidth > 767) ? `${props.logoleftposition}px` : 'auto'};
    margin-top: -3em;
    margin-bottom: 3em;

    @media (max-width: 1323px) {
      padding: 0 16px;
    }

    @media (max-width: 680px) {
      padding: 0;
    }
`

const StyledP = styled.p`
    font-family: Inter;
    text-align: left;
    font-size: 20px;
    font-weight: ${props => props.italic ? 400 : 400};
    line-height: 30px;
    color: #0C120E;
`

const StyledLi = styled.li`
    text-align: left;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #0C120E;
`;

const StyledA = styled.a`
    color: #156863;
    font-family: Inter;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
`;



function StudioPolicies() {
  const { logoLeftPosition } = useContext(LogoContext);
  const [logoLeftPos, setLogoLeftPos] = useState();

  useEffect(() => {
    document.title = 'Redefine Fit - Studio Policies';
  }, []);

  useEffect(() => {
    if (logoLeftPosition) {
      //  
      setLogoLeftPos(logoLeftPosition)
    }
  }, [logoLeftPosition])

  return (
    <div className="App">
      <PromoBanner />
      <Navbar />
      <HeroBanner titleText='Studio Policies' coverImages={[RedefineDanforth]} height={'520px'} />

      <StyledDiv studioPolicies={true} bgColor={'#fff'}>
        <ContainerDiv logoleftposition={logoLeftPos && logoLeftPos}>

            <StyledP>As our studio grows, we are committed to ensuring class sizes are the most conducive for your learning; and so we WILL NEVER OVERBOOK a class. However, to be fair to others within the studio looking to take the class if you late cancel or do not show up for a class a spot was unfortunately taken away from someone else.</StyledP>
            <br/>
            <StyledH3>Late Cancel</StyledH3>
            <br/>
            <StyledP>Please note: cancellations need to be made through the Achieve by Wellness Living APP or on our website. If you cancel LESS than 12 hours of the start of your scheduled class a $5 fee will be charged to your account, you will not lose the class.</StyledP>
            <br/>
            <br/>
            <StyledH3>No Show</StyledH3>
            <br/>
            <StyledP>If you do not show up for a class:</StyledP> 
            <br/>
            <StyledLi>Members: $10 will be charged to your credit card through our scheduling system Wellness Living</StyledLi>
            <StyledLi>Class Pass (Punch Card) Holders: You will lose the credit for that class</StyledLi>
            <StyledLi>Groupon/Voucher: You will lose the credit for that class and a $5 fee will be applied to your account</StyledLi>
            <br/>
            <br/>
            <StyledH3>Waitlist Policy</StyledH3>
            <br/>
            <StyledP>We always encourage students to sign up for the waitlist. Leading up to the class, some students may cancel early thus making room for those on the wait list.</StyledP>
            <br/>
            <StyledP>How the waitlist works:</StyledP>
            <br/>
            <ul>
                <StyledLi>If a spot opens up, you'll get an email to confirm your registration. Make sure you haven't opted out of automated emails.</StyledLi>
                <StyledLi>If you're on the waitlist for the class but not sure if you'll attend, please remove yourself. If you cancel, you will avoid getting charged.</StyledLi>
                <StyledLi>You will be automatically added to the classes UP TO A MAX of 3 HOURS before, at which point we will reach out to you via text to make sure you are able to still make it. Any additions before 3 Hours is your responsibility.</StyledLi>
                <StyledLi>If you are on the waitlist, and decide to “test your luck” you may come to class and wait to see if a spot becomes vacant. We will only be able to confirm after waiting 5 minutes to see if those who registered come.</StyledLi>
            </ul>
            <br/>
            <br/>
            <StyledH3>Private Party Deposit & Cancellation Policy</StyledH3>
            <br/>
            <StyledP>To reserve a party, a non-refundable deposit of $100 is required. However, we understand things may arise from time to time, as such if your party needs to reschedule we require 5 days written notice to <StyledA href="mailto:info@redefine-fit.com">info@redefine-fit.com</StyledA> prior to the scheduled party date. A cancellation within 5 days of your scheduled party date will result in the forfeit of your deposit.</StyledP>
            <br/>
            <br/>
            <StyledH3>Workshops/Troupes/Special Events Refund Policy</StyledH3>
            <br/>
            <StyledP>Please note that no refunds or spots will be held for workshops, troupes or special events. Please ensure you are able to commit fully before registering.</StyledP>
            <br/>
            <br/>
            <StyledH3>General Refund Policies</StyledH3>
            <br/>
            <StyledP>Please note that all sales are FINAL, and no refunds will be issued. You may transfer any regularly priced class passes or memberships to someone else in the event you are no longer able to use it.</StyledP>
            <br/>
            <br/>
            <StyledH3>Pausing or Holding Memberships</StyledH3>
            <br/>
            <ul>
                <StyledLi>Our Intro Month of $85 is a studio promotional price. As a result, there are <strong>NO MEMBERSHIP PAUSES OR HOLDS</strong> on that membership.</StyledLi>
                <StyledLi>Any memberships purchased through a sale will not be eligible for holds.</StyledLi>
                <StyledLi>Autobill/3 month memberships are valid for holds or pauses (Max of 1 within each 3 month period). If you have an excruciating circumstance that needs additional pauses please email us at <StyledA href="mailto:info@redefine-fit.com">info@redefine-fit.com</StyledA></StyledLi>
            </ul>
            <br/>
            <br/>
            <em><StyledP italic={true}>📌 Medical reasons are an exception, and we will hold your membership. We may ask you to provide a doctor's note.</StyledP></em>
        </ContainerDiv>
      </StyledDiv>

      <ChooseStudio background={'teal'} />

      <StyledDiv bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default StudioPolicies;