import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { CallToActionButton } from "../utils/cta-button";
import { useWindowSize } from '../hooks/use-window-size.js';
import { LogoContext } from '../context/LogoContext.js';
import { useContext } from 'react';

// const Hero = styled.div`
//   width: 100%;
//   height: ${props => props.height ? props.height: '49.75em'};
//   background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props => props.coverImage}) center no-repeat;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: ${props => props.bodyText ? 'center' : 'end'};
//   background-size: cover;
//   color: #fff;

//   @media (max-width: 731px) {
//     height: 32em;
//   }
// `;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Hero = styled.div`
  width: 100%;
  height: ${props => props.height ? props.height : '49.75em'};
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props => props.coverImage}) center no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => props.bodyText ? 'center' : 'end'};
  background-size: cover;
  color: #fff;
  animation: ${fadeIn} 1.5s ease-in-out;

  @media (max-width: 731px) {
    height: 32em;
  }
`;

const Title = styled.h1`
  font-family: Inter, Helvetica;
  font-size: 3.75em; 
  font-weight: 700;
  ${props => !props.blog && 'line-height: 2.625em;'} 
  ${props => props.bodyText ? 'text-align: center' : ''};
  ${props => !props.bodyText ? 'position: absolute' : ''};
  ${props => !props.bodyText ? 'top: 5.5em' : ''};
  ${props => (!props.bodyText && props.logoleftposition) ? `left: ${ props.logoleftposition + (props.plusTwenty ? 20 : 0)}px` : ''};

  @media (max-width: 754px) {
    ${props => (!props.bodyText && props.logoleftposition) ? `left: ${props.logoleftposition + 41}px` : ''};
  }

  @media (max-width: 754px) {
    line-height: 1.325em;
  }

  @media (max-width: 680px) {
    ${props => (!props.bodyText) && `left: auto; top: -1.5em;`};
  }

  @media (max-width: 680px) {
    font-size: 3.25em;
    margin: 2em 1em 1em 1em;
    position: relative;
    text-align: center;
  }

  @media (max-width: 476px) {
    margin: 2em auto 1em auto;
  }

  @media (max-width: 436px) {
    font-size: 2.8em;
    margin: 1em auto 2em auto;
  }

  @media (max-width: 387px) {
    font-size: 2.5em;
    margin: 1em auto 2.5em auto;
  }

  ${props => props.homePage && `
  @media (max-width: 754px) {
    margin: 1em 1.5em 0 1.5em;
  }    
`}

  ${props => props.homePage && `
  @media (max-width: 726px) {
    margin: 1em 1em 0 1em;
  }    
`}

  ${props => props.homePage && `
  @media (max-width: 680px) {
    margin: 2.5em 1em .02em 1em;
  }    
`}

  ${props => props.homePage && `
  @media (max-width: 524px) {
    margin: 2.5em auto .1em auto;
  }    
`}

  ${props => props.homePage && `
  @media (max-width: 476px) {
    margin: 2.5em auto .1em auto;
  }    
`}

  ${props => props.homePage && `
    @media (max-width: 436px) {
      margin: 2.5em auto .1em auto;
    }    
  `}

  @media (max-width: 354px) {
    font-size: 2.957em;
  }

  @media (max-width: 351px) {
    font-size: 2.907em;
  }

  @media (max-width: 344px) {
    font-size: 2.607em;
    margin-bottom: 1em;
  }
`;

const SupportingText = styled.p`
  font-size: 1.25em; 
  font-weight: 400;
  text-align: center;
  max-width: 36em; 
  font-family: "Inter-Bold", Helvetica;

  @media (max-width: 731px) {
    display: none;
  }
`;

const HeroBanner = ({blog, homePage, plusTwenty, link, titleText, bodyText, callToAction, coverImage, coverImages, height}) => {
  const { logoLeftPosition } = useContext(LogoContext);
  const [logoLeftPos, setLogoLeftPos] = useState();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (logoLeftPosition) {
      //  
      setLogoLeftPos(logoLeftPosition)
    }
  }, [logoLeftPosition])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % coverImages.length);
    }, 3000); 
    return () => clearInterval(intervalId);
  }, [coverImages.length]);

  return (
    <Hero bodyText={bodyText} coverImage={coverImages[currentImageIndex]} height={height}>
      <Title blog={blog} homePage={homePage} plusTwenty={plusTwenty} logoleftposition={logoLeftPos && logoLeftPos} bodyText={bodyText}>{titleText}</Title>
      <SupportingText>
        {bodyText}
      </SupportingText>
      {callToAction && <CallToActionButton link={link} bodyText={bodyText} noLeft={true} buttonText="BOOK CLASS" />}
    </Hero>
  )
};

export default HeroBanner;