import { useEffect, useState, useContext } from 'react';
import { ChooseStudio } from '../components/choose-studio';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import HeroBanner from '../components/hero';
import { Navbar } from '../components/nav';
import PromoBanner from '../components/promo-banner';
import Footer from '../components/footer';
import RedefineDanforth from '../assets/rdf-dan-studio.webp';
import StyledDiv from '../components/styled-div';
import Dance from '../assets/danforth-dance.png';
import { useWindowSize } from '../hooks/use-window-size.js';
import { Card } from '../components/card';
import { CallToActionButton } from '../utils/cta-button';
import { LogoContext } from '../context/LogoContext.js';
import Loader from '../components/loader.js';
import moment from 'moment';

const ThreeByThree = styled.div`
    max-width: 1134px;
    margin-top: -3em;
    margin-bottom: 3em;
    display: flex;
`

const authorKey = {
    "1": "Redefine Fit"
}

const reverseCategoryKey = {
    "Uncategorized": "1",
    "Events": "4"
}

const StyledSpan = styled.span`
    background-color: #fffeeb;
    color: rgba(21, 103, 99, 1);
    font-weight: 700;
    font-size: 1em;
    height: 2.75em;
    width: 9.5625em;
    border: .5px solid;
    border-radius: 50em;
    cursor: pointer;
    padding: .5em;
`

function Blog() {
    const [entries, setEntries] = useState([]);
    const [pagination, setPagination] = useState(6)
    const [entriesToShow, setEntriesToShow] = useState([]);
    const {state} = useLocation();
    const [width, height] = useWindowSize(); 

    const { logoLeftPosition } = useContext(LogoContext);
    const [logoLeftPos, setLogoLeftPos] = useState();

    useEffect(() => {
        document.title = 'Redefine Fit - Blog';
      }, []);
  
    useEffect(() => {
      if (logoLeftPosition) {
        //  
        setLogoLeftPos(logoLeftPosition)
      }
    }, [logoLeftPosition])

    useEffect(() => {
        setEntriesToShow(entries.slice(0, pagination))
    }, [pagination])

    useEffect(() => {
        window.scrollTo(0, 0)

        if (!entriesToShow.length) {
            if (state) {
                if (state.author) {
                    fetch(`https://redefine-fit-shop.com/wp-json/wp/v2/posts?categories=${state.author}`)
                    .then(data => data.json())
                    .then(json => {
                         
                        setEntries(json)
                        setEntriesToShow(json.slice(0,6))
                    })
                    .catch(err => {
                        console.log('err', err)
                    })
                } else if (state.category) {
                    fetch(`https://redefine-fit-shop.com/wp-json/wp/v2/posts?categories=${reverseCategoryKey[state.category]}`)
                    .then(data => data.json())
                    .then(json => {
                         
                        setEntries(json)
                        setEntriesToShow(json.slice(0,6))
                    })
                    .catch(err => {
                        console.log('err', err)
                    })
                }
            } else {
                // fetch('https://redefine-fit-shop.com/wp-json/wp/v2/posts/?categories=1')
                fetch('https://redefine-fit-shop.com/wp-json/wp/v2/posts/')
                .then(data => data.json())
                .then(json => {
                    setEntries(json)
                    setEntriesToShow(json.slice(0,6))
                })
                .catch(err => {
                    console.log('err', err)
                })
            }
        } 
    }, [])

    const clearTags = (tag) => {
        // fetch('https://redefine-fit-shop.com/wp-json/wp/v2/posts/?categories=1')
        fetch('https://redefine-fit-shop.com/wp-json/wp/v2/posts/')
        .then(data => data.json())
        .then(json => {
            state.category = null 
            state.author = null
            setEntries(json)
            setEntriesToShow(json.slice(0,6))
        })
        .catch(err => {
            console.log('err', err)
        })
    }

  const BlogPosts = ({entriesToShow}) => {
     
    let indexCount = 0
    let indexCap = 2
    let entriesToMap = []
    entriesToShow.forEach((entry, i) => {
        if (i <= indexCap) {
            Array.isArray(entriesToMap[indexCount]) ? entriesToMap[indexCount].push(entry) : entriesToMap[indexCount] = [entry]
        } else {
            indexCap = indexCap + 3
            indexCount = indexCount + 1
            
            entriesToMap[indexCount] = [entry]
        }
    })

    return (
        <StyledDiv blog={true} contactUs={true} logoleftposition={logoLeftPos && logoLeftPos} bgColor={'#fff'}>
        {width >= 851 ? 
        <>
        <br/>
        <br/>
        </>
        :
        ''
        }

        {(state && (state.category || state.author)) && <div style={{maxWidth: '1134px', margin: '0 auto'}}><p style={{color: '#6B7280', fontSize: '20px', lineHeight: '30px', fontWeight: '400', textAlign: 'left', position: 'relative', top: `${width > 850 ? '-3em' : '0em'}`, left: '1em'}}>Tags: {state && state.category ? <StyledSpan onClick={() => clearTags(state.category)}>{state.category} ×</StyledSpan> : state && state.author ? <StyledSpan onClick={() => clearTags(state.author)}>{authorKey[state.author]} ×</StyledSpan> : ''}</p></div>}
        {entriesToShow.length === 0 ? 
        <Loader />
        :
        width >= 1009 ? 
        <>
        {entriesToMap.map(threeEntries => {
            if (threeEntries.length < 3) { 
                return (
                    <ThreeByThree>
                    {threeEntries.map((entry, i) => {
                      return (
                        <>
                            <Card blogEntry={true} categories={entry.categories} author={entry.author} postId={entry.id} blog={true} content={entry.content.rendered} subline={moment(entry.date.split('T')[0]).format('MMMM D, Y')} homeScreen={true} image={entry.yoast_head_json.og_image ? entry.yoast_head_json.og_image[0].url?.replace("https://redefine-fit.com/","https://redefine-fit-shop.com/") : Dance} headerText={entry.title.rendered} paragraphText={entry.excerpt.rendered?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '')?.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').slice(0,101) +'...'} />
                            {(threeEntries.length === 1 && i === 0) ? 
                                <>
                                    <div style={{width: '33%', margin: '1em'}}/>
                                    <div style={{width: '33%', margin: '1em'}}/>
                                </>
                            : 
                            i === 1 &&
                            <div style={{width: '33%', margin: '1em'}}/>}
                        </>
                      )
                    })}
                    </ThreeByThree>
                )
            } else {
                return (
                <ThreeByThree>
                {threeEntries.map(entry => {
                  return (
                      <Card blogEntry={true} categories={entry.categories} author={entry.author} postId={entry.id} blog={true} content={entry.content.rendered} subline={moment(entry.date.split('T')[0]).format('MMMM D, Y')} homeScreen={true} image={entry.yoast_head_json.og_image ? entry.yoast_head_json.og_image[0].url?.replace("https://redefine-fit.com/","https://redefine-fit-shop.com/") : Dance} headerText={entry.title.rendered} paragraphText={entry.excerpt.rendered?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '')?.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').slice(0,101) +'...'} />
                  )
                })
                }
                </ThreeByThree>
                )
            }
        })}

        </> :
        <>
        {entriesToShow.map(entry => {
            return (
                <Card blogEntry={true}  categories={entry.categories} author={entry.author} postId={entry.id} blog={true} content={entry.content.rendered} subline={moment(entry.date.split('T')[0]).format('MMMM D, Y')} homeScreen={true} image={entry.yoast_head_json.og_image ? entry.yoast_head_json.og_image[0].url?.replace("https://redefine-fit.com/","https://redefine-fit-shop.com/") : Dance} headerText={entry.title.rendered} paragraphText={entry.excerpt.rendered?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '')?.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').slice(0,101) +'...'} />
            )
        })
        }
        </>
        }
      </StyledDiv>
    )
  }

  return (
    <div className="App">
      <PromoBanner />
      <Navbar />
      <HeroBanner blog={true} titleText={(state && state.category) ? `Blog / Posts in "${state.category}"` : (state && state.author) ? `Blog / Posts by ${authorKey[state.author]}` : 'Blog'} coverImages={[RedefineDanforth]} height={'520px'} />

      <BlogPosts entriesToShow={entriesToShow}/>
      {(entries.length > 6) ? (entriesToShow.length !== entries.length) ? <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1em', marginTop: '-3em'}}>
        <CallToActionButton seeMore={true} setPagination={setPagination} pagination={pagination} buttonText={"SEE MORE"} bottom={'17px'} bgColor={'#fffeeb'} textColor={'rgba(21, 103, 99, 1)'} />   
      </div>
      :
      <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1em', marginTop: '-3em'}}>
        <CallToActionButton seeLess={true} setPagination={setPagination} pagination={pagination} buttonText={"SEE LESS"} bottom={'17px'} bgColor={'#fffeeb'} textColor={'rgba(21, 103, 99, 1)'} />   
      </div>
      :
      <></>
      }

      <ChooseStudio background={'teal'} />

      <StyledDiv bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default Blog;