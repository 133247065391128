import React from "react";
import styled from 'styled-components';
import { wobble } from './keyframes.js';
import { useNavigate } from 'react-router-dom';

const CTA = styled.button`
  background-color: ${props => props.bgColor ? props.bgColor : '#87C996'};
  color: ${props => props.textColor ? props.textColor : '#FFFEEB'};
  font-weight: 700;
  font-size: 1em;
  height: ${props => props.blog ? 'auto' : '48px'};
  ${props => props.homeScreen && 'height: 48px;'}
  width: ${props => props.fullLength ? '100%' : props.buttonWidth ? props.buttonWidth : ' 9.5625em'};
  border: none;
  border-radius: 50em;
  cursor: pointer;
  margin-top: ${props => props.passesBottom ? '24px' : props => props.noTopMargin ? '' : props => props.passesBottom ? ' .8em' : '1.5em'};
  position: ${props => props.blog ? 'relative' : props.absolute ? 'absolute' : 'relative'};  
  margin: ${props => props.chooseStudio ? '0 auto' : props.margin ? props.margin : ''};
  bottom: ${props => props.homeScreen ? '1em' : props.bottom ? props.bottom : 'auto'};
  top: ${props => props.blog ? '' : props.giftCards ? '12.5em' : props.top ? props.top : (props.bodyText && window.innerWidth > 753) ? '-2em' : 'auto'};
  &:hover {
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    background-color: ${props => props.textColor ? props.textColor : '#FFFEEB'};
    color: ${props => props.bgColor ? props.bgColor : '#87C996'};
  }
  ${props => props.listOffers && 'margin-left: .4em;'}

  ${props => props.privateParties && 'position: absolute; bottom: 23px; width: 83%;'}

  ${props => props.homeScreen && `@media (max-width: 1280px) {
    ${!props.homeScreen && 'top: 12em'}; 
    ${props.homeScreen && 'bottom: 1em;'}
  }`}

  ${props => props.giftCards && `@media (max-width: 1244px) {
    top: 14.5em; 
  }`}

  ${props => props.giftCards && `@media (max-width: 1184px) {
    top: 14.5em; 
  }`}

  
  ${props => props.giftCards && `@media (max-width: 1150px) {
    top: 14.6em; 
  }`}


  ${props => props.blog && `@media (max-width: 1134px) {
    top: ${!props.blog && '15.5em'}; 
  }`}

  ${props => props.privateParties && `@media (max-width: 1055px) {
    left: 1.8em;
  }`}

  ${props => props.giftCards && `@media (max-width: 1029px) {
    top: 15.6em; 
  }`}


  ${props => props.homeScreen && `@media (max-width: 1108px) {
    ${props.homeScreen ? 'bottom: 1em' : 'top: 16.5em'}; 
  }`}

  ${props => props.homeScreen && `@media (max-width: 1016px) {
    ${props.blog && 'position: relative !important'};
    top: '17.5em'; 
  }`}

  ${props => props.homeScreen && `@media (max-width: 1010px) {
    position: ${props.blog ? 'relative' : props.homeScreen ? 'absolute' : 'static'};
    ${props.homeScreen ? 'bottom: 1em' : 'top: 17.5em'};
  }`}

  ${props => props.blog && `@media (max-width: 1008px) {
    z-index: 999;
  }`}

  ${props => props.giftCards && `@media (max-width: 1008px) {
    top: 15.8em; 
  }`}

  ${props => props.giftCards && `@media (max-width: 886px) {
    top: 16.8em; 
  }`}

  ${props => props.giftCards && `@media (max-width: 876px) {
    top: 17.8em; 
  }`}

  ${props => props.giftCards && `@media (max-width: 832px) {
    top: 19.8em; 
  }`}

  ${props => props.privateParties && `@media (max-width: 994px) {
    left: 1.7em;
  }`}

  ${props => props.privateParties && `@media (max-width: 981px) {
    left: 1.6em;
  }`}

  ${props => props.giftCards && `@media (max-width: 788px) {
    top: 19.8em;
  }`}

  ${props => props.giftCards && `@media (max-width: 770px) {
    top: 21.4em;
  }`}

  @media (max-width: 784px) {
    left: ${props => props.centerButton ? '36%' : ''}; 
  }

  ${props => props.giftCards && `@media (max-width: 766px) {
    position: unset;
  }`}

  ${props => props.privateParties && `@media (max-width: 900px) {
    width: 94%;
  }`}

  ${props => props.privateParties && `@media (max-width: 805px) {
    width: 93%;
  }`}

  ${props => props.privateParties && `@media (max-width: 699px) {
    width: 92%;
  }`}

  ${props => props.privateParties && `@media (max-width: 680px) {
    width: 91%;
  }`}

  ${props => props.privateParties && `@media (max-width: 617px) {
    width: 90%;
  }`}

  ${props => props.privateParties && `@media (max-width: 602px) {
    width: 96%;
  }`}

  ${props => props.privateParties && `@media (max-width: 525px) {
    width: 90%;
  }`}

  
  @media (max-width: 620px) {
    left: ${props => props.centerButton ? '33%' : ''}; ;
  }
  
  @media (max-width: 620px) {
    left: ${props => props.centerButton ? '32%' : ''};
  }
  
  @media (max-width: 617px) {
    left: ${props => (props.passesBottom || props.ourClasses ? '0em;' : props.noLeft || props.homePage) ? 'auto' : props.privateParties ? '1.8em;' : `${props.passes && props.firstButton ? '' : '.5em;'}`}
  }

  ${props => props.privateParties && `@media (max-width: 617px) {
    left: 1.6em;
  }`}

  ${props => props.privateParties && `@media (max-width: 602px) {
    width: 92%;
    left: 1.2em;
  }`}

  ${props => props.privateParties && `@media (max-width: 560px) {
    left: 1.1em;
  }`}

  @media (max-width: 525px) {
    left: ${props => ((props.noLeft || props.homePage)) ? 'auto' : !(props.firstButton && props.passes) && '.1em;'}
    ${props => props.passesBottom || props.ourClasses && 'left: 0em; width: 100%;'}
    ${props => props.fullLength && !props.privateParties && !props.passes && 'left: -.5em;'}
    ${props => props.ourClasses && 'left: -.2em;'}
    ${props => props.privateParties && 'left: 1.3em;'}
  }

  @media (max-width: 497px) {
    left: ${props => ((props.noLeft || props.homePage)) ? 'auto' : !(props.firstButton && props.passes) && '1em;'}
    ${props => props.passesBottom || props.ourClasses && 'left: 0em; width: 100%;'}
    ${props => props.fullLength && !props.privateParties && !props.passes && 'left: -.5em;'}
    ${props => props.ourClasses && 'left: -.2em;'}
    ${props => props.privateParties && 'left: 1.3em;'}

    @media (max-width: 487px) {
      left: ${props => ((props.noLeft || props.homePage)) ? 'auto' : (props.passes && props.firstButton) ? '.3em;' : props.passesBottom || props.ourClasses ? '0em;' : !(props.firstButton && props.passes) && !props.passesBottom && '.5em;'}
    }
  }

  @media (max-width: 447px) {
    left: ${props => ((props.noLeft || props.homePage)) ? 'auto' : (props.passes && props.firstButton) ? '.5em;' : props.passesBottom || props.ourClasses ? '0em;' : !(props.firstButton && props.passes) &&  !props.passesBottom && '.5em;'}
  }

  ${props => props.passes && props.firstButton && `@media (max-width: 485px) {
    left: .5em;
  }`}

  ${props => props.privateParties && `@media (max-width: 490px) {
    left: 1em;
  }`}

  ${props => props.privateParties && `@media (max-width: 437px) {
    width: 90%;
    left: 1.3em;
  }`}

  ${props => props.passes && props.firstButton && `@media (max-width: 431px) {
    left: .7em;
  }`}


  ${props => props.privateParties && `@media (max-width: 395px) {
    left: 1.1em;
  }`}

  ${props => props.privateParties && `@media (max-width: 430px) {
    left: 1.2em;
  }`}

  ${props => props.privateParties && `@media (max-width: 382px) {
    left: 1em;
  }`}

  ${props => props.chooseStudio && `@media (max-width: 420px) {
    width: 248px;
  }`}

  ${props => props.homePage && `@media (max-width: 442px) {
    width: 96%;
  }`}


  ${props => props.homeScreen && `@media (max-width: 450px) {
    width: 88%;
    margin-left: .89em;
  }`}

  ${props => props.homeScreen && `@media (max-width: 436px) {
    margin-left: .66em;
  }`}

  ${props => props.homeScreen && `@media (max-width: 414px) {
    ${props.homeScreen ? 'bottom: 1em;' : 'top: 10.2em;'}; 
  }`}

  ${props => props.instructorBook && `@media (max-width: 414px) {
    width: 86%;
  }`}

  ${props => props.giftCards && `@media (width: 375px) {
    width: 126px;
  }`}

  ${props => props.blog && 'position: relative !important;'}

  ${props => props.disabled && 'opacity: .5; cursor: not-allowed;'}
`;

export const CallToActionButton = ({disabled, bookClass, instructorBook, firstButton, ourClasses, listOffers, passesBottom, homePage, pagination, setPagination, seeMore, seeLess, privateParties, right, link, bodyText, noTopMargin, passes, categories, title, author, date, content, postId, blog, homeScreen, chooseStudio, giftCards, noLeft, fullLength, buttonWidth, margin, buttonText, absolute, bottom, top, bgColor, textColor }) => {
    const navigate = useNavigate();

    const handleClick = () => {
       
      if (disabled) {
        return
      } else if (bookClass || ourClasses) {
        navigate(`/schedule`)
      } else if (seeLess) {
        setPagination(pagination-3)
      } else if (seeMore) {
        setPagination(pagination+3)
      } else if (blog) {
        navigate(`/post/${postId}`, {state: {categories: categories, title: title, author: author, date: date, unparsedContent: content}})
      } else if (link) {
         
        if (link.includes('https://')) {
          window.open(link, '_blank', 'noreferrer');
        } else {
          window.scrollTo(0, 0)
          navigate(link)
        }
      }

    }

    return <CTA disabled={disabled} instructorBook={instructorBook} firstButton={firstButton} ourClasses={ourClasses} listOffers={listOffers} passesBottom={passesBottom} homePage={homePage} privateParties={privateParties} right={right} bodyText={bodyText} noTopMargin={noTopMargin} passes={passes} blog={blog} onClick={handleClick} homeScreen={homeScreen} chooseStudio={chooseStudio} giftCards={giftCards} noLeft={noLeft} fullLength={fullLength} buttonWidth={buttonWidth} margin={margin} absolute={absolute} bottom={bottom} top={top} textColor={textColor} bgColor={bgColor}>{buttonText}</CTA>
}