import { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import HeroBanner from '../components/hero';
import { Navbar } from '../components/nav';
import PromoBanner from '../components/promo-banner';
import Footer from '../components/footer';
import RedefineDanforth from '../assets/rdf-dan-studio.webp';
import StyledDiv from '../components/styled-div';
import { HorizontalCard } from '../components/horizontal-card.js';
import Class1 from '../assets/rdf-bw-group-climb-compressed-transformed.webp';
import SpinFit from '../assets/carousel-three-transformed-desktop.webp';
import Holidays from '../assets/rdf-bw-group-sensualpole-compressed-desktop.webp';
import ActiveFlex from '../assets/rdf-bw-group-activeflex.webp';
import Lollipop from '../assets/lollipop.png';
import Chair from '../assets/rdf-bw-group-chairdance-compressed-transformed.webp';
import PracticeHours from '../assets/rdf-dan-invert-compressed.webp';
import Splits from '../assets/carousel-two-transformed-desktop.webp';
import Heels from '../assets/redefine-heels.jpg';
import RedefineLogo from '../assets/redefine-logo-black.png'
import { useWindowSize } from '../hooks/use-window-size.js';
import { LogoContext } from '../context/LogoContext.js';

const ContainerDiv = styled.div`
    max-width: 1170px;
    margin: 0 ${props => (props.logoleftposition && props.width > 767) ? `${props.logoleftposition}px` : 'auto'};
    margin-top: -3em;
    margin-bottom: ${props => props.width <= 375 ? '0em' : '3em'};
`

function OurClasses() {
  const [width, height] = useWindowSize(); 
  const { logoLeftPosition } = useContext(LogoContext);
  const [logoLeftPos, setLogoLeftPos] = useState();

  useEffect(() => {
    document.title = 'Redefine Fit - Our Classes';
  }, []);

  useEffect(() => {
    if (logoLeftPosition) {
      //  
      setLogoLeftPos(logoLeftPosition)
    }
  }, [logoLeftPosition])

  return (
    <div className="App">
      <PromoBanner />
      <Navbar />
      <HeroBanner titleText='Our Classes' coverImages={[RedefineDanforth]} height={'515px'} />
      {width > 375 && <br/>}
      <StyledDiv ourClasses={true} trueClasses={true} events={true} logoleftposition={logoLeftPos && logoLeftPos} specialFormatting={{'p1': 'Do you have past pole dancing experience and are looking to take a higher level class?', 'a1': ['Visit our Contact Us', '/contact-us'], 'p2': 'page or email us at', 'a2': ['info@redefine-fit.com', 'mailto:info@redefine-fit.com'], 'p3': 'for an assessment!'}} bgColor={'#fff'} textAlign={'left'} 
      width={'31em'} actualWidth={width} />
      {/* {(width <= 784 && width > 680) &&
      <>
        <br />
        <br />
      </>} */}
      <StyledDiv classImages={true} ourClasses={true} bgColor={'#fff'}>
        <ContainerDiv width={width} logoleftposition={logoLeftPos && logoLeftPos}>
          <HorizontalCard ourClasses={true} headerText={'Pole Fit'} paragraphText={`Our most popular classes! Learn different pole dancing holds, spins and tricks! Our classes cater to everyone, from complete beginners who have never touched a pole, all the way to advanced classes!`} imagePath={Class1} buttonText='BOOK CLASS' imageLeft={width <= 785 ? true : false} imageWidth='540' textHeight='294' textWidth='542' headingFontFamily='Inter' headingFontWeight='500' bodyColor='rgba(107, 114, 128, 1)'/>
          <br/>
          
          <HorizontalCard ourClasses={true} headerText={'Spin Fit'} paragraphText={`Learn the foundations of spin pole! You will learn how to control your moves with the pole on spin, and expand your repertoire of spins and tricks.`} imagePath={SpinFit} buttonText='BOOK CLASS' imageLeft={true} imageWidth='540' textHeight='294' textWidth='542' headingFontFamily='Inter' headingFontWeight='500' bodyColor='rgba(107, 114, 128, 1)'/>
          <br/>
          
          <HorizontalCard ourClasses={true} headerText={'Sensual Pole Flow & Pole Flow'} specialFormatting={{'p1': `Explore the captivating world of pole flow with our two unique class offerings. In Sensual Pole Flow, we’ll explore the more erotic side of pole dancing as you link together movements into seductive choreography.`, 'p2': 'For those seeking a different style, our General Pole Flow focuses on lyrical movements, allowing you to express yourself through fluid, graceful sequences. Both classes are designed to enhance your flow and creativity on the pole.'}} imagePath={Holidays} buttonText='BOOK CLASS' imageLeft={width <= 785 ? true : false} imageWidth='540' textWidth='542' headingFontFamily='Inter' headingFontWeight='500' bodyColor='rgba(107, 114, 128, 1)'/>
          <br/>
          
          <HorizontalCard ourClasses={true} headerText={'Flex Fit: Flexibility'} specialFormatting={{'p1': `Looking to improve your mobility and flexibility? Our Flex Fit classes are perfect for you! We offer three types of flexibility training to cater to your goals. Join our Active Flexibility class to focus on enhancing your active range of motion.`, 'p2': `We also have specialized classes targeting shoulders and back flexibility, as well as dedicated sessions for achieving your splits. Whatever your flexibility goals, we've got you covered!`}} imagePath={ActiveFlex} buttonText='BOOK CLASS' imageLeft={true} imageWidth='540' textHeight='294' textWidth='542' headingFontFamily='Inter' headingFontWeight='500' bodyColor='rgba(107, 114, 128, 1)'/>
          <br/>
          
          <HorizontalCard ourClasses={true} headerText={'Lollipop'} specialFormatting={{'p1': 'This class is the perfect compliment to your pole dancing training. It’s a mix of a lyra /hoop and a pole to create this new apparatus called the Lollipop.','p2': `Create beautiful shapes on this unique apparatus! Learn how to get in and out of the lollipop, and execute cool tricks.`, 'bi': ['Pre-requisite:', 'Ability to climb the pole.']}} buttonText='BOOK CLASS' imagePath={Lollipop} imageLeft={width <= 785 ? true : false} imageWidth='540' textHeight='294' textWidth='542' headingFontFamily='Inter' headingFontWeight='500' bodyColor='rgba(107, 114, 128, 1)'/>
          <br/>

          <HorizontalCard ourClasses={true} headerText={'Chair Dance'} paragraphText={`Get fierce, fit and fabulous in this all-levels Chair Dance class, and learn an expertly constructed flow.  Put it all together and we're sure that you'll have a stunning routine to bring home to your own chair... Pulses will be racing (including yours!)`} imagePath={Chair} buttonText='BOOK CLASS' imageLeft={true} imageWidth='540' textHeight='294' textWidth='542' headingFontFamily='Inter' headingFontWeight='500' bodyColor='rgba(107, 114, 128, 1)'/>
          <br/>

          <HorizontalCard ourClasses={true} headerText={'Heels Conditioning/Dynamic Heels'} specialFormatting={{'p1': `Get ready to strut your stuff and tone those legs in our Heels Conditioning class. Designed to strengthen and sculpt your muscles while mastering the art of walking and dancing in heels, this class will leave you feeling empowered and ready to conquer the world all in heels!`, 'p2': 'Dynamic Heels will allow you to learn some foundational tricks that you can execute in heels.'}} imagePath={Heels} buttonText='BOOK CLASS' imageLeft={width <= 785 ? true : false} imageWidth='540' textWidth='542' headingFontFamily='Inter' headingFontWeight='500' bodyColor='rgba(107, 114, 128, 1)'/>
          <br/>

          <HorizontalCard ourClasses={true} headerText={'Belly Dance'} paragraphText={`Join us for ALL LEVELS Fusion style belly dancing. This class takes movement from traditional belly dance and blends it with contemporary, hip hop, Indian fusion, and other styles of dance. We will work on body isolations, arm patterns, smooth snake like movements, and of course the shimmy!`} imagePath={RedefineLogo} buttonText='BOOK CLASS' imageLeft={true} imageWidth='540' textHeight='294' textWidth='542' headingFontFamily='Inter' headingFontWeight='500' bodyColor='rgba(107, 114, 128, 1)'/>
          <br/>
          
          <HorizontalCard ourClasses={true} headerText={'Practice Hours'} paragraphText={`Want to practice a new move you learned in class, or nail a choreography? Use this time to perfect your spins, tricks, or dance moves. All levels are welcome!`} imagePath={PracticeHours} buttonText='BOOK CLASS' imageLeft={width <= 785 ? true : false}  imageWidth='540' textHeight='294' textWidth='542' headingFontFamily='Inter' headingFontWeight='500' bodyColor='rgba(107, 114, 128, 1)'/>
          <br/>
          
          <HorizontalCard ourClasses={true} headerText={'Private Classes'} specialFormatting={{p: `Our incredible team of instructors would be happy to offer 1-on-1 training for any level of pole dance or pole choreography!`, ul: ['$95/hour', '$425/5 hours (five 1-hour sessions)']}} imagePath={Splits} buttonText='CONTACT US' imageLeft={true} imageWidth='540' textHeight='294' textWidth='542' headingFontFamily='Inter' headingFontWeight='500' bodyColor='rgba(107, 114, 128, 1)'/>
        </ContainerDiv>
      </StyledDiv>

      <StyledDiv ourClasses={true} bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default OurClasses;