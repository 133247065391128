
import { useEffect, useState } from 'react';
import { ChooseStudio } from '../components/choose-studio';
import HeroBanner from '../components/hero';
import { Navbar } from '../components/nav';
import PromoBanner from '../components/promo-banner';
import Footer from '../components/footer';
import RedefineDanforth from '../assets/rdf-dan-studio.webp';
import StyledDiv from '../components/styled-div';
import { Accordion } from '../components/accordion';
import styled from 'styled-components';
import { LogoContext } from '../context/LogoContext.js';
import { useContext } from 'react';
import { useWindowSize } from '../hooks/use-window-size.js';

const StyledH2 = styled.h2`
  font-size: ${window.innerWidth > 1009 ? '30px' : window.innerWidth > 939 ? '24px' : '30px'};
  max-width: 1056px;
  font-family: Inter;
  font-weight: 600;
  color: #6B7280;
  ${props => props.width <= 939 && 'margin-bottom: 32px;'}
  ${props => props.width <= 939 && 'display: flex;'}
`;

const StyledFaqContainer = styled.div`
    display: flex; 
    flex-direction: ${props => (props.logoleftposition && window.innerWidth > 939) ? 'row' : 'column'};
    justify-content: space-between;
    margin: 64px ${props => (props.logoleftposition && window.innerWidth > 1009) ? `${props.logoleftposition}px` : '2em'};
    ${props => props.first && 'margin-top: -27px;'}
`

const StyledAccordionContainer = styled.div`
    ${props => (props.logoleftposition && window.innerWidth > 939) && 'max-width: 592px;'}
`

function Faq() {
    const { logoLeftPosition } = useContext(LogoContext);
    const [logoLeftPos, setLogoLeftPos] = useState();
    const [width, height] = useWindowSize(); 

    useEffect(() => {
        document.title = 'Redefine Fit - FAQ';
    }, []);
  
    useEffect(() => {
      if (logoLeftPosition) {
        //  
        setLogoLeftPos(logoLeftPosition)
      }
    }, [logoLeftPosition])

  return (
    <div className="App">
      <PromoBanner />
      <Navbar />
      <HeroBanner titleText='FAQ' coverImages={[RedefineDanforth]} height={'520px'} />

      <StyledDiv faq={true} bgColor={'#fff'}>
        <StyledFaqContainer logoleftposition={logoLeftPos && logoLeftPos} first={true}>
            <StyledH2 width={width} logoleftposition={logoLeftPos && logoLeftPos}>Getting started</StyledH2>
            <StyledAccordionContainer logoleftposition={logoLeftPos && logoLeftPos}>
                <Accordion 
                titleText="I'm under 18. Can I still take a pole dancing class?" 
                bodyObject={
                    [
                        ["p", "Yes, you can! When you arrive, please sign waivers. If you are under 18, bring a guardian to sign for you."]
                    ]
                }/> 
                <Accordion 
                titleText="It's my first pole dancing class. What do I need to know?" 
                bodyObject={
                    [
                        ["p", "Welcome to your first pole dance class! And welcome to the Redefine Fit Family! We're thrilled to have you start your pole journey with us. Please arrive at the studio 10 minutes early to get to know the space and sign your waiver."],
                        ["p", "Below are some helpful hints for first-timers:"],
                        ["ul", [
                            "Please do not apply lotion/body cream/oil etc. on your skin on the day of class. You risk sliding off the pole and making it unsafe for the students who use it next.",
                            "No jewelry: This is very important. Please take off any rings, bracelets, watches, or other things you're wearing on your hands or feet.",
                            "Attire: For pole class, please wear shorts (think booty shorts) so your skin can grip on the pole. For tops, wear anything you're comfortable moving around in, like a t-shirt, tank top, sports bra, etc.",
                            "Footwear: None needed! You will be barefoot."
                        ]]
                    ]
                }/>
                <Accordion 
                titleText="I have a voucher! How do I redeem it?" 
                bodyObject={
                [
                    ["ps", "Congratulations on purchasing your voucher for Redefine Fit! We're excited to have you try pole dancing and begin your journey with us. To redeem your voucher, "], ["a", {"href": "https://redefine-fit.com/voucher-redemption", "text": "visit this link."}]
                ]
                }/>
                <Accordion 
                titleText="I'm new to Redefine Fit, but have previous pole dance experience. Which level should I register for?" 
                bodyObject={
                    [
                        ["ps", "Welcome to Redefine Fit! We're thrilled to have you continue your pole dancing journey with us. Please email us at "],
                        ["a", {"href": "mailto:info@redefine-fit.com", "text": "info@redefine-fit.com"}], ["ps", " to determine your level. We will then send you an assessment form."],
                        ["pa", "After you complete the form, we will update your online client profile. This will allow you to access the classes suitable for your level."]
                    ]
                }/>
            </StyledAccordionContainer>
        </StyledFaqContainer>
        <StyledFaqContainer logoleftposition={logoLeftPos && logoLeftPos}>
            <StyledH2 width={width} logoleftposition={logoLeftPos && logoLeftPos}>Location and classes</StyledH2>
            <StyledAccordionContainer logoleftposition={logoLeftPos && logoLeftPos}>
                <Accordion 
                titleText="Do you have parking?" 
                bodyObject={
                    [
                        ["p", "There are several Green P options close by both of our studios that you are welcome to park at."],
                        ["p", "Danforth Studio: There are several Green P Parking lots around Chester. Danforth on Chester is the closest to us, which you can enter on Arundel. Below is a list of 4 of the closer Green P Parking lots:"],
                        ["ul", [
                            "14 Arundel Ave (Green P Lot 87)",
                            "35  Erindale Ave",
                            "18 Ferrier Ave (Green P Lot 156)",
                            "248 Danforth Ave (Green P Lot 277)"
                        ]],
                        ["p", "Bloor West Studio: There are a lot of Green P Parking lots. The one at Emerson is the closest to us, which you can enter on Margueretta Street (346 Margueretta St)."],
                    ]
                }/>
                <Accordion 
                titleText="Are there change rooms, lockers or showers?" 
                bodyObject={
                    [
                        ["p", "We have change rooms, but no lockers. But, our open-concept space lets you keep your belongings nearby. If you are taking classes in the back room you are welcome to bring your belongings with you to the second room. We do not have showers in our space."]
                    ]
                }/>
                <Accordion 
                titleText="What types of poles do you have?" 
                bodyObject={
                    [
                        ["p", "We have mounted competition-grade chrome, and stainless steel plated x-poles for students with metal sensitivities or allergies. They are 45mm in diameter and function as static and spin."]
                    ]
                }/>
                <Accordion 
                titleText="Do your poles spin?" 
                bodyObject={
                    [
                        ["p", "Yes. We have mounted competition-grade chrome, and stainless steel plated x-poles for students with metal sensitivies or allergies. They are 45mm in diameter and function as static and spin."],
                        ["p", 'Before taking our spin pole classes, you will need to have a foundation with the static pole. We call these classes "Spin Fit."']
                    ]
                }/>
                <Accordion 
                titleText="Do you do pole share?" 
                bodyObject={
                    [
                        ["p", "At this time, we are NOT doing pole share, each student will have their own pole when registering for class."],
                        ["p", "However, if you are waitlisted and your friend is already registered (or vice versa), you may choose to share a pole with your friend. Just send us a text at 647-539-9439 so we can manually add your friend on our end. Please inform us in advance to ensure we maintain a healthy max number to ensure a conducive learning environment with our class capacity."]
                    ]
                }/>
            </StyledAccordionContainer>
        </StyledFaqContainer>
        <StyledFaqContainer logoleftposition={logoLeftPos && logoLeftPos}>
            <StyledH2 width={width} logoleftposition={logoLeftPos && logoLeftPos}>Studio policies</StyledH2>
            <StyledAccordionContainer logoleftposition={logoLeftPos && logoLeftPos}>
                <Accordion 
                titleText="I'm waitlisted. What does this mean?" 
                bodyObject={
                    [
                        ["p", "We encourage students to sign up for our waitlist. Leading up to the class, some students may cancel early thus making room for those on the wait list."],
                        ["p", "How the waitlist works:"],
                        ["ul", [
                            "If a spot opens up, you'll get an email to confirm your registration. Make sure you haven't opted out of automated emails.",
                            "If you're on the waitlist for the class but not sure if you'll attend, please remove yourself. If you cancel, you will avoid getting charged.",
                            "We will add you to the classes up to 3 hours before. We will text you to confirm. Any additions before 3 hours are your responsibility.",
                            "If you are on the waitlist, and decide to 'test your luck' you may come to class and wait to see if a spot becomes vacant. We will only be able to confirm after waiting 5 minutes to see if those who registered come.",
                            "For the health and safety of our teachers and students, we maintain a ratio of 1:8 in our inverted classes."
                        ]]
                    ]
                }/>  
                <Accordion 
                titleText="What's your cancellation/no-show/refund policy?" 
                bodyObject={
                    [
                        ["p", "As our studio grows, we are still committed to keeping class sizes small. We will never overbook a class. To be fair to others in the studio, if you cancel late or don't show up, someone else loses their spot."],
                        ["ps", "All sales are final, we do not offer refunds on merchandise or classes. For detailed information related to cancellations and refunds, please "], ["a", {"href": "https://redefine-fit.com/studio-policies", "text": "visit this link."}]
                    ]
                }/> 
                <Accordion 
                titleText="How do I know when I am ready to progress to the next level?" 
                bodyObject={
                    [
                        ["p", "Once you are ready for progression our instructors will let you know. Alternatively, you are always more than welcome to ask us. Our leveling system includes Beginner Level 1 through to Advanced. All students who are new to pole dancing will begin in our Beginner Lv 1 class where you will learn about 7 different spins, climbs and sits before progressing to Beginner Lv 2."],
                        ["p", "We recommend once a student progresses to the new level to take a few classes at the old level to still help bridge that gap and make the transition easier for them."]
                    ]
                }/>   
            </StyledAccordionContainer>
        </StyledFaqContainer>
        <StyledFaqContainer logoleftposition={logoLeftPos && logoLeftPos}>
            <StyledH2 width={width} logoleftposition={logoLeftPos && logoLeftPos}>Other offerings</StyledH2>
            <StyledAccordionContainer logoleftposition={logoLeftPos && logoLeftPos}>
                <Accordion 
                titleText="I'm interested in booking a private event/party!" 
                bodyObject={
                    [
                        ["p", "We're so thrilled you're planning on a bachelorette/birthday/night out/any type of party with us!"],
                        ["ps", "To inquire about dates and times and to book, please email us at "], 
                        ["a", {"href": "mailto:info@redefine-fit.com", "text": "info@redefine-fit.com"}],
                        ["pa", "Some important information about our parties:"],
                        ["ul", [
                            "We have cute 2.5 inch buttons for bachelorette and birthday parties. They say 'Bridge', 'Bride Troupe', and 'Birthday Girl.' They are complimentary gifts with your booking!",
                            "The minimum number of participants is 4 guests.",
                            "We require a non-refundable deposit of $100.",
                            "Our east location is steps away from Chester Subway station and on the Danforth. Our west location is steps away from Lansdown Station on Bloor West.",
                            "No matter which package you choose, we enjoy decorating and will make it fit the theme."
                        ]],
                        ["ps", "To learn about party options and prices, "],
                        ["a", {"href": "https://redefine-fit.com/private-parties", "text": "visit this link."}],
                        ["pai", " Let us know if you decide to book with us and we will provide extra details. "],
                        ["ps", "For an idea about what we've done for parties, "],
                        ["a", {"href": "https://www.instagram.com/redefine_fitLtd", "text": "check our Instagram!"}],
                        ["pib", " You can check our "],
                        ["a", {"href": "https://www.facebook.com/RedefineFitLtd", "text": "Facebook page"}],
                        ["ps", " or "],
                        ["a", {"href": "https://www.google.com/search?q=redefine+fit+google+reviews&oq=redefine+fit+google+reviews&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQLhhA0gEIMzcxN2owajGoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x89d4cc9c31bbe86b:0xd7cbfc0a15fe41f0,1,,,,", "text": "check our Google Review Page"}],
                        ["pai", " to read reviews about our parties!"] 
                    ]
                }/>  
                <Accordion 
                titleText="I'm interested in booking private classes!" 
                bodyObject={
                    [
                        ["p", "The Redefine Fit team offers personalized training for pole dance. They can teach tricks and choreography for all skill levels!"],
                        ["ps", "To request a private class, please email at "],
                        ["a", {"href": "mailto:info@redefine-fit.com", "text": "info@redefine-fit.com"}]
                    ]
                }/> 
                <Accordion 
                titleText="I'm interested in renting out the space!" 
                bodyObject={
                    [
                        ["ps", "We're happy to accommodate space rental requests! Please email us at "],
                        ["a", {"href": "mailto:info@redefine-fit.com", "text": "info@redefine-fit.com"}],
                        ["ps", " for availability and rates."]
                    ]
                }/>
            </StyledAccordionContainer>
        </StyledFaqContainer>
      </StyledDiv>

      <ChooseStudio background={'teal'} />

      <StyledDiv bgColor={'#fff'}>
        <Footer />
      </StyledDiv>
    </div>
  );
}

export default Faq;